.column {
    background-color: #f2f2f2;
    border-radius: 5px;
    padding: 15px;
    width: 80%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    display: flex;
}