.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(3px);
}

.modal-container {
    width: 90%;
    max-width: 500px;
    background-color: white;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
    animation: modalFadeIn 0.3s ease-out;
}

@keyframes modalFadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.modal-header {
    padding: 20px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eaeaea;
}

.modal-header h2 {
    margin: 0;
    font-size: 1.5rem;
    color: #333;
    font-weight: 600;
}

.modal-header.warning {
    background-color: #fff8e1;
    color: #f57c00;
}

.modal-header.danger {
    background-color: #ffebee;
    color: #d32f2f;
}

.modal-header.success {
    background-color: #e8f5e9;
    color: #2e7d32;
}

.modal-header.info {
    background-color: #e3f2fd;
    color: #1976d2;
}

.modal-close-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #757575;
    transition: color 0.2s;
}

.modal-close-button:hover {
    color: #333;
}

.modal-body {
    padding: 24px;
    font-size: 1.1rem;
    line-height: 1.6;
    color: #555;
}

.modal-footer {
    padding: 16px 24px;
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    border-top: 1px solid #eaeaea;
}

.modal-button {
    padding: 10px 20px;
    border-radius: 6px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    border: none;
}

.cancel-button {
    background-color: #f5f5f5;
    color: #555;
}

.cancel-button:hover {
    background-color: #e0e0e0;
}

.confirm-button {
    color: white;
}

.confirm-button.warning {
    background-color: #ff9800;
}

.confirm-button.danger {
    background-color: #f44336;
}

.confirm-button.success {
    background-color: #4caf50;
}

.confirm-button.info {
    background-color: #2196f3;
}

.confirm-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.confirm-button.warning:hover {
    background-color: #f57c00;
}

.confirm-button.danger:hover {
    background-color: #d32f2f;
}

.confirm-button.success:hover {
    background-color: #2e7d32;
}

.confirm-button.info:hover {
    background-color: #1976d2;
}

@media (max-width: 600px) {
    .modal-container {
        width: 95%;
    }
    
    .modal-header h2 {
        font-size: 1.3rem;
    }
    
    .modal-body {
        padding: 16px;
        font-size: 1rem;
    }
    
    .modal-footer {
        padding: 12px 16px;
    }
}
