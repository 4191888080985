* {
    box-sizing: border-box;
    z-index: 1;
}

body {
    margin: 0;
}

.navbar-container {
    position: sticky;
    width: 100%;
    top: 0;
    z-index: 1000;
    background: linear-gradient(90deg, #0056b3, #2563eb);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
}

.navbar {
    width: 100%;
    margin: 0;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
}

.navbar-left {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    padding-left: 1.5rem;  /* Added left padding */
}

.navbar-search-container {
    position: relative;
    width: 500px;
    margin-left: 1rem;  /* Added margin to separate from logo */
}

.navbar-search-input {
    width: 100%;
    padding: 12px 20px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 12px;
    background-color: rgba(255, 255, 255, 0.1);
    color: #ffffff;
    font-size: 0.95rem;
    transition: all 0.3s ease;
}

.navbar-search-input::placeholder {
    color: rgba(255, 255, 255, 0.7);
}

.navbar-search-input:focus {
    outline: none;
    background-color: rgba(255, 255, 255, 0.15);
    border-color: rgba(255, 255, 255, 0.3);
    box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.1);
}

.navbar-logo {
    font-size: 1.8rem;
    font-weight: 700;
    color: #ffffff;
    text-decoration: none;
    padding: 0.5rem 0;
    transition: all 0.3s ease;
    margin-right: 0; /* Removed margin */
}

.navbar-logo:hover {
    color: #ffffff;
    text-shadow: 0 0 15px rgba(255, 255, 255, 0.5);
    transform: translateY(-1px);
}

.navbar-right {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    list-style: none;
    padding: 0;
    margin: 0;
    padding-right: 10px;
}

.navbar-right li {
    position: relative;  /* For active indicator positioning */
}

.navbar-right a {
    color: rgba(255, 255, 255, 0.9);
    text-decoration: none;
    font-weight: 500;
    padding: 0.75rem 1.25rem;  /* Adjusted padding */
    border-radius: 8px;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
}

.navbar-right a:hover {
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.1);
}

.navbar-right li.active a {
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.15);
    position: relative;  /* For active indicator */
}

.search-results-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    margin-top: 4px;
    max-height: 400px;
    overflow-y: auto;
    z-index: 1000;
}

.search-loading {
    padding: 1rem;
    text-align: center;
    color: #666;
}

.search-results {
    padding: 0.5rem;
}

.search-results section {
    margin-bottom: 1rem;
}

.search-results h4 {
    margin: 0.5rem 0;
    padding: 0.5rem;
    font-size: 0.9rem;
    color: #666;
    border-bottom: 1px solid #eee;
}

.search-result-item {
    display: block;
    padding: 8px 12px;
    color: #333;
    text-decoration: none;
    transition: background-color 0.2s;
    border-bottom: 1px solid #eee;
}

.search-result-item:hover {
    background-color: #f5f5f5;
    text-decoration: none;
}

.no-results {
    padding: 1rem;
    text-align: center;
    color: #666;
}

.result-title {
    font-weight: bold;
    color: #333;
    margin-bottom: 4px;
}

.result-preview {
    font-size: 0.85rem;
    color: #666;
    margin-bottom: 4px;
}

.result-date, .result-meta {
    font-size: 0.8rem;
    color: #888;
}

.result-meta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 4px;
    font-size: 0.8rem;
    color: #666;
}

.task-status {
    padding: 2px 6px;
    border-radius: 3px;
    font-size: 0.75rem;
}

.status-todo { background-color: #ffd700; }
.status-inProgress { background-color: #87ceeb; }
.status-complete { background-color: #90ee90; }

.match-highlight {
    background-color: rgba(255, 235, 59, 0.2);
    padding: 2px 0;
}

.match-type {
    font-size: 0.8em;
    color: #666;
    margin-left: 8px;
    font-weight: normal;
}

.result-preview mark {
    background-color: #fff3cd;
    padding: 0 2px;
    border-radius: 2px;
}

/* Profile Picture Styles */
.profile-nav-link {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    margin-left: 1rem !important;  /* Added important to override other styles */
    padding: 0.5rem !important;  /* Reduced padding for profile icon */
}

.navbar-profile-picture {
    width: 40px;
    height: 40px;
    border-radius: 12px;
    object-fit: cover;
    border: 2px solid rgba(255, 255, 255, 0.9);
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    transition: all 0.3s ease;
}

.navbar-profile-placeholder {
    width: 40px;
    height: 40px;
    border-radius: 12px;
    background-color: rgba(255, 255, 255, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(255, 255, 255, 0.9);
    border: 2px solid rgba(255, 255, 255, 0.3);
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    transition: all 0.3s ease;
}

.profile-nav-link:hover .navbar-profile-picture,
.profile-nav-link:hover .navbar-profile-placeholder {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    border-color: #ffffff;
}

.active-indicator {
    position: absolute;
    bottom: -4px;
    left: 50%;
    transform: translateX(-50%);
    width: 4px;
    height: 4px;
    background-color: #ffffff;
    border-radius: 50%;
}

.active-indicator {
    display: block;
    width: 4px;
    height: 4px;
    background-color: #ffffff;
    border-radius: 50%;
    margin: 4px auto 0;
}

.profile-menu-container {
    position: relative;
}

.profile-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    min-width: 200px;
    margin-top: 8px;
    z-index: 1000;
    padding: 8px 0;
}

.profile-dropdown-item {
    display: flex;
    align-items: left;
    gap: 8px;
    padding: 10px 16px;
    color: #4b5563 !important; /* Updated color for better contrast */
    text-decoration: none;
    width: 100%;
    border: none;
    background: none;
    cursor: pointer;
    font-size: 0.95rem;
    text-align: left;
}

.profile-dropdown-item:hover {
    background-color: #f3f4f6;
    color: #2563eb;
}

.profile-dropdown-item svg {
    width: 16px;
    height: 16px;
}

.profile-nav-link {
    cursor: pointer;
}

/* Update register button styles to match blue theme */
.register-button {
    background-color: rgba(255, 255, 255, 0.1);
    color: #ffffff !important;
    border-radius: 8px;
    margin-left: 0.5rem;
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.register-button:hover {
    background-color: rgba(255, 255, 255, 0.2) !important;
    color: #ffffff !important;
    transform: translateY(-1px);
}

/* Add responsive breakpoints */
@media screen and (max-width: 1200px) {
    .navbar-search-container {
        width: 400px;
    }
    
    .navbar-left {
        gap: 1rem;
    }
}

@media screen and (max-width: 992px) {
    .navbar-search-container {
        width: 300px;
    }
    
    .navbar-right a {
        padding: 0.75rem 1rem;
    }
}

@media screen and (max-width: 768px) {
    .navbar-search-container {
        width: 300px;
    }
    
    .navbar-right {
        gap: 0.5rem;
    }
}

@media screen and (max-width: 576px) {
    .navbar {
        flex-wrap: wrap;
        padding: 0.5rem;
    }
    
    .navbar-left {
        width: 100%;
        justify-content: space-between;
        margin-bottom: 0.5rem;
    }
    
    .navbar-search-container {
        width: 100%;
        margin: 0.5rem 0;
        order: 3;
    }
    
    .navbar-right {
        width: 100%;
        justify-content: center;
    }
}