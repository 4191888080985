/* Dashboard Layout */
.dashboard-layout {
    display: flex;
    gap: 2rem;
    height: calc(100vh - 180px);
    padding: 1.5rem;
    overflow: hidden;
}

/* Left Metrics Panel */
.metrics-sidebar {
    width: 280px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex-shrink: 0;
    overflow-y: auto;
}

.metric-tile {
    padding: 1.25rem;
    border-radius: 12px;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: transform 0.2s ease;
}

.metric-tile:hover {
    transform: translateX(5px);
}

.metric-tile .metric-data {
    display: flex;
    flex-direction: column;
}

.metric-tile .metric-data p {
    font-size: 0.85rem;
    opacity: 0.8;
    margin: 0;
    font-weight: 500;
}

.metric-tile .metric-data h3 {
    font-size: 1.75rem;
    font-weight: 600;
    margin: 0.25rem 0 0;
}

.metric-tile .metric-icon {
    font-size: 1.75rem;
    opacity: 0.8;
}

/* Gradient backgrounds */
.gradient-primary {
    background: linear-gradient(135deg, #0d6efd 0%, #0dcaf0 100%);
}

.gradient-success {
    background: linear-gradient(135deg, #198754 0%, #20c997 100%);
}

.gradient-info {
    background: linear-gradient(135deg, #0dcaf0 0%, #6610f2 100%);
}

.gradient-warning {
    background: linear-gradient(135deg, #fd7e14 0%, #ffc107 100%);
}

/* Time Metrics Card */
.time-metrics-card {
    background: white;
    border-radius: 12px;
    padding: 1.25rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    box-shadow: 0 2px 8px rgba(0,0,0,0.05);
}

.time-metrics-card .metric-data {
    padding: 0.5rem;
    border-radius: 8px;
    background: #f8f9fa;
}

.time-metrics-card p {
    color: #6c757d;
    font-size: 0.85rem;
    margin: 0;
    font-weight: 500;
}

.time-metrics-card h3 {
    color: #343a40;
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0.25rem 0;
}

.time-metrics-card small {
    color: #6c757d;
    font-size: 0.75rem;
}

/* Charts Grid */
.dashboard-main {
    flex: 1;
    min-width: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 1.5rem;
    overflow-y: auto;
    padding-right: 0.5rem;
}

.chart-card {
    background: white;
    border-radius: 12px;
    padding: 1.25rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.chart-card .card-header {
    padding: 0 0 1rem 0;
    background: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.chart-card .card-header h3 {
    font-size: 1rem;
    font-weight: 600;
    color: #495057;
}

.chart-card .card-body {
    padding: 1rem 0 0 0;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chart-container {
    width: 100%;
    height: 100%;
    position: relative;
}

/* No Data State */
.no-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: #6c757d;
    text-align: center;
    padding: 1rem;
}

.no-data i {
    font-size: 3rem;
    margin-bottom: 1rem;
    opacity: 0.5;
}

.no-data p {
    margin-bottom: 0.25rem;
    font-weight: 500;
}

/* Responsive Adjustments */
@media (max-width: 1400px) {
    .dashboard-main {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
    }
}

@media (max-width: 1200px) {
    .dashboard-layout {
        flex-direction: column;
    }

    .metrics-sidebar {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 1rem;
    }

    .time-metrics-card {
        grid-column: 1 / -1;
        flex-direction: row;
        justify-content: space-around;
    }

    .time-metrics-card .metric-data {
        flex: 1;
        text-align: center;
    }

    .dashboard-main {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(4, minmax(300px, 1fr));
    }
}

@media (max-width: 768px) {
    .dashboard-main {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(4, minmax(300px, 1fr));
    }
}
