.meetings-container {
    padding: 2rem;
    background-color: #f8f9fa;
}

.meetings-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
}

.meetings-header h1 {
    font-size: 2.5rem;
    color: #2c3e50;
    margin: 0;
}

.create-meeting-btn {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    font-weight: 600;
    background-color: #007bff;
    border: none;
    border-radius: 8px;
    transition: all 0.3s ease;
}

.create-meeting-btn:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.meetings-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.5rem;
    padding: 1rem 0;
}

.meeting-card {
    background-color: white;
    border-radius: 12px;
    padding: 1.5rem;
    text-decoration: none;
    color: inherit;
    transition: all 0.3s ease;
    border: 1px solid #e9ecef;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.meeting-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    border-color: #007bff;
}

.meeting-card-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.meeting-icon {
    font-size: 2rem;
    color: #007bff;
    margin-bottom: 0.5rem;
}

.meeting-title {
    font-size: 1.25rem;
    font-weight: 600;
    color: #2c3e50;
    margin: 0;
}

.meeting-details {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    font-size: 0.9rem;
    color: #6c757d;
}

.meeting-date,
.meeting-time {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.meetings-actions {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.show-all-btn {
    padding: 0.75rem 1.5rem;
    font-weight: 500;
    transition: all 0.3s ease;
}

.show-all-btn:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.filter-notice {
    background: rgba(37, 99, 235, 0.1);
    color: #2563eb;
    padding: 0.75rem 1rem;
    border-radius: 8px;
    margin-bottom: 1.5rem;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.filter-notice::before {
    content: "ℹ️";
}

@media (max-width: 768px) {
    .meetings-container {
        padding: 1rem;
    }

    .meetings-header {
        flex-direction: column;
        gap: 1rem;
        text-align: center;
    }

    .meetings-grid {
        grid-template-columns: 1fr;
    }

    .meetings-actions {
        flex-direction: column;
        width: 100%;
    }

    .show-all-btn,
    .create-meeting-btn {
        width: 100%;
    }
}
