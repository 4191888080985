.release-notes-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #f8f9fa;
}

.release-notes-content {
    flex: 1;
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
}

.release-notes-header {
    text-align: center;
    margin-bottom: 3rem;
    padding: 2rem 0;
}

.release-notes-header h1 {
    color: #2c3e50;
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
    font-weight: 600;
}

.release-notes-header p {
    color: #666;
    font-size: 1.1rem;
}

.releases-timeline {
    position: relative;
    max-width: 1000px;  /* Increased from 800px */
    margin: 0 auto;
}

.release-item {
    background: white;
    border-radius: 12px;
    padding: 2.5rem;    /* Increased padding */
    margin-bottom: 2rem;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    border-left: 4px solid #3498db;
    transition: transform 0.2s ease;
    width: 100%;        /* Ensure full width */
}

.release-item:hover {
    transform: translateY(-2px);
}

.release-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 1.5rem;
}

.release-header h2 {
    color: #2c3e50;
    font-size: 1.5rem;
    margin: 0;
    margin-bottom: 0.5rem;
}

.release-type {
    display: inline-block;
    padding: 0.25rem 0.75rem;
    background-color: #e3f2fd;
    color: #1976d2;
    border-radius: 20px;
    font-size: 0.85rem;
    font-weight: 500;
}

.release-date {
    color: #666;
    font-size: 0.9rem;
    font-weight: 500;
}

.changes-list {
    list-style-type: none;
    padding-left: 0;
}

.changes-list li {
    position: relative;
    padding: 0.5rem 0 0.5rem 1.5rem;
    color: #555;
    line-height: 1.6;
}

.changes-list li:before {
    content: "•";
    color: #3498db;
    position: absolute;
    left: 0;
    font-size: 1.2rem;
}

@media (max-width: 768px) {
    .release-notes-content {
        padding: 1rem;
    }

    .release-header {
        flex-direction: column;
    }

    .release-date {
        margin-top: 0.5rem;
    }
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
}

.modal-content form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.form-group input {
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.modal-actions {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
    margin-top: 1rem;
}

.modal-actions button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.modal-actions button[type="submit"] {
    background-color: #3498db;
    color: white;
}

.modal-actions button[type="button"] {
    background-color: #ddd;
}

.loading-spinner,
.error-message,
.no-notes-message {
    text-align: center;
    padding: 2rem;
    color: #666;
    font-size: 1.1rem;
}

.error-message {
    color: #dc3545;
}

.no-notes-message {
    color: #6c757d;
    font-style: italic;
}
