.note-network {
    position: relative;
    width: 100%;
    height: 100%;
    background: #f9fafb;
}

.note-network canvas {
    cursor: pointer;
}

.note-network-toggle {
    padding: 10px 20px;
    background: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
}

.note-network-toggle:hover {
    background: #f8f9fa;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.07);
}

.network-tooltip {
    position: fixed;
    z-index: 1000;
    background: white;
    padding: 12px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    font-size: 0.9rem;
    max-width: 250px;
    border-left: 4px solid #ff9900;
    pointer-events: none;
    transition: opacity 0.2s ease;
    backdrop-filter: blur(4px);
}

.network-tooltip strong {
    display: block;
    margin-bottom: 8px;
    color: #343a40;
    font-size: 16px;
}

.network-tooltip div {
    margin-bottom: 5px;
    line-height: 1.4;
}

/* Enhanced neural network style legend */
.network-legend {
    position: absolute;
    bottom: 20px;
    left: 20px;
    background: rgba(255, 255, 255, 0.92);
    padding: 18px;
    border-radius: 8px;
    box-shadow: 0 3px 15px rgba(0, 123, 255, 0.4), 0 0 30px rgba(0, 123, 255, 0.1);
    font-size: 14px;
    z-index: 1001;
    border-left: 4px solid #007bff;
    max-width: 270px;
    backdrop-filter: blur(4px);
}

.legend-title {
    font-weight: bold;
    margin-bottom: 12px;
    color: #343a40;
    font-size: 16px;
}

.legend-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.legend-color {
    width: 14px;
    height: 14px;
    margin-right: 10px;
    border-radius: 50%;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.5);
}

.legend-line {
    width: 25px;
    height: 2px;
    background-color: rgba(180, 180, 180, 0.9);
    margin-right: 10px;
    box-shadow: 0 0 8px rgba(255, 255, 255, 0.4);
}

.legend-info {
    margin-top: 12px;
    border-top: 1px solid rgba(233, 236, 239, 0.4);
    padding-top: 12px;
}

.legend-info p {
    margin: 8px 0;
    font-size: 13px;
    color: #d0d4d9;
    line-height: 1.4;
}

/* Add zoom controls for better UX */
.network-controls {
    position: absolute;
    bottom: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 1001;
}

.network-control-btn {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.92);
    border: none;
    box-shadow: 0 2px 10px rgba(0, 123, 255, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    cursor: pointer;
    transition: all 0.2s ease;
    backdrop-filter: blur(4px);
}

.network-control-btn:hover {
    background: white;
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(0, 123, 255, 0.4);
}

.embedding-badge {
    display: inline-block;
    margin-top: 6px;
    background-color: #007bff;
    color: white;
    font-size: 0.8rem;
    padding: 3px 8px;
    border-radius: 4px;
}

.note-network-header {
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
}

.note-network-info {
    display: flex;
    align-items: center;
    gap: 15px;
}

.network-stats {
    display: flex;
    gap: 15px;
    background: white;
    padding: 8px 15px;
    border-radius: 8px;
    font-size: 0.9rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.network-stats span {
    position: relative;
}

.network-stats span:not(:last-child):after {
    content: "•";
    position: absolute;
    right: -10px;
    color: #6c757d;
}

.generation-status {
    position: absolute;
    top: 80px;
    right: 20px;
    background: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 10px 20px;
    border-radius: 8px;
    z-index: 10;
    font-size: 0.9rem;
    max-width: 300px;
    text-align: center;
}

.network-loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.9);
    color: #007bff;
    font-size: 1.2rem;
    z-index: 5;
}

.network-loading p {
    margin-top: 20px;
    font-weight: 500;
}
