.privacy-policy {
    max-width: 800px;
    margin: 2rem auto;
    padding: 0 1rem;
}

.privacy-policy h1 {
    text-align: center;
    margin-bottom: 2rem;
}

.privacy-policy section {
    margin-bottom: 2rem;
}

.privacy-policy h2 {
    margin-bottom: 1rem;
}

.privacy-policy-container {
    min-height: calc(100vh - 80px); /* Account for navbar height */
    background: var(--gradient-main);
    padding-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center horizontally */
    width: 100%;
}

.privacy-hero {
    padding: 40px 20px 60px;
    text-align: center;
    background: transparent;
}

.privacy-hero h1 {
    font-size: 2.5rem;
    color: var(--white);
    margin-bottom: 1rem;
    font-weight: 700;
}

.privacy-subtitle {
    font-size: 1rem;
    color: rgba(255, 255, 255, 0.8);
    font-style: italic;
}

.privacy-content {
    flex: 1;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    position: relative;
    z-index: 2;
}

.privacy-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    padding: 20px;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.privacy-section {
    margin-bottom: 2rem;
    height: 100%;
}

.privacy-section h2 {
    color: var(--primary);
    margin-bottom: 1rem;
    font-size: 1.5rem;
}

.privacy-card {
    background: rgba(255, 255, 255, 0.95);
    border-radius: 12px;
    padding: 2rem;
    height: 100%;
    box-shadow: var(--shadow);
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-left: 4px solid var(--primary);
}

.privacy-card:hover {
    transform: translateY(-5px);
    box-shadow: var(--shadow-hover);
}

.privacy-card h2 {
    color: var(--primary);
    font-size: 1.25rem;
    margin-bottom: 1rem;
    font-weight: 600;
    flex: 0 0 auto; /* Prevents header from flexing */
}

.privacy-card p {
    color: var(--text-dark);
    line-height: 1.6;
    margin: 0;
    font-size: 0.95rem;
    flex: 1 0 auto; /* Makes content take up available space */
}

.privacy-commitment {
    max-width: 900px;
    margin: 3rem auto;
    padding: 3rem;
    background: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(12px);
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;
}

.privacy-commitment::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: linear-gradient(90deg, 
        rgba(255,255,255,0.7), 
        rgba(255,255,255,0.3) 50%,
        rgba(255,255,255,0.7)
    );
    animation: shimmer 2s infinite linear;
}

.privacy-commitment h2 {
    color: var(--white);
    font-size: 2.2rem;
    margin-bottom: 1.5rem;
    text-align: center;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-weight: 700;
}

.privacy-commitment p {
    color: rgba(255, 255, 255, 0.95);
    font-size: 1.2rem;
    line-height: 1.8;
    text-align: center;
    margin: 0;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

@keyframes shimmer {
    0% { transform: translateX(-100%); }
    100% { transform: translateX(100%); }
}

@media (max-width: 768px) {
    .privacy-hero h1 {
        font-size: 2rem;
    }
    
    .privacy-content {
        padding: 20px;
    }
    
    .privacy-grid {
        grid-template-columns: 1fr;
    }
    
    .privacy-card {
        padding: 1.5rem;
    }

    .privacy-commitment {
        margin: 2rem 1rem;
        padding: 2rem;
    }

    .privacy-commitment h2 {
        font-size: 1.8rem;
    }

    .privacy-commitment p {
        font-size: 1.1rem;
    }
}
