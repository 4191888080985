.notes-container {
    display: flex;
    height: calc(100vh - 112px);
    background: #f8f9fa;
    padding: 20px;
}

.notes-sidebar {
    width: 300px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin-right: 20px;
    display: flex;
    flex-direction: column;
}

.notes-sidebar-header {
    padding: 20px;
    border-bottom: 1px solid #e9ecef;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.notes-sidebar-header h2 {
    margin-bottom: 15px;
    font-weight: 600;
    color: #343a40;
}

.notes-sidebar-actions {
    display: flex;
    width: 100%;
    gap: 10px;
    margin-top: 5px;
}

.sidebar-button {
    flex: 1;
    padding: 8px 0;
    border: none;
    border-radius: 6px;
    font-size: 0.9rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
    box-shadow: 0 2px 4px rgba(0,0,0,0.08);
    cursor: pointer;
    text-align: center;
}

.new-note-btn {
    background: linear-gradient(135deg, #007bff, #0056b3);
    color: white;
}

.new-note-btn:hover {
    background: linear-gradient(135deg, #0069d9, #004494);
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.12);
}

.network-view-btn {
    background: linear-gradient(135deg, #6c757d, #495057);
    color: white;
}

.network-view-btn:hover {
    background: linear-gradient(135deg, #5a6268, #383d42);
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.12);
}

.sidebar-button .fa-icon {
    margin-right: 8px;
    font-size: 0.9rem;
}

.notes-list {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
}

.note-item {
    padding: 15px;
    border-radius: 6px;
    margin-bottom: 10px;
    cursor: pointer;
    position: relative;
    background: #f8f9fa;
    transition: all 0.2s ease;
}

.note-item:hover {
    background: #e9ecef;
}

.note-item.selected {
    background: #007bff;
    color: white;
}

.note-item-title {
    font-weight: 500;
    margin-bottom: 5px;
}

.note-item-date {
    font-size: 0.8em;
    color: #6c757d;
}

.note-item.selected .note-item-date {
    color: rgba(255,255,255,0.8);
}

.note-editor {
    flex: 1;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
}

.note-editor-header {
    padding: 20px;
    border-bottom: 1px solid #e9ecef;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.note-title-input {
    font-size: 1.5rem;
    font-weight: 500;
    border: none;
    border-bottom: 2px solid #007bff;
    padding: 5px 0;
    width: 100%;
    max-width: 500px;
}

.note-editor-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
}

.note-content-input {
    width: 100%;
    height: 100%;
    border: none;
    resize: none;
    font-size: 1rem;
    line-height: 1.6;
    padding: 0;
}

.note-content-input:focus {
    outline: none;
}

.note-content {
    font-size: 1rem;
    line-height: 1.6;
    white-space: pre-wrap;
    padding: 20px;
    overflow-y: auto;
}

.no-note-selected {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: #6c757d;
}

.delete-note {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    opacity: 0;
    transition: opacity 0.2s ease;
}

.note-item:hover .delete-note {
    opacity: 1;
}

.note-actions {
    display: flex;
    gap: 10px;
}

/* Save indicator styling */
.save-indicator {
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 12px 24px;
    background-color: #28a745;
    color: white;
    border-radius: 8px;
    font-size: 0.9rem;
    font-weight: 500;
    animation: fadeInOut 2s ease-in-out;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 1000;
}

.save-indicator.error {
    background-color: #dc3545;
}

@keyframes fadeInOut {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    20% {
        opacity: 1;
        transform: translateY(0);
    }
    80% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(-20px);
    }
}

/* Enhanced Save prompt styling */
.save-prompt {
    font-size: 0.9rem;
    color: #495057;
    background-color: #e9ecef;
    padding: 0.6rem 1.2rem;
    border-radius: 6px;
    animation: pulse 2s infinite;
    display: inline-flex;
    align-items: center;
    margin-right: 10px;
    font-weight: 500;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    border-left: 3px solid #28a745;
}

@keyframes pulse {
    0% { opacity: 0.8; background-color: #e9ecef; }
    50% { opacity: 1; background-color: #d1e7dd; }
    100% { opacity: 0.8; background-color: #e9ecef; }
}

/* Quill Editor Styling */
.quill-container {
    flex: 1;
    display: none; /* hidden by default, will be shown in edit mode */
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 20;
    visibility: hidden;
    background: white;
    align-items: flex-start; /* Ensure content aligns to the top */
    justify-content: flex-start; /* Ensure content aligns to the top */
    padding: 1rem; /* Add padding for better spacing */
}

.quill-container.editing {
    display: flex !important;
    visibility: visible !important;
}

.quill-container.editing + .note-content-view {
    display: none !important;
    visibility: hidden !important;
}

.quill-editor {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.ql-container {
    flex: 1;
    overflow: auto;
    border: none !important;
    font-size: 16px !important;
    position: relative;
}

.ql-editor {
    min-height: 100%;
    padding: 2rem !important;
    font-family: inherit;
    line-height: 1.6;
}

/* Apply alignment classes to the view mode as well */
.ql-align-center {
    text-align: center !important;
}

.ql-align-right {
    text-align: right !important;
}

.ql-align-justify {
    text-align: justify !important;
}

.ql-toolbar {
    position: relative;
    z-index: 2;
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
    background-color: #f8f9fa !important;
    border: none !important;
    border-bottom: 1px solid #e9ecef;
    padding: 1rem !important;
}

.ql-formats {
    margin-right: 12px !important;
}

/* Note viewing styling */
.note-content {
    font-size: 1rem;
    line-height: 1.6;
    padding: 20px;
    overflow-y: auto;
}

/* Add Quill viewer class to support the same styling as the editor */
.ql-viewer {
    white-space: normal; /* Override pre-wrap to allow proper text wrapping */
}

.note-content h1,
.note-content h2,
.note-content h3,
.note-content h4,
.note-content h5,
.note-content h6 {
    margin-top: 1em;
    margin-bottom: 0.5em;
    font-weight: 600;
}

.note-content p {
    margin-bottom: 1em;
}

.note-content ul,
.note-content ol {
    padding-left: 1.5em;
    margin-bottom: 1em;
}

.note-content blockquote {
    border-left: 3px solid #e9ecef;
    padding-left: 1em;
    margin-left: 0;
    color: #6c757d;
}

/* Remove any explicit text-align that might override Quill's alignment */
.note-editor-content * {
    /* No default alignment here */
}

/* Ensure Quill alignment classes take precedence */
.note-content .ql-align-center,
.note-content .ql-align-right,
.note-content .ql-align-justify {
    /* These will use the !important alignments from above */
}

/* Improve the note content display */
.note-content h1,
.note-content h2,
.note-content h3,
.note-content h4,
.note-content h5,
.note-content h6 {
    margin-top: 1em;
    margin-bottom: 0.5em;
    font-weight: 600;
}

.note-content p {
    margin-bottom: 1em;
}

.note-content ul,
.note-content ol {
    padding-left: 1.5em;
    margin-bottom: 1em;
}

.note-content blockquote {
    border-left: 3px solid #e9ecef;
    padding-left: 1em;
    margin-left: 0;
    color: #6c757d;
}

/* Remove any text-align: inherit that might interfere with user alignments */
.note-editor-content * {
    /* Remove this line to allow user-defined alignments */
    /* text-align: inherit; */
}

/* View container styling */
.note-content-view {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 2rem;
    overflow-y: auto;
    background: white;
    z-index: 5;
    display: block;
    visibility: visible;
    font-size: 16px;
    line-height: 1.5;
    color: #333;
    text-align: initial;
}

/* Support for Quill alignment classes */
.ql-align-center,
.note-content-view .ql-align-center {
    text-align: center !important;
}

.ql-align-right,
.note-content-view .ql-align-right {
    text-align: right !important;
}

.ql-align-justify,
.note-content-view .ql-align-justify {
    text-align: justify !important;
}

/* Fix for editor container to ensure it's always accessible */
.quill-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 10; /* Increased z-index to ensure visibility */
}

.quill-editor {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

/* Ensure the ql-toolbar is properly positioned and sized */
.ql-toolbar {
    width: 100%;
    position: relative;
    z-index: 11; /* Increased z-index */
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
    background-color: #f8f9fa !important;
    border: none !important;
    border-bottom: 1px solid #e9ecef;
    padding: 1rem !important;
}

/* Ensure the quill container properly fills the space */
.note-editor-content {
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 1;
    overflow: hidden;
}

.quill-container.editing {
    z-index: 20;
    display: flex !important;
    visibility: visible !important;
}

.note-content-view.hidden {
    display: none !important;
    visibility: hidden !important;
}

/* Ensure the view container doesn't block editor interaction */
.note-content-view {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 2rem;
    overflow-y: auto;
    background: white;
    z-index: 1;
}

/* Ensure the view container properly displays formatting including alignment */
.note-content-view {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 2rem;
    overflow-y: auto;
    background: white;
    z-index: 1;
}

/* Fix alignment classes to work in both edit and view modes */
.note-content-view.ql-editor .ql-align-center,
.note-content-view .ql-align-center {
    text-align: center !important;
}

.note-content-view.ql-editor .ql-align-right,
.note-content-view .ql-align-right {
    text-align: right !important;
}

.note-content-view.ql-editor .ql-align-justify,
.note-content-view .ql-align-justify {
    text-align: justify !important;
}

/* Add direct alignments without .ql- prefix in case they're missing */
.note-content-view p[style*="center"],
.note-content-view div[style*="center"],
.note-content-view h1[style*="center"],
.note-content-view h2[style*="center"],
.note-content-view h3[style*="center"],
.note-content-view h4[style*="center"],
.note-content-view h5[style*="center"],
.note-content-view h6[style*="center"] {
    text-align: center !important;
}

.note-content-view p[style*="right"],
.note-content-view div[style*="right"],
.note-content-view h1[style*="right"],
.note-content-view h2[style*="right"],
.note-content-view h3[style*="right"],
.note-content-view h4[style*="right"],
.note-content-view h5[style*="right"],
.note-content-view h6[style*="right"] {
    text-align: right !important;
}

.note-content-view p[style*="justify"],
.note-content-view div[style*="justify"],
.note-content-view h1[style*="justify"],
.note-content-view h2[style*="justify"],
.note-content-view h3[style*="justify"],
.note-content-view h4[style*="justify"],
.note-content-view h5[style*="justify"],
.note-content-view h6[style*="justify"] {
    text-align: justify !important;
}

/* Make sure the quill content is using Quill's alignment classes */
.note-content-view {
    font-family: inherit !important;
}

.note-editor-content {
    flex: 1;
    position: relative;
    overflow: hidden;
}

.quill-container {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.quill-editor {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.ql-container {
    flex: 1;
    overflow: auto;
}

.note-content-view {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 2rem;
    overflow-y: auto;
    background: white;
}

/* Network overlay styling */
.network-overlay {
    position: fixed;
    top: 112px; /* Account for navbars */
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
}

/* Fix note-content-view to ensure content is always visible and has proper spacing */
.note-content-view {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 2rem;
    overflow-y: auto;
    background: white;
    z-index: 5;
    display: block !important;
    visibility: visible !important;
    font-size: 16px;
    line-height: 1.5;
    color: #333;
    text-align: initial;
}

/* Improve spacing for content elements */
.note-content-view p {
    margin: 0.5em 0;
    line-height: 1.5;
}

.note-content-view h1,
.note-content-view h2,
.note-content-view h3,
.note-content-view h4,
.note-content-view h5,
.note-content-view h6 {
    margin: 1em 0 0.5em 0;
    line-height: 1.2;
}

.note-content-view ul,
.note-content-view ol {
    margin: 0.5em 0;
    padding-left: 1.5em;
}

.note-content-view li {
    margin: 0.25em 0;
    line-height: 1.5;
}

/* Make sure the Quill editor matches these spacing rules */
.ql-editor {
    line-height: 1.5 !important;
}

.ql-editor p {
    margin: 0.5em 0 !important;
}

/* Quill Editor Styling - Updated with more specific selectors and !important rules */
.note-editor .quill-container {
    flex: 1 !important;
    display: none !important;
    flex-direction: column !important;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    z-index: 20 !important;
    visibility: hidden;
    background: white;
    margin: 0 !important;
    padding: 0 !important;
}

.note-editor .quill-container.editing {
    display: flex !important;
    visibility: visible !important;
}

.note-editor .quill-editor {
    height: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    margin: 0 !important;
    padding: 0 !important;
}

.note-editor .ql-container {
    flex: 1 !important;
    display: flex !important;
    flex-direction: column !important;
    height: auto !important;
}

.note-editor .ql-editor {
    flex: 1 !important;
    padding: 2rem !important;
    min-height: 100% !important;
    font-size: 16px !important;
    line-height: 1.6 !important;
    margin: 0 !important;
}

.note-editor .ql-toolbar.ql-snow {
    position: static !important;
    width: 100% !important;
    border: none !important;
    border-bottom: 1px solid #e9ecef !important;
    padding: 1rem !important;
    background-color: #f8f9fa !important;
    z-index: 21 !important;
}

/* View container - Updated with more specific selectors */
.note-editor .note-content-view {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    padding: 2rem !important;
    overflow-y: auto !important;
    background: white !important;
    z-index: 5 !important;
}

