.wbs-container {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 200px);
    background-color: #f8f9fa;
    padding: 20px;
}

.wbs-title {
    text-align: left !important;
}

.wbs-toolbar {
    background: white;
    border: 1px solid #ddd;
    padding: 12px 20px;
    display: flex;
    gap: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
    margin-bottom: 20px;
}

.toolbar-group {
    display: flex;
    gap: 8px;
}

.toolbar-button {
    padding: 8px 16px;
    background: white;
    border: 1px solid #dee2e6;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    transition: all 0.3s ease;
    font-weight: 500;
}

.toolbar-button:hover {
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.toolbar-button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.toolbar-button.success {
    color: #198754;
    border-color: #198754;
}

.toolbar-button.danger {
    color: #dc3545;
    border-color: #dc3545;
}

.wbs-editor {
    padding: 0;
    height: calc(100vh - 200px); /* Adjust based on your navbar height */
    overflow-y: auto;
    border: 1px solid #ddd;
    flex-grow: 1;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.wbs-row {
    display: flex;
    align-items: stretch;
    border-bottom: 1px solid #eee;
    min-height: 40px; /* Change from height to min-height */
}

.wbs-row-content {
    display: flex;
    align-items: center;  /* Keep centered alignment */
    flex-grow: 1;
}

.wbs-indented-section {
    display: flex;  /* Change to flex */
    flex-grow: 1;  /* Allow it to grow */
    align-items: center;
}

.wbs-fixed-section {
    display: grid;
    grid-template-columns: 150px 150px 150px 100px;
    flex-shrink: 0;  /* Prevent shrinking */
}

.wbs-row:first-child {
    border-top: 1px solid #ddd;
}

.wbs-number {
    width: 80px;  /* Fixed width for the number column */
    flex-shrink: 0;  /* Prevent shrinking */
    font-weight: bold;
    text-align: center;
    border-right: 1px solid #eee;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f8f9fa;
    color: #0056b3;
}

.wbs-row input {
    width: 100%;
    min-height: 100%;
    border: none;
    outline: none;
    background: transparent;
    padding: 8px 12px; /* Increase padding for better readability */
    font-size: 14px;
    white-space: pre-wrap; /* Allow text to wrap */
    word-wrap: break-word; /* Break long words if necessary */
    height: auto; /* Allow height to adjust to content */
    resize: none; /* Prevent manual resizing */
    overflow: hidden; /* Hide scrollbar */
}

.wbs-row input:focus {
    background-color: #f8f9fa;
}

.wbs-row button {
    width: 70px;
    padding: 3px;
    cursor: pointer;
}

.wbs-row:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.wbs-row.selected {
    background: rgba(0, 123, 255, 0.1);
    border-left: 3px solid #0056b3;
}

/* New column styles */
.task-input {
    flex-grow: 1;
    padding: 8px 12px;
    line-height: 1.2;
    white-space: normal; /* Allow wrapping */
    height: auto;
    min-height: 24px;
    overflow: visible;
}

/* Remove hover and focus special handling since we want it to always wrap */
.task-input:hover,
.task-input:focus {
    white-space: normal;
    height: auto;
    position: static;
    box-shadow: none;
    border: none;
    overflow: visible;
}

.owner-input {
    text-align: center;
    min-width: 150px;
}

.date-input {
    text-align: center;
    padding: 2px;
    color: #666;
    min-width: 150px;
}

.date-input:disabled {
    background-color: #f8f9fa;
    color: #495057;
    cursor: not-allowed;
    border: none;
    font-style: italic;
}

.duration {
    text-align: center;
    padding: 0 8px;
    color: #0056b3;
    font-weight: 500;
    min-width: 100px;
}

/* Ensure all inputs have consistent styling */
.wbs-row input {
    width: 100%;
    min-height: 100%;
    border: none;
    outline: none;
    background: transparent;
    padding: 8px 12px; /* Increase padding for better readability */
    font-size: 14px;
    white-space: pre-wrap; /* Allow text to wrap */
    word-wrap: break-word; /* Break long words if necessary */
    height: auto; /* Allow height to adjust to content */
    resize: none; /* Prevent manual resizing */
    overflow: hidden; /* Hide scrollbar */
}

/* Add column borders */
.wbs-indented-section > *,
.wbs-fixed-section > * {
    border-right: 1px solid #ddd;
    height: auto; /* Change from 100% to auto */
    min-height: 100%;
    display: flex;
    align-items: center;
}

.wbs-editor::-webkit-scrollbar {
    width: 8px;
}

.wbs-editor::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
}

.wbs-editor::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.wbs-editor::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* Remove the ::before pseudo-element and add header styles */
.wbs-row.header {
    background-color: #f8f9fa;
    font-weight: 600;
    color: #495057;
    position: sticky;
    top: 0;
    z-index: 2;
    border-top: none;
    border-bottom: 2px solid #dee2e6;
    /* Add shadow for visual separation when scrolling */
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
    /* Ensure the background fully covers the content below */
    backdrop-filter: blur(5px);
}

/* Ensure the header cells maintain their background */
.wbs-row.header > * {
    background-color: #f8f9fa;
}

.wbs-row.header .wbs-row-content {
    background-color: #f8f9fa;
    margin-left: 0 !important; /* Ensure header never gets indented */
}

.header-cell {
    padding: 0 12px;
    text-align: left;
}

.wbs-row:focus {
    outline: none;
    background-color: #e7f1ff;
    border-left: 3px solid #0056b3;
}

@keyframes fadeInOut {
    0% { opacity: 0.4; }
    50% { opacity: 0.8; }
    100% { opacity: 0.4; }
}

.wbs-header {
    padding: 10px 0;
}

.spinner-border {
    width: 3rem;
    height: 3rem;
}

/* Add style for row number */
.row-number {
    width: 40px;
    flex-shrink: 0;
    text-align: center;
    border-right: 1px solid #eee;
    background-color: #f8f9fa;
    color: #6c757d;
    font-size: 0.85rem;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    height: auto; /* Add this to ensure it stretches */
    align-self: stretch; /* Add this to ensure it stretches */
}
