@import url('https://fonts.googleapis.com/css2?family=Moderustic:wght@300..800&display=swap');

/* RAID Log Table Styles */
.raidlog-table-responsive {
    overflow-x: auto;
}

.raidlog-table {
    width: 100%;
    margin: 20px 0;
    border-collapse: collapse;
    background-color: white;
    border-radius: 0;
    overflow: hidden;
    box-shadow: none;
    border: 1px solid #ddd;
    min-width: 1000px; /* Ensure table has a minimum width */
}

.raidlog-table th, .raidlog-table td {
    padding: 8px;
    text-align: left;
    border: 1px solid #ddd;
}

.raidlog-table th {
    background-color: #0056b3;
    color: white;
    padding: 10px;
    text-align: left;
    border-bottom: 2px solid #004494;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.raidlog-table th:first-child {
    border-top-left-radius: 10px;
}

.raidlog-table th:last-child {
    border-top-right-radius: 10px;
}

.raidlog-table tr:nth-child(even) {
    background-color: #f4f7fa;
}

.raidlog-table tr:hover {
    background-color: #e6eef7;
}

.raidlog-table input[type="text"], .raidlog-table textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 0;
    white-space: pre-wrap; /* Ensure text wraps */
    overflow-wrap: break-word; /* Break long words */
}

.raidlog-table textarea {
    resize: vertical; /* Allow vertical resizing */
}

.raidlog-table .btn {
    padding: 5px 10px;
    border: none;
    border-radius: 0;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.raidlog-table .raidlog-btn-primary {
    background: var(--gradient);
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 10px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.raidlog-table .raidlog-btn-primary:hover {
    transform: translateY(-2px);
    box-shadow: var(--shadow);
}

.raidlog-table .btn-secondary {
    background-color: #6c757d;
    color: white;
}

.raidlog-table .btn-secondary:hover {
    background-color: #5a6268;
}

.raidlog-table .btn-danger {
    background-color: #dc3545;
    color: white;
}

.raidlog-table .btn-danger:hover {
    background-color: #c82333;
}

/* Dropdown Styles */
.raidlog-select {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 0;
    margin: 0 10px;
}

.raidlog-modal {
    display: block; /* Hidden by default */
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
}

.raidlog-modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 500px;
    border-radius: 10px;
}

.raidlog-close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.raidlog-close:hover,
.raidlog-close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

/* Tabs Styles */
.raidlog-tabs {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
}

.raidlog-tab {
    padding: 10px 20px;
    border: 1px solid #ddd;
    border-radius: 5px 5px 0 0;
    cursor: pointer;
    background-color: #f4f7fa;
    transition: background-color 0.3s ease;
    margin-right: -1px; /* To avoid double border */
}

.raidlog-tab.active {
    background-color: #0056b3;
    color: white;
    border-bottom: 1px solid white; /* To blend with the table */
}

.raidlog-tab:hover {
    background-color: #e6eef7;
}

/* Consistent styling for Identified Date, Risk Impact, and Risk Probability fields */
.raidlog-input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 0;
    white-space: pre-wrap; /* Ensure text wraps */
    overflow-wrap: break-word; /* Break long words */
}

/* Comment Box Styles */
.raidlog-comment-box {
    position: absolute;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
    z-index: 1000;
    width: 300px;
}

.raidlog-comment-box textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 5px;
    resize: vertical;
}

.raidlog-comment-box .btn {
    margin-top: 10px;
    width: 100%;
}

/* Sidebar Horizontal Styles */
.raidlog-sidebar-horizontal {
    background-color: #f8f9fa;
    border-bottom: 1px solid #ddd;
    padding: 5px 0; /* Reduce padding to bring it closer to the top navbar */
    margin-top: -10px; /* Adjust margin to reduce space */
}

.raidlog-sidebar-horizontal .nav {
    display: flex;
    justify-content: flex-start; /* Align links to the left */
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.raidlog-sidebar-horizontal .nav-item {
    margin: 0 5px; /* Reduce space between links */
}

.raidlog-sidebar-horizontal .nav-link {
    color: #0056b3;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: color 0.3s ease;
}

.raidlog-sidebar-horizontal .nav-link.active {
    color: #004494;
    border-bottom: 2px solid #004494;
}

.raidlog-sidebar-horizontal .nav-link:hover {
    color: #003366;
}

@media (max-width: 768px) {
    .raidlog-sidebar-horizontal .nav {
        justify-content: center; /* Center links on smaller screens */
    }

    .raidlog-sidebar-horizontal .nav-item {
        margin: 5px; /* Increase space between links on smaller screens */
    }
}

/* Context Menu Styles */
.raidlog-context-menu {
    position: absolute;
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    padding: 15px;
    border-radius: 5px;
    color: #333; /* Ensure text is visible */
    font-size: 14px;
    padding-left: 5px;
}

.filter-checkbox {
    margin-right: 10px;
}

.raidlog-context-menu button {
    display: block;
    width: 100%;
    padding: 5px 10px;
    border: none;
    background: none;
    text-align: left;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.raidlog-context-menu button:hover {
    background-color: #f4f7fa;
}

/* Override react-sliding-pane default styles to match StakeholderRegister */
.slide-pane {
    background-color: #f8f9fa !important;
}

.slide-pane__header {
    background-color: #0056b3 !important;
    color: white !important;
    padding: 20px !important;
}

.slide-pane__title {
    font-size: 1.5rem !important;
    font-weight: bold !important;
}

.slide-pane__close {
    color: white !important;
    opacity: 0.8 !important;
    transition: opacity 0.2s ease !important;
}

.slide-pane__close:hover {
    opacity: 1 !important;
}

/* Matching StakeholderRegister sliding pane styles */
.stakeholder-register-pane-inner {
    padding: 30px;
    background-color: #f8f9fa;
    height: 100%;
}

.stakeholder-register-pane-inner h3 {
    margin-bottom: 30px;
    color: #0056b3;
    font-weight: bold;
    font-size: 1.5rem;
    border-bottom: 2px solid #0056b3;
    padding-bottom: 10px;
}

.stakeholder-register-pane-item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    padding: 12px;
    border-radius: 8px;
    transition: background-color 0.2s ease;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.stakeholder-register-pane-item:hover {
    background-color: #e6eef7;
}

.stakeholder-register-pane-item input[type="checkbox"] {
    margin-right: 15px;
    width: 18px;
    height: 18px;
    cursor: pointer;
}

.stakeholder-register-pane-item label {
    margin: 0;
    font-weight: 500;
    color: #333;
    font-size: 1rem;
    cursor: pointer;
    flex-grow: 1;
}