.project-scheduler-container {
    width: 100%;
    height: calc(100vh - 250px); /* Increase height to accommodate more rows */
    overflow-x: auto;
    overflow-y: hidden;
    padding: 20px;
    background: white;
    border-radius: 8px;
    position: relative;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: thin;
    border: 1px solid #ddd;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
    margin-bottom: 20px;
}

.project-scheduler-timeline-svg {
    min-width: 100%;
    width: 100%;
    height: 100%;
}

.project-scheduler-axis path,
.project-scheduler-axis line {
    stroke: #ddd;
}

.project-scheduler-axis text {
    font-size: 12px;
    font-weight: 500;
}

.project-scheduler-event-rect {
    fill: #0056b3;
    rx: 4;
    ry: 4;
    cursor: pointer;
    transition: fill 0.3s ease;
}

.project-scheduler-event-rect:hover {
    fill: #004494;
}

.project-scheduler-event-text {
    fill: white;
    font-size: 12px;
    dominant-baseline: middle;
}

.project-scheduler-container::-webkit-scrollbar {
    height: 8px;
}

.project-scheduler-container::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
}

.project-scheduler-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.project-scheduler-container::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.project-scheduler-nav {
    margin-top: 10px;
    width: 100%;
    height: 80px; /* Increase navigation height to show stacked events */
    background: white;
    border-radius: 4px;
    position: relative;
    border: 1px solid #ddd;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
    margin-bottom: 20px;
}

.project-scheduler-nav-svg {
    width: 100%;
    height: 100%;
}

.project-scheduler-brush .selection {
    fill: #0056b3;
    fill-opacity: 0.2;
    stroke: #0056b3;
    stroke-width: 1px;
}

.project-scheduler-nav-event-rect {
    fill: #0056b3;
    rx: 2;
    ry: 2;
}

.project-scheduler-wrapper {
    background-color: #f8f9fa;
    min-height: 100vh;
    padding: 0;
    height: 100vh;
}

.project-scheduler-content {
    height: calc(100vh - 120px); /* Full viewport height minus header */
    max-width: none; /* Remove max-width restriction */
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
}

.project-scheduler-title {
    color: #0056b3;
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 1.8rem;
    border-bottom: 2px solid #0056b3;
    padding-bottom: 10px;
}

.project-scheduler-button {
    background-color: #0056b3;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease;
    margin-bottom: 20px;
}

.project-scheduler-button:hover {
    background-color: #004494;
    transform: translateY(-2px);
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.project-scheduler-grid {
    stroke-width: 1px;
    pointer-events: none;
}

/* Add new styles for row highlighting */
.project-scheduler-event:hover .project-scheduler-event-rect {
    fill: #003366;
}

.project-scheduler-row-background {
    fill: transparent;
    transition: fill 0.3s ease;
}

.project-scheduler-row-background:hover {
    fill: rgba(0, 86, 179, 0.05);
}

/* Add styles for drag and drop interactions */
.project-scheduler-event {
    cursor: move;
    transition: transform 0.1s ease;
}

.project-scheduler-dragging {
    opacity: 0.8;
    cursor: grabbing;
}

.project-scheduler-dragging .project-scheduler-event-rect {
    fill: #003366;
}

.project-scheduler-date-preview {
    fill: #666;
    font-size: 10px;
    opacity: 0;
    pointer-events: none;
}

.project-scheduler-dragging .project-scheduler-date-preview {
    opacity: 1;
}

.project-scheduler-event:hover .project-scheduler-event-rect {
    fill: #004494;
    filter: brightness(1.1);
}

/* Add grab cursor indicators */
.project-scheduler-event:hover {
    cursor: grab;
}

.project-scheduler-event:active {
    cursor: grabbing;
}

/* Add new styles for months container */
.months-container {
    display: flex;
    flex-wrap: nowrap;
    gap: 0;  /* Remove any gap between flex items */
}

.months-container > div {
    flex-shrink: 0;  /* Prevent shrinking */
}

.scheduler-button {
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease;
}

.scheduler-button::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: #0056b3;
}

@keyframes shine {
    from {
        left: -100%;
    }
    to {
        left: 100%;
    }
}

.activity-item {
    display: flex;
    align-items: center;
    transition: transform 0.2s, box-shadow 0.2s;
}

.activity-item:hover {
    transform: translateX(5px);
}

.activity-item.drop-target {
    border: 2px dashed #666;
    transform: translateX(10px);
}

.drag-handle {
    cursor: grab;
    padding: 0 8px;
    color: rgba(255, 255, 255, 0.7);
    user-select: none;
}

.activity-item:active {
    cursor: grabbing;
}

@keyframes fadeInOut {
    0% { opacity: 0.4; }
    50% { opacity: 0.8; }
    100% { opacity: 0.4; }
}
