.project-tag {
    display: flex;
    align-items: center;
    background-color: #EBF5FB;
    border: 1px solid #0056b3;
    border-radius: 4px;
    padding: 8px 12px;
    margin: 4px 0;
    cursor: pointer;
    transition: all 0.2s ease;
    width: 100%;
    user-select: none;
}

.project-tag:hover {
    background-color: #D4E6F1;
    transform: translateX(4px);
}

.project-tag:active {
    transform: translateX(4px) scale(0.98);
}

.project-tag-icon {
    color: #0056b3;
    font-weight: bold;
    margin-right: 6px;
    font-size: 0.9rem;
    flex-shrink: 0;
}

.project-tag-name {
    color: #0056b3;
    font-size: 0.85rem;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
