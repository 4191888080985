.card {
    margin-top: 25px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 50px;
    padding: 20px; /* Added padding for better spacing */
}

.card-title {
    margin-bottom: 20px;
}

.login-btn-primary {
    background-color: #007bff;
    border-color: #007bff;
    margin-top: 10px;
}

.login-btn-primary:hover {
    background-color: #0056b3;
    border-color: #0056b3;
}

/* Focus styles for accessibility */
.login-btn-primary:focus {
    outline: 2px solid #0056b3;
    outline-offset: 2px;
}

/* Responsive design */
@media (max-width: 576px) {
    .card {
        margin-top: 15px;
        margin-bottom: 30px;
        padding: 15px; /* Adjusted padding for smaller screens */
    }
}