body, html {
    height: 100%;
    margin: 0;
}

.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
}

main {
    flex: 1;
}