/* ...existing CSS... */

.tab-navigation {
    display: flex;
    gap: 0;
    margin: 0 auto 40px;
    max-width: 1200px;
    background: white;
    border-radius: 12px;
    padding: 8px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
    position: relative;
}

.tab-button {
    flex: 1;
    padding: 16px 24px;
    background: transparent;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    color: #666;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.tab-button svg {
    width: 18px;
    height: 18px;
}

.tab-button:hover {
    color: #007bff;
    background-color: rgba(0,123,255,0.05);
}

.tab-button.active {
    color: #007bff;
    background-color: rgba(0,123,255,0.1);
    font-weight: 600;
}

.tab-button.active::after {
    content: '';
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
    width: 24px;
    height: 3px;
    background-color: #007bff;
    border-radius: 2px;
}

.page-header {
    text-align: center;
    margin: 40px 0;
    color: #333;
    font-size: 2rem;
    font-weight: 600;
    padding-top: 20px;
}

.integrations-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.integration-card {
    background: white;
    border-radius: 8px;
    padding: 24px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    transition: transform 0.2s;
    border: 1px solid #eaeaea;
}

.integration-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.integration-header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.integration-icon {
    width: 40px;
    height: 40px;
    margin-right: 16px;
}

.integration-title {
    margin: 0;
    font-size: 1.25rem;
    color: #333;
}

.integration-description {
    color: #666;
    margin-bottom: 20px;
    line-height: 1.5;
}

.integration-status {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    color: #666;
}

.status-indicator {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 8px;
}

.status-connected {
    background-color: #4CAF50;
}

.status-disconnected {
    background-color: #757575;
}

.integration-button {
    background-color: #0078d4;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.2s;
}

.integration-button:hover {
    background-color: #006cbd;
}

.integration-button.connected {
    background-color: #dc3545;
}

.integration-button.connected:hover {
    background-color: #c82333;
}

.profile-picture-section {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.profile-picture-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.profile-picture {
    width: 180px;
    height: 180px;
    border-radius: 50%;
    object-fit: cover;
    border: 4px solid #fff;
    box-shadow: 0 2px 8px rgba(0,0,0,0.15);
}

.profile-picture-placeholder {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #666;
}

.upload-picture-button {
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.upload-picture-button:hover {
    background-color: #0056b3;
}

.upload-picture-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.crop-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.crop-container {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 90vw;
    max-height: 90vh;
    overflow: auto;
}

.crop-actions {
    margin-top: 20px;
    display: flex;
    gap: 10px;
    justify-content: center;
}

.save-crop-button,
.cancel-crop-button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
}

.save-crop-button {
    background-color: #007bff;
    color: white;
}

.save-crop-button:hover {
    background-color: #0056b3;
}

.cancel-crop-button {
    background-color: #dc3545;
    color: white;
}

.cancel-crop-button:hover {
    background-color: #c82333;
}

.ReactCrop__crop-selection {
    border: 2px solid #007bff;
}

.account-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.account-header {
    display: flex;
    align-items: center;
    gap: 40px;
    padding: 30px;
    background: white;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
    margin-bottom: 30px;
}

.account-info {
    flex: 1;
}

.account-name {
    font-size: 2rem;
    margin: 0 0 8px 0;
    color: #333;
}

.account-email {
    color: #666;
    font-size: 1.1rem;
    margin: 0;
}

.account-details {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    margin-top: 30px;
}

.detail-card {
    background: white;
    border-radius: 12px;
    padding: 24px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
    transition: transform 0.2s ease;
}

.detail-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0,0,0,0.15);
}

.detail-card h3 {
    margin: 0 0 16px 0;
    color: #333;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    gap: 8px;
}

.detail-card h3 svg {
    width: 20px;
    height: 20px;
    color: #007bff;
}

.detail-field {
    margin-bottom: 16px;
}

.detail-label {
    font-size: 0.9rem;
    color: #666;
    margin-bottom: 4px;
    display: block;
}

.detail-value {
    font-size: 1.1rem;
    color: #333;
}

.edit-button {
    background: transparent;
    border: 1px solid #007bff;
    color: #007bff;
    padding: 6px 12px;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s;
}

.edit-button:hover {
    background: #007bff;
    color: white;
}

.time-zone-info {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #666;
    font-size: 0.9rem;
    margin-top: 8px;
}

/* Modal styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    width: 100%;
    max-width: 500px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-content h2 {
    margin-bottom: 1.5rem;
    color: #333;
}

.form-group {
    margin-bottom: 1.5rem;
}

.form-group label {
    display: block;
    margin-bottom: 0.5rem;
    color: #555;
}

.form-group input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
}

.modal-actions {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
    margin-top: 2rem;
}

.error-message {
    color: #dc3545;
    margin: 1rem 0;
    padding: 0.5rem;
    background-color: #fff1f1;
    border-radius: 4px;
}

.success-message {
    color: #28a745;
    margin: 1rem 0;
    padding: 0.5rem;
    background-color: #f1fff1;
    border-radius: 4px;
}

.save-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
}

.cancel-button {
    background-color: #6c757d;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
}

.save-button:hover,
.cancel-button:hover {
    opacity: 0.9;
}

/* Add/update email change form styles */
.email-change-form {
    margin-top: 1rem;
}

.email-change-form .form-group {
    margin-bottom: 1rem;
}

.email-change-form input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 0.9rem;
}

.form-actions {
    display: flex;
    gap: 0.5rem;
    margin-top: 1rem;
}

.form-actions button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
}

.save-button {
    background-color: #007bff;
    color: white;
}

.cancel-button {
    background-color: #6c757d;
    color: white;
}

.error-message {
    color: #dc3545;
    font-size: 0.9rem;
    margin-top: 0.5rem;
    padding: 0.5rem;
    background-color: #fff1f1;
    border-radius: 4px;
}

.success-message {
    color: #28a745;
    font-size: 0.9rem;
    margin-top: 0.5rem;
    padding: 0.5rem;
    background-color: #f1fff1;
    border-radius: 4px;
}

/* Add password change form styles */
.password-change-form {
    margin-top: 1rem;
}

.password-change-form .form-group {
    margin-bottom: 1rem;
}

.password-change-form input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 0.9rem;
}

/* Billing styles */
.billing-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.billing-header {
    margin-bottom: 30px;
    padding: 20px;
    background: white;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.billing-header h2 {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #333;
    margin: 0 0 10px 0;
    font-size: 1.5rem;
}

.billing-header p {
    color: #666;
    margin: 0;
}

.billing-details {
    display: grid;
    grid-template-columns: 300px 1fr;
    gap: 24px;
    max-width: 1200px;
    margin: 0 auto;
}

.plan-badge {
    display: inline-flex;
    align-items: center;
    padding: 8px 16px;
    border-radius: 20px;
    font-size: 1rem;
    font-weight: 600;
    margin: 15px 0;
}

.plan-badge.freeforever {
    background-color: #e3f2fd;
    color: #1976d2;
    border: 1px solid rgba(25, 118, 210, 0.1);
}

.plan-badge.professional {
    background-color: #e3f2fd;
    color: #1976d2;
    border: 1px solid rgba(25, 118, 210, 0.1);
}

.current-period {
    color: #666;
    margin: 15px 0;
    padding: 12px;
    background-color: #f8f9fa;
    border-radius: 8px;
    font-size: 0.9rem;
    border: 1px solid #eee;
}

.upcoming-payment {
    margin-top: 20px;
    padding: 15px;
    background-color: #f8f9fa;
    border-radius: 8px;
    border: 1px solid #e9ecef;
    color: #333;
}

.upcoming-payment strong {
    color: #1976d2;
    font-weight: 600;
}

.billing-history-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    margin-top: 15px;
}

.billing-history-table th {
    background-color: #f8f9fa;
    font-weight: 600;
    color: #333;
    padding: 16px;
    text-align: left;
    border-bottom: 2px solid #eee;
}

.billing-history-table td {
    padding: 16px;
    border-bottom: 1px solid #eee;
}

.billing-history-table tr:hover {
    background-color: #f8f9fa;
}

.payment-placeholder {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    margin: 15px 0;
    color: #666;
}

.billing-history-empty {
    padding: 20px;
    text-align: center;
    background-color: #f5f5f5;
    border-radius: 8px;
    color: #666;
}

.billing-history-card {
    overflow: hidden;
    grid-column: 2;
    grid-row: 1 / span 2;
    height: 100%;
}

.billing-history-table-container {
    overflow-x: auto;
    margin-top: 1rem;
}

.billing-history-table {
    width: 100%;
    border-collapse: collapse;
    min-width: 800px;
}

.billing-history-table th,
.billing-history-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #eee;
}

.billing-history-table th {
    background-color: #f8f9fa;
    font-weight: 600;
    color: #333;
}

.status-badge {
    display: inline-block;
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 0.85rem;
    font-weight: 500;
}

.status-badge.paid {
    background-color: #e8f5e9;
    color: #2e7d32;
}

.status-badge.pending {
    background-color: #fff3e0;
    color: #f57c00;
}

.status-badge.failed {
    background-color: #ffebee;
    color: #c62828;
}

.invoice-download-link {
    color: var(--primary);
    text-decoration: none;
    font-weight: 500;
}

.invoice-download-link:hover {
    text-decoration: underline;
}

.current-period {
    color: #666;
    margin-top: 0.5rem;
}

.upcoming-payment {
    background-color: #f8f9fa;
    padding: 1rem;
    border-radius: 8px;
    margin-top: 0.5rem;
}

.no-history {
    color: #666;
    text-align: center;
    padding: 2rem;
    background-color: #f8f9fa;
    border-radius: 8px;
}

@media (max-width: 768px) {
    .billing-details {
        grid-template-columns: 1fr;
    }

    .billing-history-card {
        grid-column: 1;
        grid-row: auto;
    }
}

.subscription-actions {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #eee;
}

.subscription-actions h4 {
    margin-bottom: 15px;
    color: #333;
}

.billing-toggle {
    display: flex;
    gap: 20px;
    margin-bottom: 15px;
}

.billing-toggle label {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

.upgrade-button {
    background: var(--primary);
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 6px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
}

.upgrade-button:hover {
    background: var(--primary-dark);
    transform: translateY(-1px);
}

.downgrade-button {
    background: #fff;
    color: #dc3545;
    border: 1px solid #dc3545;
    padding: 10px 20px;
    border-radius: 6px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
}

.downgrade-button:hover {
    background: #dc3545;
    color: white;
}

.upgrade-section {
    padding: 20px 0;
}

.upgrade-features {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 15px;
    margin: 25px 0;
    background: #f8fbff;
    padding: 20px;
    border-radius: 12px;
    border: 1px solid #e3f2fd;
}

.feature-item {
    display: flex;
    align-items: center;
    gap: 12px;
    color: #333;
    font-size: 1rem;
    padding: 8px 0;
}

.feature-icon {
    color: #4CAF50;
    width: 20px;
    height: 20px;
    background: #e8f5e9;
    padding: 5px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.billing-options {
    display: flex;
    gap: 25px;
    margin: 30px 0;
    justify-content: center;
}

.billing-option {
    flex: 1;
    max-width: 220px;
    padding: 25px;
    border: 2px solid #e9ecef;
    border-radius: 16px;
    text-align: center;
    cursor: pointer;
    transition: all 0.35s ease;
    position: relative;
    background: white;
    box-shadow: 0 3px 10px rgba(0,0,0,0.04);
}

.billing-option:hover {
    border-color: #007bff;
    transform: translateY(-4px);
    box-shadow: 0 6px 15px rgba(0,123,255,0.1);
}

.billing-option.selected {
    border-color: #007bff;
    background-color: #f8fbff;
    transform: translateY(-4px);
    box-shadow: 0 8px 20px rgba(0,123,255,0.15);
}

.option-header {
    font-weight: 600;
    color: #333;
    margin-bottom: 12px;
    font-size: 1.1rem;
}

.option-price {
    font-size: 2.2rem;
    font-weight: 700;
    color: #007bff;
    margin: 5px 0;
    text-shadow: 0 1px 2px rgba(0,123,255,0.1);
}

.option-period {
    color: #666;
    font-size: 1rem;
    margin-top: 3px;
}

.save-badge {
    position: absolute;
    top: -12px;
    right: -12px;
    background: linear-gradient(135deg, #4CAF50, #2E7D32);
    color: white;
    padding: 6px 10px;
    border-radius: 20px;
    font-size: 0.8rem;
    font-weight: 600;
    box-shadow: 0 2px 5px rgba(46, 125, 50, 0.3);
}

.upgrade-button {
    width: 100%;
    max-width: 300px;
    margin: 30px auto 0;
    display: block;
    padding: 14px;
    font-size: 1.1rem;
    background: linear-gradient(135deg, #007bff, #0056b3);
    color: white;
    border: none;
    border-radius: 8px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 10px rgba(0,123,255,0.3);
}

.upgrade-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 15px rgba(0,123,255,0.35);
}

.downgrade-button {
    background: #fff;
    color: #dc3545;
    border: 1px solid #dc3545;
    padding: 12px 20px;
    border-radius: 8px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
    display: inline-block;
    margin-top: 10px;
}

.downgrade-button:hover {
    background: #fff1f0;
    color: #c82333;
    transform: translateY(-2px);
    box-shadow: 0 3px 8px rgba(220, 53, 69, 0.15);
}

/* Enhanced plan badge */
.plan-badge {
    display: inline-flex;
    align-items: center;
    padding: 8px 16px;
    border-radius: 20px;
    font-size: 1rem;
    font-weight: 600;
    margin: 15px 0;
    box-shadow: 0 2px 5px rgba(0,0,0,0.05);
}

.plan-badge.freeforever {
    background: linear-gradient(135deg, #e3f2fd, #bbdefb);
    color: #1565c0;
    border: none;
}

.plan-badge.professional {
    background: linear-gradient(135deg, #e3f2fd, #bbdefb);
    color: #1565c0;
    border: none;
}

/* Subscription section header improvements */
.subscription-actions h4 {
    margin-bottom: 15px;
    color: #333;
    font-size: 1.2rem;
    text-align: center;
    position: relative;
    padding-bottom: 10px;
}

.subscription-actions h4:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 3px;
    background: linear-gradient(90deg, #007bff, #0056b3);
    border-radius: 3px;
}

/* Improve tablet and mobile responsiveness */
@media (max-width: 768px) {
    .billing-options {
        flex-direction: column;
        align-items: center;
    }
    
    .billing-option {
        width: 100%;
        max-width: 280px;
    }
    
    .upgrade-features {
        grid-template-columns: 1fr;
    }
}

/* Clean subscription styles */
.upgrade-section,
.manage-plan-section {
    padding: 15px 0;
}

.upgrade-section h4,
.manage-plan-section h4 {
    text-align: center;
    font-size: 1.2rem;
    margin-bottom: 20px;
    color: #333;
}

.plan-comparison-card {
    background: #fff;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
    overflow: hidden;
    margin-bottom: 25px;
}

.plan-comparison-header {
    display: flex;
    background: #f5f7f9;
    border-bottom: 1px solid #e0e0e0;
}

.plan-column {
    flex: 1;
    padding: 12px 15px;
    text-align: center;
}

.plan-column.highlight {
    background: #e3f2fd;
    font-weight: 500;
}

.plan-title {
    font-weight: 600;
    color: #333;
}

.plan-comparison-body {
    border-collapse: collapse;
    width: 100%;
}

.feature-row {
    display: flex;
    border-bottom: 1px solid #e0e0e0;
}

.feature-row:last-child {
    border-bottom: none;
}

.feature-name {
    flex: 1.5;
    padding: 10px 15px;
    font-weight: 500;
    color: #555;
    background: #f9f9f9;
}

/* Pricing section */
.subscription-pricing {
    max-width: 500px;
    margin: 0 auto;
}

.price-option-container {
    display: flex;
    align-items: stretch;
    justify-content: center;
    margin-bottom: 20px;
    gap: 15px;
    width: 100%;
    position: relative;
}

.price-option {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px;
    border: 2px solid #e2e8f0;
    border-radius: 12px;
    cursor: pointer;
    transition: all 0.2s ease;
    min-height: 140px; /* Set minimum height */
    width: 100%;
    position: relative;
}

.price-separator {
    display: flex;
    align-items: center;
    font-weight: 500;
    color: #888;
    padding: 0 12px;
    align-self: stretch;
}

.price-amount {
    font-size: 1.8rem;
    font-weight: 700;
    color: #333;
}

.price-period {
    font-size: 0.9rem;
    color: #666;
    margin-top: 5px;
}

.price-save {
    background: #4caf50;
    color: white;
    font-size: 0.8rem;
    font-weight: 600;
    padding: 2px 8px;
    border-radius: 12px;
    display: inline-block;
    margin-top: 8px;
    position: absolute;
    top: -10px;
    right: -10px;
    background: #dcfce7;
    color: #166534;
    font-size: 0.75rem;
    font-weight: 600;
    padding: 4px 8px;
    border-radius: 20px;
    z-index: 1;
}

.upgrade-button {
    background-color: #1976d2;
    color: white;
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    padding: 12px 25px;
    font-size: 1rem;
    font-weight: 600;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: block;
    transition: background-color 0.2s, transform 0.2s;
}

.upgrade-button:hover {
    background-color: #1565c0;
    transform: translateY(-2px);
}

.manage-plan-section p {
    text-align: center;
    color: #555;
    margin-bottom: 20px;
}

.downgrade-button {
    background: transparent;
    color: #d32f2f;
    border: 1px solid #d32f2f;
    padding: 10px 20px;
    border-radius: 4px;
    font-weight: 500;
    cursor: pointer;
    display: block;
    margin: 0 auto;
    transition: all 0.2s ease;
}

.downgrade-button:hover {
    background: #ffebee;
}

/* Improved responsive design */
@media (max-width: 576px) {
    .price-option-container {
        flex-direction: column;
        align-items: center;
    }
    
    .price-option {
        width: 100%;
        max-width: 280px;
    }
    
    .price-separator {
        padding: 10px 0;
    }
}

/* Feature showcase card for free users */
.feature-showcase-card {
    grid-column: 2;
    grid-row: 1 / span 2;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.feature-showcase {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    flex: 1;
}

.feature-showcase-item {
    padding: 20px;
    background-color: #f8fbff;
    border-radius: 8px;
    border: 1px solid #e3f2fd;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    text-align: center;
}

.feature-showcase-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}

.feature-icon-large {
    width: 60px;
    height: 60px;
    margin: 0 auto 15px;
    border-radius: 50%;
    background: #e3f2fd;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #1976d2;
}

.feature-icon-large svg {
    width: 30px;
    height: 30px;
}

.feature-showcase-item h4 {
    margin: 0 0 10px;
    color: #333;
    font-size: 1.1rem;
}

.feature-showcase-item p {
    color: #666;
    margin: 0;
    font-size: 0.95rem;
    line-height: 1.5;
}

.upcoming-payment-section {
    margin-top: 20px;
}

.upcoming-payment-section h4 {
    font-size: 1rem;
    color: #333;
    margin-bottom: 10px;
}

@media (min-width: 768px) {
    .feature-showcase {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 768px) {
    .feature-showcase-card {
        grid-column: 1;
        grid-row: auto;
    }
}

/* Updated billing layout styles */
.current-plan-card {
    grid-column: 1;
    background: white;
    padding: 28px;
    border-radius: 16px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.06);
}

.current-plan-card h3 {
    color: #1a1f36;
    font-size: 1.25rem;
    margin-bottom: 20px;
}

.plan-description {
    color: #64748b;
    font-size: 0.95rem;
    line-height: 1.6;
    margin: 16px 0;
}

.upgrade-options-card {
    grid-column: 2;
    grid-row: 1 / span 2;
    background: white;
    padding: 28px;
    border-radius: 16px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.06);
}

.plan-comparison-card {
    border: 1px solid #e2e8f0;
    border-radius: 12px;
    overflow: hidden;
    margin: 24px 0;
}

.plan-comparison-header {
    background: #f8fafc;
    border-bottom: 1px solid #e2e8f0;
    padding: 16px 0;
}

.plan-column {
    padding: 12px 20px;
    text-align: center;
}

.plan-column.highlight {
    background: #f0f9ff;
}

.plan-title {
    font-weight: 600;
    color: #1e293b;
    font-size: 0.95rem;
}

.feature-row {
    display: flex;
    border-bottom: 1px solid #e2e8f0;
}

.feature-name {
    flex: 1.5;
    padding: 12px 20px;
    color: #475569;
    font-weight: 500;
    font-size: 0.95rem;
    background: #f8fafc;
}

.price-option-container {
    display: flex;
    align-items: center;
    gap: 16px;
    margin: 32px 0;
}

.price-option {
    flex: 1;
    padding: 24px;
    border: 2px solid #e2e8f0;
    border-radius: 12px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.price-option:hover {
    border-color: #0ea5e9;
    transform: translateY(-2px);
}

.price-option.selected {
    border-color: #0ea5e9;
    background: #f0f9ff;
}

.price-amount {
    font-size: 2rem;
    font-weight: 700;
    color: #0f172a;
    margin-bottom: 4px;
}

.price-period {
    color: #64748b;
    font-size: 0.9rem;
}

.price-save {
    display: inline-block;
    background: #dcfce7;
    color: #166534;
    font-size: 0.75rem;
    font-weight: 600;
    padding: 4px 8px;
    border-radius: 20px;
    margin-top: 8px;
}

.upgrade-button {
    background: #0ea5e9;
    color: white;
    width: 100%;
    max-width: 300px;
    margin: 24px auto 0;
    padding: 14px;
    font-size: 1rem;
    font-weight: 600;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.upgrade-button:hover {
    background: #0284c7;
    transform: translateY(-2px);
}

.downgrade-button {
    color: #dc2626;
    background: #fff;
    border: 1px solid #dc2626;
    padding: 12px 24px;
    border-radius: 8px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    margin-top: 16px;
}

.downgrade-button:hover {
    background: #fef2f2;
    color: #b91c1c;
}

.feature-showcase-card {
    margin-top: 24px;
    padding: 28px;
    background: white;
    border-radius: 16px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.06);
}

.feature-showcase {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
    margin-top: 20px;
}

.feature-showcase-item {
    padding: 24px;
    background: #f8fafc;
    border-radius: 12px;
    text-align: center;
    transition: transform 0.2s ease;
}

.feature-showcase-item:hover {
    transform: translateY(-4px);
}

.feature-icon-large {
    width: 48px;
    height: 48px;
    margin: 0 auto 16px;
    border-radius: 12px;
    background: #f0f9ff;
    color: #0ea5e9;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 768px) {
    .billing-details {
        grid-template-columns: 1fr;
    }

    .feature-showcase {
        grid-template-columns: 1fr;
    }

    .price-option-container {
        flex-direction: column;
    }

    .price-option {
        width: 100%;
    }
}
.current-plan-card {
    background: white;
    padding: 28px;
    border-radius: 16px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.06);
}

.current-features-list {
    list-style: none;
    padding: 0;
    margin: 16px 0;
}

.current-features-list li {
    color: #64748b;
    font-size: 0.95rem;
    padding: 8px 0;
    border-bottom: 1px solid #e2e8f0;
    display: flex;
    align-items: center;
    gap: 8px;
}

.current-features-list li:last-child {
    border-bottom: none;
}

.upgrade-card {
    background: white;
    padding: 28px;
    border-radius: 16px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.06);
}

.pro-features-list {
    margin: 24px 0;
}

.feature-item {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    padding: 16px 0;
    border-bottom: 1px solid #e2e8f0;
}

.feature-item:last-child {
    border-bottom: none;
}

.feature-check {
    color: #0ea5e9;
    width: 20px;
    height: 20px;
    margin-top: 4px;
}

.feature-item strong {
    display: block;
    color: #1e293b;
    margin-bottom: 4px;
}

.feature-item p {
    color: #64748b;
    font-size: 0.9rem;
    margin: 0;
}

/* Update responsive styles */
@media (max-width: 768px) {
    .billing-details {
        grid-template-columns: 1fr;
    }
    
    .upgrade-card {
        margin-top: 24px;
    }
}

.payment-form-container {
    max-width: 600px;
    margin: 0 auto;
    background: white;
    padding: 28px;
    border-radius: 16px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.06);
}

.payment-form-container h3 {
    color: #1a1f36;
    font-size: 1.25rem;
    margin-bottom: 24px;
    text-align: center;
}

/* Update responsive styles */
@media (max-width: 768px) {
    .payment-form-container {
        padding: 20px;
        margin: 0 16px;
    }
}

.subscription-cancel-notice {
    margin: 16px 0;
    padding: 16px;
    background-color: #fff9db;
    border: 1px solid #ffec99;
    border-radius: 8px;
    color: #805700;
}

.notice-badge {
    display: inline-flex;
    padding: 4px 10px;
    background-color: #ffc078;
    color: #7c4700;
    font-size: 0.8rem;
    font-weight: 600;
    border-radius: 20px;
    margin-bottom: 8px;
}

.subscription-cancel-notice p {
    margin: 8px 0;
    font-size: 0.95rem;
    line-height: 1.5;
}

.subscription-cancel-notice strong {
    color: #e8590c;
}

.resume-subscription-button {
    background-color: #1976d2;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 0.9rem;
    font-weight: 500;
    margin-top: 10px;
    cursor: pointer;
    transition: background-color 0.2s, transform 0.2s;
}

.resume-subscription-button:hover {
    background-color: #1565c0;
    transform: translateY(-2px);
}
