/* Epic Container */
.epics-container {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    padding: 20px;
}

.epic-form-container {
    border-left: 4px solid #007bff;
}

/* Table Styling */
.epic-list-container {
    background: white;
    border-radius: 0.5rem;
    box-shadow: 0 0.125rem 0.25rem rgba(0,0,0,0.075);
}

.epic-list-container .table {
    margin-bottom: 0;
}

.epic-list-container .table th {
    background-color: #f8f9fa;
    border-bottom: 2px solid #dee2e6;
    color: #495057;
    font-weight: 600;
    padding: 1rem;
}

.epic-list-container .table td {
    padding: 1rem;
    vertical-align: middle;
}

.sortable-header {
    cursor: pointer;
    user-select: none;
}

.sortable-header:hover {
    background-color: #f5f5f5;
}

.epic-title-cell {
    max-width: 350px;
}

.epic-description {
    font-size: 0.85rem;
    margin-top: 5px;
    white-space: normal;
    word-break: break-word;
}

/* Epic Row Styling */
.epic-row {
    transition: background-color 0.15s ease-in-out;
}

.epic-row:hover {
    background-color: rgba(13, 110, 253, 0.05);
}

.epic-title-cell:hover {
    color: #007bff;
}

/* Priority Badge Styling */
.badge {
    padding: 0.5em 0.75em;
    font-weight: 500;
    letter-spacing: 0.3px;
}

.badge.bg-high {
    background: linear-gradient(45deg, #dc3545 0%, #fd7e14 100%);
}

.badge.bg-medium {
    background: linear-gradient(45deg, #ffc107 0%, #fd7e14 100%);
}

.badge.bg-low {
    background: linear-gradient(45deg, #0dcaf0 0%, #0d6efd 100%);
}

/* Epic Controls */
.epic-controls {
    background: white;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 0.125rem 0.25rem rgba(0,0,0,0.075);
    margin-bottom: 1.5rem;
    width: 100%;
    flex-grow: 1;
    margin-right: 1rem;
}

.epic-controls .input-group {
    width: 100%;
}

.epic-controls .form-select {
    min-width: 140px;
    flex-grow: 1;
    border-radius: 4px;
}

.epic-controls .form-control {
    border-radius: 4px;
}

.epic-controls .input-group-text {
    background-color: #f8f9fa;
    border-right: none;
}

.epic-controls .form-control,
.epic-controls .form-select {
    border-left: none;
}

/* Epic Modal Styling */
.epic-modal .modal-content {
    border: none;
    box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.15);
    border-radius: 0.5rem;
}

.epic-modal .modal-header {
    background: linear-gradient(135deg, #0d6efd 0%, #0dcaf0 100%);
    color: white;
    padding: 1rem 1.5rem;
}

.epic-modal .modal-title {
    font-size: 1.25rem;
    font-weight: 600;
}

.epic-modal .modal-body {
    padding: 1.5rem;
    background-color: #f8f9fa;
}

.epic-modal .form-label {
    font-weight: 500;
    color: #495057;
}

.epic-modal .form-control,
.epic-modal .form-select {
    border: 1px solid #dee2e6;
    border-radius: 0.375rem;
    padding: 0.5rem 0.75rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.epic-modal .modal-footer {
    background-color: #f8f9fa;
    border-top: 1px solid #dee2e6;
    padding: 1rem 1.5rem;
}

/* Enhanced Epic Modal Styling */
.epic-modal .modal-content {
    border: none;
    box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.15);
    border-radius: 0.5rem;
}

.epic-modal .modal-header {
    background: linear-gradient(135deg, #0d6efd 0%, #0dcaf0 100%);
    color: white;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    padding: 1rem 1.5rem;
}

.epic-modal .modal-title {
    font-size: 1.25rem;
    font-weight: 600;
}

.epic-modal .modal-header .btn-close {
    color: white;
    filter: brightness(0) invert(1);
}

/* Epic Card Grid Layout */
.epics-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 1.5rem;
    margin-top: 1.5rem;
}

.epic-card-item {
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
    transition: all 0.3s ease;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(0, 0, 0, 0.05);
}

.epic-card-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
    border-color: rgba(13, 110, 253, 0.2);
}

/* Epic Card Styling */
.epic-card-item {
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
    transition: all 0.3s ease;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(0, 0, 0, 0.05);
    min-height: 140px;
}

.epic-card-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
    border-color: rgba(13, 110, 253, 0.2);
}

.epic-card-header {
    padding: 1.25rem 1.5rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.epic-card-number {
    font-family: 'Roboto Mono', monospace;
    font-size: 0.85rem;
    font-weight: 600;
    color: #6c757d;
    background-color: #f8f9fa;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    border: 1px solid #e9ecef;
}

.epic-card-title {
    padding: 1.25rem 1.5rem;
    flex: 1;
    font-size: 1.1rem;
    font-weight: 600;
    color: #2c3e50;
}

.epic-card-description {
    padding: 0 1.5rem 1rem;
    color: #6c757d;
    font-size: 0.9rem;
    line-height: 1.5;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    flex: 1;
}

.epic-card-footer {
    padding: 1rem 1.5rem;
    background-color: rgba(0, 0, 0, 0.02);
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.epic-meta {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.epic-card-assignee {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.85rem;
    color: #495057;
}

.epic-card-assignee-avatar {
    width: 28px;
    height: 28px;
    background-color: #e0e7ff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    color: #4f46e5;
    font-size: 0.75rem;
}

.epic-card-status {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.epic-card-status-icon {
    font-size: 0.85rem;
}

.epic-card-status-text {
    font-size: 0.85rem;
    font-weight: 500;
}

.epic-priority-indicator {
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
}

.epic-actions {
    display: flex;
    gap: 0.5rem;
}

.epic-action-btn {
    width: 32px;
    height: 32px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #6c757d;
    background-color: #f8f9fa;
    border: 1px solid #e9ecef;
    transition: all 0.2s;
    cursor: pointer;
}

.epic-action-btn:hover {
    background-color: #e9ecef;
    color: #212529;
}

/* Epic Comments Section */
.epic-comments-section {
    background: white;
    border-radius: 12px;
    padding: 1.25rem;
    margin-bottom: 1rem;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.epic-comments-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.75rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #e9ecef;
}

.epic-comments-list {
    max-height: 250px;
    overflow-y: auto;
    margin-bottom: 1rem;
    padding-right: 0.5rem;
}

.comment-item {
    padding: 0.75rem;
    margin-bottom: 0.75rem;
    background-color: #f8f9fa;
    border-radius: 8px;
    border-left: 3px solid #0d6efd;
}

/* Override previous comment styles */
.epic-comments-section .comment-header {
    display: flex !important;
    justify-content: space-between !important;
    align-items: flex-start !important;
    margin-bottom: 0.5rem !important;
    position: relative !important;
}

.epic-comments-section .comment-actions {
    position: static !important; /* Change from absolute to static */
    display: flex !important;
    align-items: center !important;
    gap: 8px !important;
}

.epic-comments-section .comment-author {
    font-weight: 600 !important;
    flex: 1 !important;
}

.epic-comments-section .comment-content {
    margin-top: 0.5rem !important;
    padding-right: 0 !important;
    clear: both !important;
}

/* Epic Detail Panel */
.epic-detail-section {
    background: #f8f9fa;
    border-radius: 12px;
    padding: 1.5rem;
    margin-bottom: 1.5rem;
}

.epic-detail-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    margin-bottom: 1.5rem;
}

.epic-detail-item {
    background: white;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
    height: 100%;
    display: flex;
    flex-direction: column;
}

/* Success Criteria Section */
.success-criterion-item {
    background: white;
    border-radius: 8px;
    padding: 0.75rem;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin-bottom: 0.5rem;
}

.success-criterion-item .form-check {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
}

.success-criteria-progress {
    margin-top: 1.5rem;
    padding-top: 1.5rem;
    border-top: 1px solid #dee2e6;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .epic-controls .row > div {
        margin-bottom: 0.5rem;
    }
    
    .epic-controls .input-group {
        width: 100%;
    }
    
    .view-toggle-container {
        margin-top: 1rem;
        width: 100%;
        justify-content: center;
    }

    .epics-grid {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 1200px) {
    .epic-detail-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 992px) {
    .epic-detail-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .epic-detail-grid {
        grid-template-columns: 1fr;
    }

    .epic-card-footer {
        flex-direction: column;
        gap: 1rem;
    }

    .epic-meta {
        flex-wrap: wrap;
    }
}

.epic-card {
    background: white;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin-bottom: 15px;
}

/* Enhanced Epic Layout */
.epics-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 2rem;
}

.header-content {
    flex: 1;
}

.epic-metrics {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
    margin-top: 1rem;
}

.metric-card {
    background: white;
    padding: 1.25rem;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.05);
    transition: transform 0.2s ease;
}

.metric-card:hover {
    transform: translateY(-2px);
}

.epics-toolbar {
    background: white;
    padding: 1rem;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.05);
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}

.search-filters {
    display: flex;
    gap: 1rem;
    flex: 1;
}

.search-filters .input-group {
    max-width: 400px;
}

.view-controls {
    display: flex;
    gap: 0.5rem;
}

.view-controls .btn {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Enhanced Epic Card Styling */
.epic-card {
    position: relative;
    background: white;
    border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 0 4px 12px rgba(0,0,0,0.05);
    transition: all 0.3s ease;
    border: 1px solid #e9ecef;
    overflow: hidden;
}

.epic-card:hover {
    transform: translateY(-4px);
    box-shadow: 0 8px 24px rgba(0,0,0,0.1);
}

.epic-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    background: linear-gradient(to bottom, var(--priority-color-start), var(--priority-color-end));
}

.epic-card[data-priority="high"] {
    --priority-color-start: #dc3545;
    --priority-color-end: #fd7e14;
}

.epic-card[data-priority="medium"] {
    --priority-color-start: #ffc107;
    --priority-color-end: #fd7e14;
}

.epic-card[data-priority="low"] {
    --priority-color-start: #0dcaf0;
    --priority-color-end: #0d6efd;
}