.dailyJournal-board {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.dailyJournal-column {
    background-color: #E6EEF7;
    border-radius: 10px;
    padding: 10px;
    width: 32.5%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
    max-height: 600px; /* Set a maximum height */
    overflow-y: auto; /* Enable vertical scrolling */
}

.dailyJournal-column-title {
    background-color: #004494;
    color: white;
    padding: 8px 12px;
    border-radius: 10px;
    text-align: center;
    font-size: 20px;
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dailyJournal-column ul {
    list-style-type: none;
    padding: 0;
    height: auto; /* Adjust height to auto */
}

.dailyJournal-task-item {
    background-color: white;
    margin: 8px 0;
    padding: 12px;
    padding-right: 12px; /* Removed extra padding-right */
    padding-top: 16px; /* Added more top padding for the delete button */
    border-radius: 3px;
    border: 1px solid #ddd;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    cursor: grab; /* Optionally change cursor if editing is removed */
    position: relative;
    transition: all 0.2s ease;
    min-height: 60px;
}

.dailyJournal-task-item:hover {
    background-color: #f4f7fa;
}

.dailyJournal-task-item:active {
    cursor: grabbing;
}

.dailyJournal-dragging-over {
    background-color: #d0e1f9;
}

.dailyJournal-input {
    display: flex;
    justify-content: center;
    align-items: flex-start; /* Changed from center to flex-start to handle dropdown */
    margin-bottom: 20px;
    width: 100%;
    padding: 0 20px;
}

.dailyJournal-input-container {
    position: relative;
    flex: 1;
    margin-right: 10px;
    z-index: 2;
}

.dailyJournal-input input {
    width: 100%;
    border: 1px solid #ddd;
    padding: 8px 12px;
    border-radius: 5px;
    height: 40px; /* Adjusted height for better alignment */
}

.dailyJournal-addTask, .dailyJournal-duplicateTasks {
    background-color: #0056b3;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    height: 40px; /* Adjusted height for better alignment */
    margin-right: 10px;
}

.dailyJournal-addTask:hover, .dailyJournal-duplicateTasks:hover {
    background-color: #004494;
}

.dailyJournal-addTask:active, .dailyJournal-addTask:focus, .dailyJournal-duplicateTasks:active, .dailyJournal-duplicateTasks:focus {
    background-color: #003366;
    outline: none;
}

/* Improved delete button styling */
.dailyJournal-delete-button {
    position: absolute;
    top: 8px;
    right: 8px;
    transform: none;
    opacity: 0;
    transition: all 0.2s ease;
    z-index: 2;
    background: rgba(220, 53, 69, 0.1);
    border: none;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #dc3545;
    font-size: 16px;
    padding: 0;
}

.dailyJournal-task-item:hover .dailyJournal-delete-button {
    opacity: 1;
}

.dailyJournal-delete-button:hover {
    background: rgba(220, 53, 69, 0.2);
    transform: scale(1.1);
}

.dailyJournal-delete-button:active {
    transform: scale(0.95);
}

.dailyJournal-date-input {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.dailyJournal-date-input label {
    margin-right: 10px;
}

.dailyJournal-date-picker {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    overflow-x: auto;
}

.dailyJournal-day {
    padding: 3px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.dailyJournal-day-selected {
    background-color: #004494;
    color: white;
}

.dailyJournal-month-year {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    position: relative;
    display: inline-block;
    background: linear-gradient(90deg, #0056b3, #4C89CA, #0056b3);
    background-size: 200%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text; /* Standard property for compatibility */
    animation: shiny 5s linear infinite;
}

.dailyJournal-arrow {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    margin: 0 10px;
}

.dailyJournal-arrow:hover {
    color: #004494;
}

.dailyJournal-task-item input {
    width: 100%;
    border: none;
    background: none;
    outline: none;
    padding: 0;
    font-size: 1rem;
}

.dailyJournal-notes-section {
    background-color: #f9f9f9;
    border-radius: 10px;
    padding: 20px;
    margin-top: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.dailyJournal-notes-label {
    font-size: 1.5rem;
    font-weight: bold;
    color: #0056b3; /* Base color */
    margin-bottom: 10px;
    text-align: left; /* Align to the left */
    position: relative;
    display: inline-block;
    background: linear-gradient(90deg, #0056b3, #4C89CA, #0056b3);
    background-size: 200%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text; /* Standard property for compatibility */
    animation: shiny 5s linear infinite;
}

@keyframes shiny {
    0% {
        background-position: -200%;
    }
    100% {
        background-position: 200%;
    }
}

.dailyJournal-editor-container {
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 10px;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
    min-height: 200px;
}

#editor-journal {
    min-height: 200px;
    font-size: 16px;
    line-height: 1.5;
    width: 100%;
    border: none;
    outline: none;
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 12px 15px;
}

.dailyJournal-header {
    font-size: 2rem;
    font-weight: bold;
    color: #0056b3; /* Base color */
    margin-bottom: 20px;
    text-align: center;
    position: relative;
    display: inline-block;
    background: linear-gradient(90deg, #0056b3, #4C89CA, #0056b3);
    background-size: 200%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text; /* Standard property for compatibility */
    animation: shiny 5s linear infinite;
}

.dailyJournal-story-points-input {
    width: 32px !important;
    height: 32px !important;
    padding: 0 !important;
    text-align: center !important;
    font-size: 0.85rem !important;
    font-weight: 600 !important;
    border: 1px solid #dfe1e6 !important;
    border-radius: 3px !important;
    background-color: white !important;
    color: #172B4D !important;
    transition: all 0.2s ease !important;
    flex-shrink: 0 !important;
    margin-left: 8px !important;
    box-sizing: border-box !important;
    appearance: textfield !important;
    min-width: 32px !important;
    max-width: 32px !important;
}

.dailyJournal-story-points-input:focus {
    outline: none !important;
    border-color: #0052CC !important;
    box-shadow: 0 0 0 1px #0052CC !important;
    background-color: white !important;
}

.dailyJournal-story-points-input::-webkit-inner-spin-button,
.dailyJournal-story-points-input::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
}

.dailyJournal-task-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 8px;
}

.dailyJournal-task-content span {
    flex: 1; /* Allows the text to take up remaining space */
    min-width: 0; /* Prevents text from forcing container to grow */
    overflow: hidden;
    text-overflow: ellipsis;
}

.dailyJournal-task-text {
    flex: 1;
    margin-right: 8px;
    color: #172B4D;
    line-height: 1.4;
    word-break: break-word;
}

.dailyJournal-story-points-badge {
    width: 32px;
    height: 32px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.85rem;
    font-weight: 600;
    cursor: pointer;
    margin-left: 8px;
    flex-shrink: 0;
    transition: all 0.2s ease;
    background-color: white;
    border: 1px solid #dfe1e6;
    color: #000;
}

.dailyJournal-story-points-header {
    width: 32px;
    height: 32px;
    border-radius: 3px;
    font-size: 0.85rem;
    font-weight: 600;
    cursor: pointer;
    margin-left: 8px;
    flex-shrink: 0;
    transition: all 0.2s ease;
    background-color: white;
    border: 1px solid #dfe1e6;
    color: #000;
}

.dailyJournal-story-points-badge:hover {
    background-color: #ebecf0;
    border-color: #c1c7d0;
}

/* Story point colors based on size */
.sp-0 {
    background-color: #f4f5f7;
    color: #42526e;
}

.sp-1, .sp-2 {
    background-color: #E9F2FF;
    color: #0052CC;
    border: 1px solid #0052CC;
}

.sp-3, .sp-5 {
    background-color: #DEEBFF;
    color: #0747A6;
    border: 1px solid #0747A6;
}

.sp-8 {
    background-color: #EAE6FF;
    color: #403294;
    border: 1px solid #403294;
}

.sp-13 {
    background-color: #FFF0F4;
    color: #DE350B;
    border: 1px solid #DE350B;
}

/* Criticality badges */
.dailyJournal-criticality-badge {
    width: 28px;
    height: 28px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    font-weight: 600;
    cursor: pointer;
    margin-left: 8px;
    flex-shrink: 0;
    transition: all 0.2s ease;
}

.criticality-low {
    background-color: #E3FCEF;
    color: #006644;
    border: 1px solid #006644;
}

.criticality-medium {
    background-color: #FFFAE6;
    color: #FF8B00;
    border: 1px solid #FF8B00;
}

.criticality-high {
    background-color: #FFEBE6;
    color: #DE350B;
    border: 1px solid #DE350B;
}

.dailyJournal-criticality-select {
    width: 100%; /* Changed from 100px to 100% to match the due date field */
    height: 32px;
    padding: 0;
    text-align: center;
    font-size: 0.85rem;
    font-weight: 600;
    border: 1px solid #0052CC;
    border-radius: 3px;
    background-color: white;
    margin-left: 8px;
}

.dailyJournal-badges {
    display: flex;
    align-items: center;
    gap: 6px;
    margin-left: auto;
    padding-left: 8px;
    border-left: 1px solid #e9ecef;
}

.dailyJournal-sort-controls {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
    margin-bottom: 15px;
    padding: 0 10px;
}

.dailyJournal-sort-select {
    padding: 8px 16px;
    border-radius: 5px;
    border: 2px solid #0056b3;
    background-color: white;
    color: #0056b3;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
    min-width: 200px;
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%230056b3' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 16px;
    padding-right: 36px;
    margin-right: 0; /* Remove right margin if it exists */
}

.dailyJournal-sort-select:hover {
    background-color: #f0f7ff;
    border-color: #004494;
}

.dailyJournal-sort-select:focus {
    outline: none;
    border-color: #004494;
    box-shadow: 0 0 0 3px rgba(0,82,204,0.2);
    background-color: white;
}

.dailyJournal-sort-select option {
    padding: 8px;
    font-weight: 500;
}

.dailyJournal-sort-select:focus {
    outline: none;
    border-color: #0052CC;
    box-shadow: 0 0 0 2px rgba(0,82,204,0.2);
}

.dailyJournal-duplicateTasks {
    background-color: white;
    color: #0056b3;
    border: 2px solid #0056b3;
    padding: 8px 16px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
    min-width: 150px;
    height: 41px; /* Match the height of the sort select */
    margin: 0;
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%230056b3' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 16px;
    padding-right: 36px;
}

.dailyJournal-duplicateTasks:hover {
    background-color: #f0f7ff;
    border-color: #004494;
    color: #004494;
}

.dailyJournal-duplicateTasks:focus {
    outline: none;
    border-color: #004494;
    box-shadow: 0 0 0 3px rgba(0,82,204,0.2);
    background-color: white;
}

.dailyJournal-column-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    width: 100%;
}

.dailyJournal-story-points-box {
    background-color: white;
    color: #000;
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: bold;
    font-size: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-left: 10px;
}

.dailyJournal-story-points-display {
    background-color: white;
    color: #000;
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: bold;
    font-size: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dailyJournal-story-points-badge-header {
    width: 32px;
    height: 32px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.85rem;
    font-weight: 600;
    cursor: pointer;
    margin-left: 8px;
    flex-shrink: 0;
    transition: all 0.2s ease;
    background-color: white;
    border: 1px solid #dfe1e6;
    color: #000;
}

.dailyJournal-story-points-badge-header:hover {
    background-color: #ebecf0;
    border-color: #c1c7d0;
}

.dailyJournal-modal-button {
    background-color: #0056b3;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-left: 10px;
}

.dailyJournal-modal-button:hover {
    background-color: #004494;
}

.dailyJournal-modal {
    display: flex !important;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    padding: 0 !important;
}

.dailyJournal-modal-dialog {
    position: relative;
    width: 90% !important; /* Increased from 80% to 90% */
    max-width: 1200px !important; /* Increased from 900px to 1200px */
    min-width: 800px !important; /* Increased from 600px to 800px */
    margin: 1.5rem auto;
    pointer-events: none;
    max-width: 95% !important;
    width: 1200px !important;
    margin: 1.5rem auto !important;
}

.dailyJournal-modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #f8f9fa;
    border-radius: 16px;
    box-shadow: 0 16px 32px rgba(0, 0, 0, 0.15);
    pointer-events: auto;
    max-height: 85vh;
    overflow-y: auto;
    animation: fadeInUp 0.4s ease forwards;
    opacity: 0;
    transform: translateY(20px);
    width: 100% !important;
    max-width: 1200px !important;
    margin: 0 auto !important;
}

@keyframes fadeInUp {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.dailyJournal-modal-header {
    padding: 1.75rem 2rem;
    border-bottom: none;
    background: white;
    border-radius: 16px 16px 0 0;
    width: 100% !important;
}

.dailyJournal-modal-title {
    margin: 0;
    font-size: 1.25rem;
    font-weight: 600;
    color: #0056b3;
}

.dailyJournal-modal-title.full-width {
    width: 100%;
    box-sizing: border-box;
}

.dailyJournal-modal-body {
    padding: 2rem;
    overflow-y: auto;
    max-height: 70vh; /* Limit the height to prevent excessive scrolling */
    width: 100% !important;
    padding: 2rem !important;
}

.dailyJournal-modal-footer {
    padding: 1.5rem 2rem;
    border-top: none;
    background: white;
    border-radius: 0 0 16px 16px;
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
}

.dailyJournal-modal-form-control {
    width: 100%;
    padding: 12px 16px;
    border: 2px solid #e9ecef;
    border-radius: 8px;
    font-size: 1rem;
    color: #495057;
    transition: all 0.2s;
    margin-bottom: 1rem;
}

.dailyJournal-modal-form-control:focus {
    border-color: #0056b3;
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba(0, 86, 179, 0.25);
}

.dailyJournal-modal-textarea {
    min-height: 300px;
    padding: 16px;
    border: 2px solid #e9ecef;
    border-radius: 12px;
    resize: vertical;
    font-size: 1rem;
    line-height: 1.6;
    width: 100%;
    height: calc(100% - 40px);
}

.dailyJournal-modal-textarea:focus {
    border-color: #0056b3;
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 86, 179, 0.15);
}

.dailyJournal-modal-overlay {
    background-color: rgba(0, 0, 0, 0.5) !important;
    backdrop-filter: blur(2px);
}

.dailyJournal-modal-title {
    margin-top: 0;
    color: #0056b3;
}

.dailyJournal-modal-body {
    padding: 15px 0;
}

.dailyJournal-modal-form-control {
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100%;
}

.dailyJournal-modal-label {
    font-weight: 600;
    color: #172B4D;
    margin-bottom: 0.75rem;
    margin-top: 0.5rem;
    display: block;
}

.dailyJournal-modal-select {
    padding-right: 24px;
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23172B4D' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 8px center;
    background-size: 12px;
}

.dailyJournal-modal-textarea {
    width: 100%;
    height: 80px;
    margin-top: 5px;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ddd;
    resize: vertical;
}

.dailyJournal-modal-date {
    width: 100%;
    padding: 5px;
    margin-top: 5px;
    border-radius: 5px;
    border: 1px solid #ddd;
}

.dailyJournal-modal-button {
    margin-top: 15px;
    padding: 10px 24px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.dailyJournal-modal-save {
    background-color: #0056b3;
    color: white;
    border: none;
    box-shadow: 0 2px 4px rgba(0, 86, 179, 0.2);
    margin-right: 10px;
}

.dailyJournal-modal-save:hover {
    background: #004494;
    transform: translateY(-1px);
    box-shadow: 0 4px 8px rgba(0, 86, 179, 0.3);
}

.dailyJournal-modal-close {
    background: #f8f9fa;
    border: 2px solid #e9ecef;
    color: #495057;
}

.dailyJournal-modal-close:hover {
    background: #e9ecef;
    color: #212529;
}

.dailyJournal-details-button {
    background: none;
    border: none;
    color: #6c757d; /* Less conspicuous color */
    font-size: 1rem;
    cursor: pointer;
    padding: 4px;
    transition: color 0.3s ease, transform 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0; /* Remove margin */
}
.dailyJournal-details-button:hover {
    background-color: #f8f9fa;
    transform: scale(1.1);
}

.dailyJournal-details-button:focus {
    outline: none;
    color: #0056b3;
    transform: scale(1.2);
}

.dailyJournal-modal-body .dailyJournal-form-control {
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 50%;
}

.dailyJournal-modal-body .dailyJournal-form-label {
    font-weight: 600;
    color: #172B4D;
    margin-bottom: 5px;
    display: block;
}

.dailyJournal-modal-body .dailyJournal-form-select {
    padding-right: 24px;
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23172B4D' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 8px center;
    background-size: 12px;
}

.dailyJournal-modal-split {
    display: flex;
    gap: 2.5rem;
    min-height: 450px;
    padding: 2.5rem;
    width: 100% !important;
    display: flex !important;
    flex-wrap: nowrap !important;
    background: white;
    border-radius: 12px;
    margin: 0 2rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.dailyJournal-modal-left {
    flex: 0 0 30%;
    padding-right: 2rem;
    border-right: 1px solid #e9ecef;
    padding: 2rem;
}

.dailyJournal-modal-right {
    flex: 0 0 70%;
    padding-left: 1rem;
    padding: 2rem;
    background: white;
    border-radius: 0 12px 12px 0;
}

.dailyJournal-modal-notes {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.dailyJournal-modal-form-control {
    width: 100%;
    margin-bottom: 1.5rem;
}

.dailyJournal-modal-title-styled {
    background-color: white;
    padding: 12px 16px;
    border: 2px solid #e9ecef;
    border-radius: 12px;
    resize: vertical; 
    max-height: 120px;
    line-height: 1.5;
    font-size: 1.3rem; /* Increase font size for better readability */
    width: 100%; /* Ensure it takes full width */
    transition: all 0.2s ease;
    color: #172B4D;
    font-weight: 600;
    min-height: 56px;
}

.dailyJournal-modal-title-styled:focus {
    border-color: #0056b3;
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 86, 179, 0.15);
}

.dailyJournal-analytics-button {
    background-color: #0056b3;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    padding: 8px 16px;
    margin: 10px;
    transition: background-color 0.3s ease;
}

.dailyJournal-analytics-button:hover {
    background-color: #004494;
}

/* Optional: Style adjustments for the SlidingPane content */
.dailyJournal-sliding-pane {
    /* Ensure the pane has padding for the chart */
    width: 80% !important;
}

.react-sliding-pane__content {
    /* Optional: Customize the content area */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dailyJournal-line-chart {
    width: 100%;
    max-width: 600px;
}

/* Style for the Reset Zoom button */
.dailyJournal-reset-zoom-button {
    background-color: #0056b3;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    height: 40px;
    padding: 0 20px;
    margin: 10px;
}

.dailyJournal-reset-zoom-button:hover {
    background-color: #004494;
}

.dailyJournal-reset-zoom-button:active,
.dailyJournal-reset-zoom-button:focus {
    background-color: #003366;
    outline: none;
}

.dailyJournal-sliding-pane canvas {
    cursor: grab;
}

.dailyJournal-sliding-pane canvas:active {
    cursor: grabbing;
}

.dailyJournal-bar-chart {
    width: 100%;
    max-width: 600px;
    margin-top: 40px; /* Space between line and bar charts */
}

.dailyJournal-analytics-pane {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 20px;
    margin-top: 40px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dailyJournal-analytics-title {
    text-align: center;
    font-size: 2rem;
    color: #0056b3;
    margin-bottom: 30px;
    position: relative;
    display: inline-block;
    background: linear-gradient(90deg, #0056b3, #4C89CA, #0056b3);
    background-size: 200%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shiny 5s linear infinite;
}

@keyframes shiny {
    0% {
        background-position: -200%;
    }
    100% {
        background-position: 200%;
    }
}

.dailyJournal-charts-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
}

.dailyJournal-line-chart, .dailyJournal-bar-chart {
    width: 100%;
    max-width: 800px;
}

.dailyJournal-stacked-bar-chart {
    /* Additional styling if needed */
}

/* Responsive Design */
@media (min-width: 768px) {
    .dailyJournal-charts-container {
        flex-direction: row;
        justify-content: space-between;
    }

    .dailyJournal-line-chart, .dailyJournal-bar-chart {
        width: 48%;
    }
}

/* Enhance chart titles */
.dailyJournal-analytics-title::after {
    content: '';
    display: block;
    width: 60px;
    height: 4px;
    background-color: #0056b3;
    margin: 10px auto 0;
    border-radius: 2px;
}

/* Optional: Add hover effects to charts */
.dailyJournal-line-chart:hover, .dailyJournal-bar-chart:hover {
    transform: scale(1.02);
    transition: transform 0.3s ease;
}

/* New Project Badge Styles */
.dailyJournal-project-badge {
    background-color: #EBF5FB;
    color: #0056b3;
    border: 1px solid #0056b3;
    border-radius: 3px;
    padding: 2px 6px;
    font-size: 0.7rem;
    font-weight: 600;
    margin-top: 4px;
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.dailyJournal-task-info {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 0;
    overflow: hidden;
    gap: 4px;
}

/* Style for project selector in modal */
.dailyJournal-project-select {
    width: 100%;
    padding: 10px 12px;
    font-size: 0.95rem;
    border: 1px solid #ced4da;
    color: #495057;
    border-radius: 4px;
    background-color: white;
    transition: all 0.2s;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23495057' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 12px center;
    background-size: 14px;
    padding-right: 36px;
    appearance: none;
}

.dailyJournal-project-select:focus {
    border-color: #0056b3;
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba(0, 86, 179, 0.25);
}

.dailyJournal-input-container {
    position: relative;
    width: 50%;
    margin-right: 10px;
    z-index: 2;
}

.dailyJournal-project-suggestions {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 0 0 5px 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 8px;
    max-height: 200px;
    overflow-y: auto;
}

.dailyJournal-task-help {
    display: none;
}

.dailyJournal-task-help code {
    background-color: #f8f9fa;
    padding: 2px 4px;
    border-radius: 3px;
    color: #0056b3;
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.dailyJournal-modal-form-control {
    width: 100%;
    padding: 10px 12px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 0.95rem;
    color: #495057;
    transition: all 0.2s;
    margin-bottom: 1.25rem;
}

.dailyJournal-modal-form-control:focus {
    border-color: #0056b3;
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba(0, 86, 179, 0.25);
}

.dailyJournal-modal-label {
    font-weight: 600;
    color: #172B4D;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    display: block;
    font-size: 0.95rem;
}

.dailyJournal-modal-notes {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.dailyJournal-modal-notes .dailyJournal-modal-label {
    margin-bottom: 0.75rem;
}

.dailyJournal-modal-save {
    background-color: #28a745;
    color: white;
    padding: 10px 16px;
    font-weight: 600;
    border-radius: 4px;
    transition: all 0.2s;
}

.dailyJournal-modal-close {
    background-color: #6c757d; /* Changed from red to gray for less alarm */
    color: white;
    padding: 10px 16px;
    font-weight: 600;
    border-radius: 4px;
    transition: all 0.2s;
}

/* Timer Controls */
.dailyJournal-timer-button {
    background: none;
    border: none;
    color: #6c757d;
    font-size: 1rem;
    cursor: pointer;
    padding: 4px;
    transition: color 0.3s ease, transform 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    border-radius: 4px;
    font-size: 0.8rem;
}

.dailyJournal-timer-button.active {
    color: #28a745;
    animation: pulse 1.5s infinite;
}

.dailyJournal-time-display {
    font-size: 0.75rem;
    color: #6c757d;
    font-weight: 500;
    margin-left: 8px;
    min-width: 50px;
}

@keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.2); }
    100% { transform: scale(1); }
}

/* Focus Task Styles */
.dailyJournal-focus-task {
    border-left: 4px solid #ffd700 !important;
    background-color: #fffdf0 !important;
}

.dailyJournal-focus-button {
    background: none;
    border: none;
    color: #6c757d;
    font-size: 1rem;
    cursor: pointer;
    padding: 4px;
    transition: color 0.3s ease, transform 0.2s ease;
}

.dailyJournal-focus-button.active {
    color: #ffd700;
}

.dailyJournal-focus-tasks {
    background-color: #fffdf0;
    border: 1px solid #ffd700;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 20px;
}

.dailyJournal-focus-title {
    color: #856404;
    margin-bottom: 12px;
    font-size: 1.2rem;
}

.dailyJournal-focus-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.dailyJournal-focus-item {
    background-color: white;
    border: 1px solid #ffeeba;
    border-radius: 4px;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dailyJournal-modal-time-display {
    font-size: 1.2rem;
    font-weight: 500;
    color: #0056b3;
    background-color: #f8f9fa;
    padding: 12px 16px;
    border-radius: 8px;
    border: 2px solid #e9ecef;
    text-align: center;
}

/* Task Details Sliding Pane - Redesigned */
.dailyJournal-details-pane {
    background-color: #f8f9fa !important;
    box-shadow: -5px 0 25px rgba(0, 0, 0, 0.15) !important;
}

.dailyJournal-details-pane .slide-pane__header {
    background: linear-gradient(135deg, #0056b3, #4C89CA) !important;
    border-bottom: none !important;
    color: white !important;
    padding: 20px !important;
}

.dailyJournal-details-pane .slide-pane__close {
    color: white !important;
    opacity: 0.8 !important;
}

.dailyJournal-details-pane .slide-pane__close:hover {
    opacity: 1 !important;
}

.dailyJournal-details-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.dailyJournal-details-header span {
    font-weight: 500;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

.dailyJournal-details-save-icon {
    background: rgba(255, 255, 255, 0.1);
    border: none;
    color: rgba(255, 255, 255, 0.8);
    font-size: 1.1rem;
    padding: 8px;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
}

.dailyJournal-details-save-icon:hover {
    background: rgba(255, 255, 255, 0.2);
    color: white;
}

/* Add visual feedback for saving */
.dailyJournal-details-save-icon.saved {
    background: rgba(40, 167, 69, 0.4) !important; /* Green background for success */
    color: white !important;
    animation: pulse-success 1s;
}

@keyframes pulse-success {
    0% { transform: scale(1); }
    50% { transform: scale(1.2); }
    100% { transform: scale(1); }
}

/* Add a tooltip for save button with instructions */
.dailyJournal-details-header {
    position: relative;
}

.dailyJournal-auto-save-notice {
    position: absolute;
    top: 100%;
    right: 12px;
    background: rgba(255, 255, 255, 0.9);
    color: #172B4D;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 0.8rem;
    transform: translateY(8px);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s, transform 0.2s;
    z-index: 10;
    white-space: nowrap;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dailyJournal-details-save-icon:hover + .dailyJournal-auto-save-notice {
    opacity: 1;
    transform: translateY(4px);
}

.dailyJournal-details-content {
    padding: 0;
    display: flex;
    flex-direction: column;
    background-color: white;
    min-height: 100%;
}

.dailyJournal-details-title-section {
    padding: 20px;
    border-bottom: 1px solid #e9ecef;
}

.dailyJournal-details-title {
    width: 100%;
    border: none;
    font-size: 1.25rem;
    font-weight: 600;
    color: #172B4D;
    resize: none;
    padding: 0;
    background: none;
    min-height: 40px;
    border-bottom: 2px solid transparent;
    transition: border-color 0.2s ease;
}

.dailyJournal-details-title:focus {
    outline: none;
    border-bottom: 2px solid #0056b3;
}

.dailyJournal-details-meta {
    padding: 15px 20px;
    display: flex;
    gap: 15px;
    background-color: #f8f9fa;
    border-bottom: 1px solid #e9ecef;
}

.dailyJournal-details-time {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #0056b3;
    font-size: 1rem;
    font-weight: 500;
}

.dailyJournal-details-icon {
    color: #0056b3;
}

.dailyJournal-details-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding: 20px;
    background-color: white;
}

.dailyJournal-details-item {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.dailyJournal-details-item label {
    font-weight: 600;
    color: #172B4D;
    font-size: 0.9rem;
    opacity: 0.8;
}

.dailyJournal-details-item input,
.dailyJournal-details-item select {
    padding: 10px 12px;
    border: 2px solid #e9ecef;
    border-radius: 8px;
    font-size: 0.95rem;
    transition: all 0.2s;
    background-color: white;
}

.dailyJournal-details-item input:focus,
.dailyJournal-details-item select:focus {
    border-color: #0056b3;
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 86, 179, 0.1);
}

.dailyJournal-details-notes {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: white;
    border-top: 1px solid #e9ecef;
}

.dailyJournal-details-notes label {
    font-weight: 600;
    color: #172B4D;
    font-size: 0.95rem;
    margin-bottom: 10px;
    opacity: 0.8;
}

.dailyJournal-details-notes textarea {
    flex: 1;
    min-height: 200px;
    padding: 15px;
    border: 2px solid #e9ecef;
    border-radius: 8px;
    resize: none;
    font-size: 0.95rem;
    line-height: 1.5;
}

.dailyJournal-details-notes textarea:focus {
    border-color: #0056b3;
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 86, 179, 0.1);
}

/* Criticality select styling */
.criticality-l, .criticality-low {
    color: #00875A !important;
    background-color: rgba(0, 135, 90, 0.1) !important;
}

.criticality-m, .criticality-medium {
    color: #FF991F !important;
    background-color: rgba(255, 153, 31, 0.1) !important;
}

.criticality-h, .criticality-high {
    color: #DE350B !important;
    background-color: rgba(222, 53, 11, 0.1) !important;
}

/* Overriding the styles that are no longer needed */
.dailyJournal-details-section,
.dailyJournal-details-actions,
.dailyJournal-details-save {
    display: none;
}

/* Add auto-save indicator */
.dailyJournal-auto-save-indicator {
    font-size: 0.75rem;
    color: rgba(255, 255, 255, 0.7);
    background: rgba(255, 255, 255, 0.1);
    padding: 4px 8px;
    border-radius: 4px;
    margin-left: 10px;
    display: inline-block;
}

/* Remove the old save button styles */
.dailyJournal-details-save-icon,
.dailyJournal-details-save-icon.saved,
.dailyJournal-auto-save-notice {
    display: none;
}

.dailyJournal-notes-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.save-prompt {
    color: #0056b3;
    font-size: 0.9rem;
    background: #e7f0ff;
    padding: 6px 12px;
    border-radius: 4px;
    animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
    from { opacity: 0; transform: translateY(-10px); }
    to { opacity: 1; transform: translateY(0); }
}

/* Remove old toggle-related styles */
.quill-container,
.note-content-view {
    display: none !important; /* Use !important to ensure this rule takes priority */
}