.timeline-container {
    width: 100%;
    height: calc(100vh - 200px);
    position: relative;
    background: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    overflow: hidden; /* Keep this to prevent double scrollbars */
    padding-bottom: 12px; /* Add padding to show full scrollbar */
}

.timeline-visual {
    width: 100%;
    height: calc(100% - 12px); /* Adjust height to accommodate scrollbar */
    overflow: scroll; /* Change from auto to scroll to always show scrollbars */
    position: relative;
    white-space: nowrap;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
}

.timeline-content {
    min-height: 100%;
    position: relative;
    width: max-content; /* Change from 100% to max-content */
    height: 100%;
    min-height: 1000px; /* Base minimum height */
    transition: min-height 0.3s ease; /* Smooth height transitions */
}

.timeline-events-container {
    position: relative;
    min-height: 1000px; /* Provide space for many events */
}

/* Update scrollbar styling to make them more visible */
.timeline-visual::-webkit-scrollbar {
    width: 12px;  /* Increase width */
    height: 12px; /* Increase height */
    display: block; /* Ensure scrollbar is always displayed */
    background: #f1f1f1; /* Add background color to scrollbar area */
}

.timeline-visual::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 6px;
    margin: 2px;
    border: 1px solid #ddd;
}

.timeline-visual::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 6px;
    border: 2px solid #f1f1f1;
    min-height: 40px; /* Minimum thumb size */
    min-width: 40px; /* Add minimum width for horizontal scrollbar */
}

.timeline-visual::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.timeline-visual::-webkit-scrollbar-corner {
    background: #f1f1f1;
}

.current-day-marker {
    position: absolute;
    top: 0;
    width: 2px;
    background: rgba(0, 0, 0, 0.75);
    pointer-events: none;
    z-index: 9999;
    transition: height 0.3s ease;
}

.timeline-years {
    display: inline-flex; /* Change from flex to inline-flex */
    flex-direction: row;
    min-width: max-content; /* Change from 100% to max-content */
    height: 100%;
}

.month-column {
    display: inline-flex;
    flex-direction: column;
    flex-shrink: 0;
}

.month-header {
    background: #0056b3;
    color: white;
    padding: 8px;
    text-align: center;
    font-weight: 500;
}

.days-grid {
    display: flex;
    flex-direction: row;
}

.day-column {
    width: 40px;
    border-right: 1px solid #eee;
    text-align: center;
    height: 100%;
}

.day-number {
    font-size: 14px;
    padding: 2px;
}

.day-abbr {
    font-size: 10px;
    color: #666;
    padding: 2px;
}

.day-content {
    height: calc(100vh - 300px);
    border-top: 1px solid #ddd;
    background: linear-gradient(to right, #eee 1px, transparent 1px);
    transition: height 0.3s ease;
}

.timeline-event {
    position: absolute;
    color: white;
    border-radius: 4px;
    cursor: move;
    z-index: 1;
    min-width: 40px; /* Change from 80px to 40px to allow single day events */
    box-sizing: border-box;
    overflow: visible !important;
    user-select: none;
    transform: translate3d(0, 0, 0); /* Enable hardware acceleration */
    will-change: transform; /* Optimize for animations */
    transition: box-shadow 0.2s ease;
}

.timeline-event:active {
    cursor: grabbing;
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.event-title {
    display: block;
    width: 100%;
    line-height: 1.2;
    font-size: 0.9rem;
    font-weight: 500;
    position: relative;
    z-index: 2;
}

.event-title-input {
    width: 100%;
    border: none;
    background: transparent;
    color: white;
    font-size: 0.9rem;
    font-weight: 500;
    outline: none;
}

.event-title-input {
    width: 100%;
    border: none;
    background: transparent;
    color: white;
    text-align: center;
    width: 100%;
    margin: 0;
    padding: 2px;
    font-size: inherit;
}

.event-resizer {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 5px;
    cursor: ew-resize;
    z-index: 10;
}

.event-resizer.left {
    left: 0;
}

.event-resizer.right {
    right: 0;
}
