.bugs-container {
    padding: 1.5rem;
    background-color: #f8f9fa;
    border-radius: 0.5rem;
}

.bugs-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 2rem;
}

/* Analytics Dashboard */
.bugs-analytics {
    background: white;
    border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 0 2px 8px rgba(0,0,0,0.05);
    margin-bottom: 2rem;
}

.analytics-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 1.5rem;
}

.analytic-card {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    padding: 1.25rem;
    background: #f8f9fa;
    border-radius: 8px;
    transition: transform 0.2s ease;
}

.analytic-card:hover {
    transform: translateY(-2px);
}

.analytic-icon {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    border-radius: 8px;
    font-size: 1.25rem;
}

.analytic-content {
    flex: 1;
}

.analytic-content h4 {
    font-size: 0.875rem;
    color: #6c757d;
    margin: 0 0 0.5rem 0;
}

.analytic-value {
    font-size: 1.5rem;
    font-weight: 600;
    color: #2c3e50;
}

/* Filters */
.bugs-filters {
    background: white;
    border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 0 2px 8px rgba(0,0,0,0.05);
}

.view-controls {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.filter-badges {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-top: 1rem;
}

.filter-badge {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.25rem 0.75rem;
    background: #e7f1ff;
    color: #0d6efd;
    border-radius: 1rem;
    font-size: 0.875rem;
}

.filter-badge button {
    background: none;
    border: none;
    color: #0d6efd;
    font-size: 1.25rem;
    line-height: 1;
    padding: 0;
    cursor: pointer;
    opacity: 0.7;
    transition: opacity 0.2s;
}

.filter-badge button:hover {
    opacity: 1;
}

.clear-filters-btn {
    background: none;
    border: none;
    color: #6c757d;
    font-size: 0.875rem;
    padding: 0.25rem 0.5rem;
    cursor: pointer;
    transition: color 0.2s;
}

.clear-filters-btn:hover {
    color: #dc3545;
}

/* Severity and Status Badges */
.severity-badge {
    padding: 0.25em 0.75em;
    border-radius: 1rem;
    color: white;
    font-size: 0.875rem;
    font-weight: 500;
    white-space: nowrap;
}

.card-severity {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    pointer-events: none;
}

.status-badge {
    padding: 0.25em 0.75em;
    border-radius: 1rem;
    font-size: 0.875rem;
    font-weight: 500;
    white-space: nowrap;
}

.status-open {
    background-color: #ffc107;
    color: #000;
}

.status-in-progress {
    background-color: #0d6efd;
    color: white;
}

.status-fixed {
    background-color: #198754;
    color: white;
}

.status-verified {
    background-color: #0dcaf0;
    color: white;
}

.status-closed {
    background-color: #6c757d;
    color: white;
}

/* List View Styles */
.bugs-list {
    background: white;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0,0,0,0.05);
    margin-top: 1.5rem;
}

.bugs-list table {
    margin-bottom: 0;
}

.sortable-header {
    cursor: pointer;
    user-select: none;
}

.sortable-header:hover {
    background-color: #f5f5f5;
}

.bug-title-cell {
    max-width: 350px;
}

.bug-title {
    font-weight: 500;
    margin-bottom: 0.25rem;
}

.bug-description {
    font-size: 0.85rem;
    color: #6c757d;
    white-space: normal;
    word-break: break-word;
}

.bug-row {
    transition: background-color 0.15s;
}

.bug-row:hover {
    background-color: rgba(13, 110, 253, 0.05);
}

.bug-id {
    font-family: 'Roboto Mono', monospace;
    font-size: 0.85rem;
    color: #6c757d;
}

/* Card View Styles */
.bugs-cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.5rem;
    margin-top: 1.5rem;
}

.bug-card {
    background: white;
    border-radius: 12px;
    padding: 1.25rem;
    box-shadow: 0 2px 8px rgba(0,0,0,0.05);
    transition: transform 0.2s, box-shadow 0.2s;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    height: 100%;
}

.bug-card:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 16px rgba(0,0,0,0.1);
}

.bug-card-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 1rem;
    min-height: 2rem;
    position: relative;
    padding-right: 100px;
}

.bug-card-title {
    font-size: 1.1rem;
    font-weight: 600;
    color: #2c3e50;
    margin-bottom: 0.75rem;
}

.bug-card-description {
    font-size: 0.9rem;
    color: #6c757d;
    margin-bottom: 1.25rem;
    flex-grow: 1;
}

.bug-card-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid rgba(0,0,0,0.05);
    padding-top: 0.75rem;
    margin-top: auto;
}

.bug-meta {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.bug-reporter {
    font-size: 0.8rem;
    color: #6c757d;
}

.bug-date {
    font-size: 0.8rem;
    color: #6c757d;
}

/* Empty State */
.bugs-empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 3rem 1rem;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
    margin-top: 1.5rem;
}

.empty-icon {
    margin-bottom: 1rem;
    color: #6c757d;
}

.bugs-empty-state h4 {
    margin-bottom: 0.5rem;
    color: #343a40;
}

.bugs-empty-state p {
    color: #6c757d;
    margin-bottom: 1.5rem;
}

/* Loading and Error States */
.bugs-loading, .bugs-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem 0;
    color: #6c757d;
}

.bugs-loading .spinner-border {
    margin-bottom: 1rem;
}

/* Responsive Adjustments */
@media (max-width: 992px) {
    .bugs-cards {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
    
    .analytics-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .bugs-cards {
        grid-template-columns: 1fr;
    }
    
    .analytics-grid {
        grid-template-columns: 1fr;
    }
    
    .bug-title-cell {
        max-width: 200px;
    }
}
