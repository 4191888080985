.scheduler-activities {
    width: 600px; /* Updated width for better column display */
    height: calc(100vh - 200px);
    background: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
}

.activities-header {
    padding: 15px;
    border-bottom: 1px solid #ddd;
    background: #f8f9fa;
    position: sticky;
    top: 0;
    z-index: 2;
}

.activities-search {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    transition: border-color 0.2s;
}

.activities-search:focus {
    border-color: #0056b3;
    outline: none;
    box-shadow: 0 0 0 2px rgba(0,86,179,0.1);
}

.activities-list {
    list-style: none;
    padding: 0;
    margin: 0;
    overflow-y: auto;
    flex: 1;
}

.activities-table-header {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 0.5fr;
    gap: 10px;
    padding: 10px 15px;
    background: #f8f9fa;
    border-bottom: 2px solid #dee2e6;
    font-weight: 500;
    position: sticky;
    top: 0;
    z-index: 3;
    min-height: 40px;
}

.activity-item {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 0.5fr;
    gap: 10px;
    padding: 12px 15px;
    align-items: flex-start; /* Align items to top when wrapped */
    border-bottom: 1px solid #eee;
    background: white;
    cursor: pointer;
    transition: all 0.2s ease;
    justify-content: space-between;
    min-height: 60px; /* Give more height for wrapped text */
}

.activity-item:hover {
    background: #f8f9fa;
    transform: translateX(2px);
}

.activity-item.active {
    background: #e7f1ff;
    border-left: 4px solid #0056b3;
}

.activity-content {
    flex: 1;
}

.activity-title {
    font-weight: 500;
    margin-bottom: 4px;
    color: #2c3e50;
}

.activity-date {
    font-size: 0.75rem;
    color: #666;
}

.activity-status {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-left: 10px;
    background: #ddd;
}

.activity-status.active {
    background: #28a745;
}

.activity-actions {
    opacity: 0;
    transition: opacity 0.2s;
}

.activity-item:hover .activity-actions {
    opacity: 1;
}

.activity-dates {
    margin-top: 4px;
    font-size: 0.75rem;
    color: #666;
}

.activity-date-range {
    display: inline-block;
    padding: 2px 6px;
    background: #f8f9fa;
    border-radius: 3px;
    border: 1px solid #eee;
}

.col-title {
    font-weight: 500;
    color: #2c3e50;
    overflow: visible;
    white-space: normal;
    word-wrap: break-word;
    line-height: 1.2;
    min-height: 2.4em;
    padding-right: 8px;
}

.col-date {
    font-size: 0.85rem;
    color: #666;
    display: flex;
    align-items: center;
    padding-top: 4px; /* Align with first line of wrapped title */
    cursor: pointer;
}

.col-date:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.col-duration {
    font-size: 0.85rem;
    color: #666;
    text-align: center;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center; /* Add this to center the content */
    padding-top: 4px; /* Align with first line of wrapped title */
}

/* Scrollbar styling */
.activities-list::-webkit-scrollbar {
    width: 6px;
}

.activities-list::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.activities-list::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 3px;
}

.activities-list::-webkit-scrollbar-thumb:hover {
    background: #999;
}

/* Virtual scroll container for performance */
.virtual-scroll-container {
    height: 100%;
    overflow-y: auto;
}

.date-input {
    width: 120px;
    padding: 2px 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 12px;
    background: white;
}

.btn-link:hover .fa-plus {
    transform: scale(1.2);
}

.activities-header .btn-link:hover {
    color: #004494;
}
