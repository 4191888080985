.sliding-panel-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1050;
}

.panel-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.panel-overlay.visible {
    opacity: 1;
}

.panel-overlay.hiding {
    opacity: 0;
}

.sliding-panel {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    max-width: 1000px;
    background-color: #fff;
    box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
}

.sliding-panel.open {
    transform: translateX(0);
}

.sliding-panel.closing {
    transform: translateX(100%);
}

.sliding-panel-header {
    padding: 1.5rem;
    border-bottom: 1px solid #e9ecef;
    background: linear-gradient(135deg, #0d6efd 0%, #0dcaf0 100%);
    color: white;
    position: relative;
}

.sliding-panel-close {
    position: absolute;
    top: 1.25rem;
    right: 1.25rem;
    background: none;
    border: none;
    color: white;
    font-size: 1.25rem;
    opacity: 0.8;
    transition: opacity 0.2s;
    cursor: pointer;
    padding: 0.5rem;
}

.sliding-panel-close:hover {
    opacity: 1;
}

.sliding-panel-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0 0 0.25rem 0;
}

.sliding-panel-subtitle {
    font-size: 0.9rem;
    opacity: 0.8;
}

.sliding-panel-content {
    padding: 1.5rem;
    overflow-y: auto;
    flex: 1;
    background-color: #f8f9fa;
}

.form-section {
    background: white;
    border-radius: 12px;
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.section-title {
    font-size: 1.1rem;
    font-weight: 600;
    color: #495057;
    margin-bottom: 1.25rem;
}

.sprint-summary {
    background: #f8f9fa;
    border-radius: 8px;
}

.summary-stat {
    padding: 0.75rem;
    text-align: center;
}

.stat-label {
    font-size: 0.85rem;
    color: #6c757d;
    margin-bottom: 0.25rem;
}

.stat-value {
    font-size: 1.25rem;
    font-weight: 600;
    color: #0d6efd;
}

.sprint-stories-list {
    max-height: 400px;
    overflow-y: auto;
    margin: 1rem 0;
    padding-right: 0.5rem;
}

.sprint-story-item {
    display: flex;
    align-items: flex-start;
    padding: 1rem;
    background: white;
    border: 1px solid #e9ecef;
    border-radius: 8px;
    margin-bottom: 0.75rem;
    cursor: pointer;
    transition: all 0.2s ease;
}

.sprint-story-item:hover {
    border-color: #0d6efd;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
    transform: translateY(-1px);
}

.sprint-story-item.selected {
    background-color: #e7f1ff;
    border-color: #0d6efd;
}

.story-checkbox {
    padding-right: 1rem;
    padding-top: 0.25rem;
}

.story-details {
    flex: 1;
}

.story-title-row {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin-bottom: 0.5rem;
}

.story-id {
    font-family: monospace;
    font-size: 0.8rem;
    color: #6c757d;
    background: #f8f9fa;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    border: 1px solid #e9ecef;
}

.story-title {
    font-weight: 500;
    color: #212529;
}

.story-meta {
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.story-points-badge {
    background: #e7f1ff;
    color: #0d6efd;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    font-size: 0.8rem;
    font-weight: 500;
}

.selected-count {
    padding: 0.75rem;
    background: white;
    border-radius: 6px;
    text-align: center;
}

/* Capacity Planning Styles */
.capacity-planning {
    background: #f8f9fa;
    border-radius: 8px;
    padding: 1.25rem;
    margin-top: 1rem;
}

.capacity-metrics {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 1rem;
    margin-bottom: 1.5rem;
}

.metric {
    background: white;
    padding: 1rem;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.metric label {
    color: #6c757d;
    font-size: 0.85rem;
    margin-bottom: 0.5rem;
    display: block;
}

.metric-value {
    font-size: 1.25rem;
    font-weight: 600;
    color: #0d6efd;
}

.team-allocation {
    background: white;
    border-radius: 8px;
    padding: 1.25rem;
    margin-top: 1rem;
}

.team-allocation h4 {
    font-size: 1rem;
    color: #495057;
    margin-bottom: 1rem;
}

.team-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1rem;
}

.team-member-card {
    background: #f8f9fa;
    border-radius: 6px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

.member-info {
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.member-avatar {
    width: 32px;
    height: 32px;
    background: linear-gradient(45deg, #0d6efd, #0dcaf0);
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
}

.member-name {
    font-weight: 500;
    color: #495057;
}

.member-capacity {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.75rem;
}

.capacity-input label {
    font-size: 0.75rem;
    color: #6c757d;
    margin-bottom: 0.25rem;
}

.capacity-input .form-control {
    height: 32px;
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
}

/* Risk Assessment Styles */
.risk-assessment {
    margin-top: 1.5rem;
    padding: 1rem;
    background: #fff3cd;
    border-left: 4px solid #ffc107;
    border-radius: 4px;
}

.risk-header {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #997404;
    margin-bottom: 0.75rem;
}

.risk-list {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 0.875rem;
}

.risk-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #664d03;
    margin-bottom: 0.5rem;
}

/* Points Distribution Chart */
.points-distribution {
    margin-top: 1.5rem;
}

.distribution-bar {
    height: 24px;
    background: #e9ecef;
    border-radius: 12px;
    overflow: hidden;
    display: flex;
}

.bar-segment {
    height: 100%;
    transition: width 0.3s ease;
}

.bar-segment.high {
    background: linear-gradient(45deg, #dc3545, #fd7e14);
}

.bar-segment.medium {
    background: linear-gradient(45deg, #ffc107, #fd7e14);
}

.bar-segment.low {
    background: linear-gradient(45deg, #0dcaf0, #0d6efd);
}
