.container-fluid {
    display: flex;
    flex-wrap: nowrap;
    padding: 0;
    width: 100%;
}

.card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
}

.card:hover {
    transform: scale(1.05);
}

.card-body {
    padding: 20px;
}

.list-group-item {
    margin-bottom: 10px;
}

.btn-danger {
    background-color: #dc3545;
    border-color: #dc3545;
}

.btn-danger:hover {
    background-color: #c82333;
    border-color: #bd2130;
}

.sidebar {
    display: none; /* Hide the sidebar */
}

.main-content {
    width: 100%; /* Ensure main content takes up 100% of the width */
}

@media (max-width: 767.98px) {
    .sidebar {
        width: 100%;
        position: relative;
        top: 0;
    }
}

.status-badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
}

.status-green {
    background-color: #28a745; /* Green */
    color: #fff;
}

.status-yellow {
    background-color: #ffc107; /* Yellow */
    color: #000;
}

.status-red {
    background-color: #dc3545; /* Red */
    color: #fff;
}

.status-circle {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-left: 8px; /* Change margin to the left */
    vertical-align: middle; /* Ensure vertical alignment */
}

.form-select:focus {
    background-color: #e9ecef; /* Change this to your desired background color */
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.status-green:focus {
    background-color: #28a745; /* Green */
    color: #fff;
}

.status-yellow:focus {
    background-color: #ffc107; /* Yellow */
    color: #000;
}

.status-red:focus {
    background-color: #dc3545; /* Red */
    color: #fff;
}

.project-status-select:focus {
    background-color: #e9ecef; /* Change this to your desired background color */
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.project-status-select.status-green {
    background-color: #28a745; /* Green */
    color: #fff;
}

.project-status-select.status-yellow {
    background-color: #ffc107; /* Yellow */
    color: #000;
}

.project-status-select.status-red {
    background-color: #dc3545; /* Red */
    color: #fff;
}

/* Dashboard layout styles */
.project-dashboard-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas:
        "controls summary description"
        "overdue-count stakeholders raid"
        "overdue-detailed overdue-detailed overdue-detailed"
        "upcoming upcoming upcoming";
    gap: 20px;
    margin-bottom: 30px;
}

.dashboard-card.controls-card { grid-area: controls; }
.dashboard-card.summary-card { grid-area: summary; }
.dashboard-card.description-card { grid-area: description; }
.dashboard-card.raid-card { grid-area: raid; }
.dashboard-card.stakeholders-card { grid-area: stakeholders; }
.dashboard-card.overdue-count-card { grid-area: overdue-count; }
.dashboard-card.overdue-detailed-card { grid-area: overdue-detailed; }
.dashboard-card.upcoming-card { grid-area: upcoming; }

.dashboard-card {
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
    overflow: hidden;
    height: 100%;
    position: relative;
    transition: transform 0.2s, box-shadow 0.2s;
    border-top: 4px solid #0056b3; /* Default blue top border */
}

.dashboard-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
}

.dashboard-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    background-color: #f8f9fa;
    border-bottom: 1px solid #eee;
}

.dashboard-card-header h2 {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    color: #2c3e50;
    display: inline-flex;
    align-items: center;
}

.dashboard-card-header svg {
    color: #0056b3; /* Blue icons by default */
    font-size: 20px;
}

.dashboard-card-body {
    padding: 20px;
}

/* For cards that should span full width */
.full-width {
    grid-column: span 3;
}

/* Chart container */
.chart-container {
    height: 200px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
}

/* Task status summary styling */
.status-summary {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 15px;
}

.status-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    border-radius: 10px;
    min-width: 100px;
    background-color: #f8f9fa;
    border: 1px solid #e3e6f0;
    transition: transform 0.2s;
}

.status-item:hover {
    transform: translateY(-5px);
}

.status-icon {
    font-size: 2rem;
    margin-bottom: 10px;
}

.status-icon.complete {
    color: #28a745;
}

.status-icon.in-progress {
    color: #007bff;
}

.status-icon.not-started {
    color: #6c757d;
}

.status-icon.blocked {
    color: #dc3545;
}

.status-count {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 5px;
}

.status-label {
    font-size: 0.85rem;
    color: #6c757d;
}

/* Overdue task styling */
.overdue-task-row {
    background-color: rgba(220, 53, 69, 0.05);
}

.overdue-task-row:hover {
    background-color: rgba(220, 53, 69, 0.1);
}

.overdue-task-link {
    color: #dc3545;
    font-weight: 500;
}

.days-overdue {
    color: #dc3545;
    font-weight: bold;
}

/* Responsive adjustments */
@media (max-width: 992px) {
    .project-dashboard-grid {
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas:
            "controls summary"
            "description overdue-count"
            "stakeholders raid"
            "overdue-detailed overdue-detailed"
            "upcoming upcoming";
    }
    
    .full-width {
        grid-column: span 2;
    }
}

@media (max-width: 768px) {
    .project-dashboard-grid {
        grid-template-columns: 1fr;
        grid-template-areas:
            "controls"
            "summary"
            "description"
            "overdue-count"
            "raid"
            "stakeholders"
            "overdue-detailed"
            "upcoming";
    }
    
    .full-width {
        grid-column: span 1;
    }
}

/* Project Status Banner */
.project-status-banner {
    display: flex;
    align-items: center;
    padding: 15px 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    background-color: #f8f9fa;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.project-status-banner.status-green {
    background-color: rgba(40, 167, 69, 0.1);
    border-left: 4px solid #28a745;
}

.project-status-banner.status-yellow {
    background-color: rgba(255, 193, 7, 0.1);
    border-left: 4px solid #ffc107;
}

.project-status-banner.status-red {
    background-color: rgba(220, 53, 69, 0.1);
    border-left: 4px solid #dc3545;
}

.banner-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
}

.status-info {
    display: flex;
    align-items: center;
    margin-right: 30px;
    margin-bottom: 10px;
}

.status-label {
    font-weight: 600;
    margin-right: 8px;
    font-size: 14px;
    color: #6c757d;
}

.status-value {
    font-weight: 600;
    font-size: 16px;
}

.status-green .status-value {
    color: #28a745;
}

.status-yellow .status-value {
    color: #e0a800;
}

.status-red .status-value {
    color: #dc3545;
}

.status-dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-left: 8px;
}

.status-green .status-dot {
    background-color: #28a745;
}

.status-yellow .status-dot {
    background-color: #ffc107;
}

.status-red .status-dot {
    background-color: #dc3545;
}

/* Remove old progress bar styles */
.progress-bar-container {
    /* Remove this class */
}

/* Update progress bar styles */
.progress {
    background-color: #e9ecef;
    border-radius: 4px;
    margin: 0 10px;
}

.progress-bar {
    background-color: #0056b3;
    transition: width 0.3s ease;
}

.progress-percentage {
    font-weight: 600;
    font-size: 14px;
    color: #0056b3;
    min-width: 45px;
    text-align: right;
}

/* Project Controls */
.status-select-wrapper {
    position: relative;
    display: flex;
    align-items: center;
}

.status-help-tooltip {
    margin-left: 10px;
    position: relative;
    color: #6c757d;
    cursor: help;
}

.status-help-tooltip:hover .tooltip-content {
    display: block;
}

.tooltip-content {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    width: 250px;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    z-index: 1000;
}

.tooltip-content p {
    margin-bottom: 5px;
    font-size: 12px;
}

.status-updated-tooltip {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #d4edda;
    color: #155724;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 12px;
    animation: fadeInOut 3s ease-in-out;
    z-index: 100;
}

@keyframes fadeInOut {
    0% { opacity: 0; }
    15% { opacity: 1; }
    85% { opacity: 1; }
    100% { opacity: 0; }
}

.project-state-select {
    background-color: #f8f9fa;
    border: 1px solid #ced4da;
}

.project-action-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

/* Project Details Table */
.project-details-table {
    width: 100%;
}

.project-details-table th,
.project-details-table td {
    padding: 8px;
    border-bottom: 1px solid #e9ecef;
}

.project-details-table th {
    width: 40%;
    text-align: left;
    color: #6c757d;
    font-weight: 600;
}

.project-details-table tr:last-child th,
.project-details-table tr:last-child td {
    border-bottom: none;
}

.project-quick-actions {
    display: flex;
    gap: 10px;
}

/* Dashboard table styling */
.dashboard-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
}

.dashboard-table th {
    background-color: #f1f5fb;
    padding: 12px 15px;
    text-align: left;
    border-bottom: 2px solid #e3e6f0;
    color: #0056b3;
    font-weight: 600;
}

.dashboard-table td {
    padding: 12px 15px;
    border-bottom: 1px solid #e3e6f0;
    transition: background-color 0.2s;
    text-align: left;
}

.dashboard-table tr:hover td {
    background-color: #f8f9fc;
}

.dashboard-table tr:last-child td {
    border-bottom: none;
}

.dashboard-table a {
    color: #0056b3;
    text-decoration: none;
    font-weight: 500;
    transition: color 0.2s;
    display: inline-block;
    max-width: 350px;
    word-break: break-word;
    line-height: 1.4;
}

.dashboard-table a:hover {
    color: #003d7a;
    text-decoration: underline;
}

/* Overdue task specific styling */
.overdue-task-row {
    background-color: rgba(220, 53, 69, 0.05);
}

.overdue-task-row:hover {
    background-color: rgba(220, 53, 69, 0.1) !important;
}

.overdue-task-link {
    color: #dc3545;
    font-weight: 500;
    text-decoration: none;
}

.overdue-task-link:hover {
    text-decoration: underline;
    color: #c82333;
}

.days-overdue {
    color: #dc3545;
    font-weight: 600;
}

/* Make overdue detailed card full width */
.dashboard-card.overdue-detailed-card {
    grid-column: 1 / -1;
    background-color: #fff8f8;
    border-left: 4px solid #dc3545;
}

.overdue-detailed-card .dashboard-card-header {
    background-color: #fff0f0;
    border-bottom: 1px solid rgba(220, 53, 69, 0.2);
}

/* Card accent colors */
.dashboard-card.raid-card {
    border-top-color: #0056b3; /* Blue accent for RAID log */
}

.dashboard-card.overdue-count-card,
.dashboard-card.overdue-detailed-card {
    border-top-color: #dc3545; /* Red accent for both overdue cards */
    border-left: 4px solid #dc3545;
    background-color: #fff8f8;
}

.dashboard-card.overdue-count-card .dashboard-card-header,
.dashboard-card.overdue-detailed-card .dashboard-card-header {
    background-color: #fff0f0;
    border-bottom: 1px solid rgba(220, 53, 69, 0.2);
}

.dashboard-card.overdue-count-card .dashboard-card-header svg,
.dashboard-card.overdue-detailed-card .dashboard-card-header svg {
    color: #dc3545; /* Red icons for overdue cards */
}

.dashboard-card.raid-card .dashboard-card-header svg {
    color: #0056b3; /* Blue icon for RAID log */
}

/* Action link styling */
.action-link {
    color: #0056b3;
    font-weight: 500;
    text-decoration: none;
    display: inline-block;
    max-width: 350px;
    word-break: break-word;
    line-height: 1.4;
}

.action-link:hover {
    color: #003d7a;
    text-decoration: underline;
}

/* Ensure the upcoming card has proper styling */
.dashboard-card.upcoming-card .dashboard-table {
    margin-bottom: 0;
}

.usage-indicator {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 10px;
}

.usage-indicator .progress {
    height: 6px;
    width: 50px;
    margin-left: 0;
    background-color: #e9ecef;
    border-radius: 3px;
    overflow: hidden;
}

.usage-indicator .limit-badge {
    font-size: 9px;
    font-weight: bold;
    color: #dc3545;
    margin-top: 2px;
    width: 100%;
    text-align: center;
}

.project-list {
    margin-top: 10px;
    padding-left: 0;
    list-style-type: none;
}

.project-list li {
    margin-bottom: 8px;
}

.dashboard-card-body .text-center a {
    text-decoration: none;
    color: #007bff;
    font-weight: 600;
}

.dashboard-card-body .text-center a:hover {
    text-decoration: underline;
}
