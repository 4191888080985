.stakeholder-matrix {
    display: grid;
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add box shadow */
    border-radius: 10px; /* Add border radius */
    overflow: hidden;
    grid-template-columns: repeat(3, 1fr); /* Define three columns */
    position: relative;
    z-index: 1;
}

.matrix-row {
    display: contents; /* Use contents to maintain grid structure */
}

.matrix-cell {
    border: 1px solid #dee2e6;
    padding: 20px; /* Increase padding for better spacing */
    background-color: #f4f7fa; /* Light background color */
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
    display: grid;
    align-items: center; /* Center content vertically */
    justify-items: center; /* Center content horizontally */
}

.matrix-cell:hover {
    background-color: #e6eef7; /* Slightly darker background on hover */
}

.matrix-header {
    background-color: #0056b3; /* Consistent blue color */
    color: white;
    font-weight: bold;
    text-align: center;
    padding: 20px; /* Increase padding for better spacing */
    text-transform: uppercase;
    letter-spacing: normal; /* Ensure normal letter spacing */
    display: flex;
    align-items: center; /* Center content vertically */
    justify-content: center; /* Center content horizontally */
    height: 100%; /* Ensure it takes the full height of the cell */
    white-space: pre; /* Change to pre to preserve spaces */
    font-family: Arial, sans-serif; /* Use a more reliable font for export */
    word-spacing: 2px; /* Add space between words */
}

.matrix-header-power {
    display: flex;
    align-items: center; /* Center content vertically */
    justify-content: center; /* Center content horizontally */
    height: 100%; /* Ensure it takes the full height of the cell */
    white-space: nowrap; /* Ensure text does not wrap */
    padding: 0 10px; /* Add padding to ensure space between words */
}

.matrix-stakeholder {
    margin: 10px 0; /* Increase margin for better spacing */
    padding: 10px; /* Increase padding for better spacing */
    background-color: #e9ecef;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
    transition: transform 0.2s ease; /* Smooth transition for hover effect */
    width: 100%; /* Make the names span the length of the cell */
    text-align: center; /* Center the text */
}

.matrix-stakeholder:hover {
    transform: scale(1.05); /* Slightly enlarge on hover */
    background-color: #d6d8db; /* Slightly darker background on hover */
}

.export-buttons {
    display: flex;
    gap: 10px;
    align-items: center;
}

.export-dropdown-container {
    position: relative;
    z-index: 2000; /* Higher z-index to ensure dropdown appears above matrix */
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0; /* Align dropdown to the right */
    z-index: 2001; /* Even higher z-index than container */
    background-color: white;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    min-width: 160px;
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: 8px 16px;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    cursor: pointer;
}

.dropdown-item:hover {
    background-color: #f8f9fa;
    color: #16181b;
}
