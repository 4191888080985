.kanban-board {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.kanban-column {
    background-color: #e6eef7;
    border-radius: 10px;
    padding: 10px;
    width: 30%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
}

.kanban-column h3 {
    background-color: #0056b3;
    color: white;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
}

.kanban-column ul {
    list-style-type: none;
    padding: 0;
}

.kanban-column li {
    background-color: white;
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: grab;
    position: relative;
    transition: background-color 0.3s ease;
    padding-top: 22px;
}

.kanban-column li:hover {
    background-color: #f4f7fa;
}

.kanban-column li:active {
    cursor: grabbing;
}

.kanban-column .dragging-over {
    background-color: #d0e1f9;
}

.kanban-input {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.kanban-input input {
    width: 70%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-right: 10px;
}

.kanban-input button {
    padding: 10px 20px;
    background-color: #0056b3;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.kanban-input button:hover {
    background-color: #004494;
}

.kanban-input button:active,
.kanban-input button:focus {
    background-color: #003366;
    outline: none;
}

.delete-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background: none;
    border: none;
    color: red;
    font-size: 16px;
    cursor: pointer;
}

.delete-button:hover {
    color: darkred;
}

/* Responsive Sidebar */
.sidebar {
    transition: transform 0.3s ease;
}

.sidebar-toggle {
    display: none;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    position: relative;
    z-index: 1001; /* Ensure the button is above other elements */
}

@media (max-width: 768px) {
    .sidebar {
        transform: translateX(-100%);
        position: fixed;
        z-index: 1000;
        height: 100%;
    }

    .sidebar.open {
        transform: translateX(0);
    }

    .sidebar-toggle {
        display: block;
    }

    .col-md-9, .col-lg-10 {
        margin-left: 0;
    }
}