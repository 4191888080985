.terms-container {
    min-height: calc(100vh - 80px);
    background: var(--gradient-main);
    padding-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.terms-hero {
    padding: 40px 20px 60px;
    text-align: center;
    background: transparent;
}

.terms-hero h1 {
    font-size: 2.5rem;
    color: var(--white);
    margin-bottom: 1rem;
    font-weight: 700;
}

.terms-subtitle {
    font-size: 1rem;
    color: rgba(255, 255, 255, 0.8);
    font-style: italic;
}

.terms-commitment {
    max-width: 900px;
    margin: 3rem auto;
    padding: 3rem;
    background: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(12px);
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;
}

.terms-commitment::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: linear-gradient(90deg, 
        rgba(255,255,255,0.7), 
        rgba(255,255,255,0.3) 50%,
        rgba(255,255,255,0.7)
    );
    animation: shimmer 2s infinite linear;
}

.terms-commitment h2 {
    color: var(--white);
    font-size: 2.2rem;
    margin-bottom: 1.5rem;
    text-align: center;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-weight: 700;
}

.terms-commitment p {
    color: rgba(255, 255, 255, 0.95);
    font-size: 1.2rem;
    line-height: 1.8;
    text-align: center;
    margin: 0;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.terms-content {
    flex: 1;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    position: relative;
    z-index: 2;
}

.terms-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    padding: 20px;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.terms-card {
    background: rgba(255, 255, 255, 0.95);
    border-radius: 12px;
    padding: 2rem;
    height: 100%;
    box-shadow: var(--shadow);
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-left: 4px solid var(--primary);
}

.terms-card h2 {
    color: var(--primary);
    font-size: 1.25rem;
    margin-bottom: 1rem;
    font-weight: 600;
    flex: 0 0 auto;
}

.terms-card p {
    color: var(--text-dark);
    line-height: 1.6;
    margin: 0;
    font-size: 0.95rem;
    flex: 1 0 auto;
}

/* ... Add the same media queries as PrivacyPolicy.css ... */
