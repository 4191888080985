.sidebar-horizontal {
    background-color: #f8f9fa;
    border-bottom: 1px solid #ddd;
    padding: 5px 0; /* Reduce padding to bring it closer to the top navbar */
    margin-top: -10px; /* Adjust margin to reduce space */
}

.sidebar-horizontal .nav {
    display: flex;
    justify-content: space-between; /* Distribute space between items */
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.sidebar-horizontal .nav-item {
    margin: 0 5px; /* Reduce space between links */
}

.sidebar-horizontal .nav-item.settings {
    margin-left: auto; /* Push the settings item to the far right */
}

.sidebar-horizontal .nav-link {
    color: #0056b3;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: color 0.3s ease;
}

.sidebar-horizontal .nav-link.active {
    color: #004494;
    border-bottom: 2px solid #004494;
}

.sidebar-horizontal .nav-link:hover {
    color: #003366;
}

.nav-link[title] {
    position: relative;
}

.nav-link[title]::after {
    content: attr(title);
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 5px;
    border-radius: 3px;
    white-space: nowrap; /* Prevent text from wrapping */
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;
    font-size: 12px;
    margin-top: 5px; /* Add margin to create space between icon and tooltip */
    z-index: 9999; /* Increased z-index to ensure tooltip appears */
}

.nav-link[title]:hover::after {
    opacity: 1;
    white-space: nowrap; /* Prevent text from wrapping */
    max-width: none; /* Remove maximum width */
}

.nav-link.active[title="Project Dashboard"]::after {
    left: 0;
    transform: translateX(0);
}

.nav-link[title="Project Settings"]::after {
    right: 0;
    left: auto;
    transform: translateX(0);
}

@media (max-width: 768px) {
    .sidebar-horizontal .nav {
        justify-content: center; /* Center links on smaller screens */
    }

    .sidebar-horizontal .nav-item {
        margin: 5px; /* Increase space between links on smaller screens */
    }
}
