/* Reset animations and transforms completely */
.pmhub-wiki-container, 
.pmhub-wiki-container *,
.pmhub-wiki-sidebar,
.pmhub-wiki-content-card,
.pmhub-wiki-section,
.pmhub-wiki-list-item {
    transform: none !important;
    animation: none !important;
    transition: none !important;
    box-shadow: none !important;
}

/* Basic container styles */
.pmhub-wiki-container {
    background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
    min-height: calc(100vh - 60px);
    padding-bottom: 2rem;
}

/* Main container */
.pmhub-wiki-container {
    background-color: #f8f9fa;
    min-height: calc(100vh - 140px);
    padding: 20px 0;
}

.pmhub-wiki-welcome {
    background: linear-gradient(135deg, #0056b3, #4C89CA);
    color: white !important;
    padding: 2.5rem;
    border-radius: 8px;
    margin-bottom: 2rem;
    text-align: center;
}

/* Welcome section */
.pmhub-wiki-welcome {
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    margin-bottom: 1.5rem;
    text-align: center;
}

.pmhub-wiki-welcome h2 {
    color: white !important;
    margin-bottom: 1rem;
    font-size: 2rem;
    font-weight: 600;
}

.pmhub-wiki-welcome p {
    color: rgba(255, 255, 255, 0.9);
    font-size: 1.1rem;
    margin-bottom: 0;
}

.pmhub-wiki-title {
    color: #0056b3;
    font-weight: 600;
    margin-bottom: 2rem;
    position: relative;
    display: inline-block;
}

.pmhub-wiki-title:after {
    content: '';
    position: absolute;
    width: 60%;
    height: 3px;
    background: linear-gradient(90deg, #0056b3, transparent);
    bottom: -10px;
    left: 20%;
}

.pmhub-wiki-sidebar {
    position: sticky;
    top: 20px;
    border: none;
    border-radius: 8px;
    background: white;
    box-shadow: none;
    margin-bottom: 1.5rem;
    max-height: calc(100vh - 140px); /* Account for navbar and padding */
    display: flex;
    flex-direction: column;
}

.pmhub-wiki-sidebar .list-group {
    overflow-y: auto;
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: rgba(0, 0, 0, 0.2) transparent; /* For Firefox */
}

/* Webkit scrollbar styling */
.pmhub-wiki-sidebar .list-group::-webkit-scrollbar {
    width: 6px;
}

.pmhub-wiki-sidebar .list-group::-webkit-scrollbar-track {
    background: transparent;
}

.pmhub-wiki-sidebar .list-group::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
}

.pmhub-wiki-header {
    flex-shrink: 0; /* Prevent header from shrinking */
}

.pmhub-wiki-sidebar {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    margin-top: 0;
    height: fit-content;
}

.pmhub-wiki-header {
    background: linear-gradient(135deg, #0056b3, #4C89CA);
    color: white !important;
    padding: 1rem;
    font-weight: 500;
}

.pmhub-wiki-header {
    background-color: #0056b3;
    color: white;
    font-weight: 600;
    padding: 15px;
}

.pmhub-wiki-list-item {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    border: none;
    padding: 0.8rem 1rem;
    transition: background-color 0.3s ease;
    border-left: 4px solid transparent;
}

.pmhub-wiki-category-item {
    display: flex;
    align-items: center;
    padding: 12px 15px;
    justify-content: space-between;
}

.pmhub-wiki-category-item > div:first-child {
    display: flex;
    align-items: center;
    gap: 10px;
}

.pmhub-wiki-list-item:hover {
    background-color: #f8f9fa;
    color: #0056b3;
    border-left: 4px solid #4C89CA;
    transform: none;
}

.pmhub-wiki-list-item.active {
    background-color: #e9ecef;
    color: #0056b3;
    border-left: 4px solid #0056b3;
    font-weight: 500;
}

.pmhub-wiki-icon {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    background: rgba(0, 86, 179, 0.1);
    color: #0056b3;
    font-size: 1.1rem;
}

.pmhub-wiki-content-card {
    border: none;
    border-radius: 8px;
    background: white;
    box-shadow: none;
}

/* Article content */
.pmhub-wiki-content-card {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.pmhub-wiki-main-content {
    font-size: 1.2rem;
    line-height: 1.8;
    color: #495057;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #e9ecef;
}

.pmhub-wiki-main-content {
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 2rem;
}

.pmhub-wiki-section {
    position: relative;
    overflow: hidden;
    margin-bottom: 2.5rem;
    padding: 1.5rem;
    background: #f8f9fa;
    border-radius: 8px;
    border-left: 4px solid #0056b3;
    transition: none;
    transform: none;
    box-shadow: none;
}

.pmhub-wiki-section {
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #dee2e6;
}

.pmhub-wiki-section::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 4px;
    height: 0;
    background: #4C89CA;
    transition: height 0.3s ease;
}

.pmhub-wiki-section:hover::after {
    height: 100%;
}

.pmhub-wiki-section:hover {
    transform: none;
    box-shadow: none;
}

.pmhub-wiki-section h3 {
    color: #0056b3;
    font-size: 1.4rem;
    margin-bottom: 1.5rem;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.pmhub-wiki-section h3 {
    color: #0056b3;
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid #e9ecef;
}

.pmhub-wiki-section h3:before {
    content: '📌';
    margin-right: 10px;
    font-size: 1.2rem;
}

.pmhub-wiki-section-content {
    color: #495057;
    line-height: 1.8;
}

.pmhub-wiki-section-content {
    padding-left: 0.5rem;
}

/* Fix for duplicating bullets - Complete override */
.pmhub-wiki-section-content p {
    margin-bottom: 0.8rem;
    padding-left: 0;
    position: relative;
}

.pmhub-wiki-line {
    margin-bottom: 0.8rem;
    line-height: 1.6;
}

/* Style for bullet points */
.pmhub-wiki-line-bullet {
    padding-left: 1.5rem;
    position: relative;
}

.pmhub-wiki-line-bullet {
    position: relative;
    padding-left: 1.5rem;
}

.pmhub-wiki-line-bullet::before {
    content: "";
    position: absolute;
    left: 0.5rem;
    top: 0.7em;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #0056b3;
}

.pmhub-wiki-line-bullet::before {
    content: "•";
    position: absolute;
    left: 0.5rem;
    color: #0056b3;
}

/* Style for numbered items */
.pmhub-wiki-line-number {
    padding-left: 0;
}

.pmhub-wiki-line-number {
    font-weight: 500;
}

/* Style for regular text */
.pmhub-wiki-line-text {
    padding-left: 0;
}

/* Custom bullet handling for different line types */
.pmhub-wiki-section-content p:first-letter {
    margin-left: 0;
}

/* Remove the generic bullet for all paragraph items */
.pmhub-wiki-section-content p:before {
    content: none;
}

/* Style specifically for standard bullet points that start with '•' */
.pmhub-wiki-section-content p[data-bullet="true"] {
    padding-left: 1.5rem;
}

.pmhub-wiki-section-content p[data-bullet="true"]:before {
    content: '';
    position: absolute;
    left: 0.5rem;
    top: 0.7em;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #0056b3;
}

/* Style for numbered items that start with a number and period */
.pmhub-wiki-section-content p[data-numbered="true"] {
    padding-left: 1.5rem;
    counter-increment: item;
    font-weight: 500;
}

/* Custom icons for different section types */
.pmhub-wiki-section[data-type="warning"] h3:before {
    content: '⚠️';
}

.pmhub-wiki-section[data-type="tip"] h3:before {
    content: '💡';
}

.pmhub-wiki-section[data-type="important"] h3:before {
    content: '🎯';
}

.pmhub-wiki-search {
    background: white;
    padding: 1.5rem;
    border-radius: 8px;
    margin-bottom: 2rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    width: 100%;
}

/* Search bar */
.pmhub-wiki-search {
    margin-bottom: 1.5rem;
}

.pmhub-wiki-search input {
    width: 100%;
    padding: 0.8rem 1rem;
    border: 2px solid #e9ecef;
    border-radius: 6px;
    font-size: 1rem;
}

.search-input-container {
    display: flex;
    gap: 10px;
    width: 100%;
    align-items: center;
}

.search-input-container .form-control {
    flex: 1;
    height: 48px;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    border-radius: 24px;
    border: 1px solid #dee2e6;
}

.search-input-container .form-control:focus {
    border-color: #4C89CA;
    box-shadow: 0 0 0 0.2rem rgba(0, 86, 179, 0.15);
}

.search-input-container button {
    height: 48px;
    padding: 0 1.5rem;
    border-radius: 24px;
    display: flex;
    align-items: center;
    gap: 8px;
    white-space: nowrap;
    min-width: 100px;
    justify-content: center;
}

.search-result-stats {
    color: #6c757d;
    font-size: 0.9rem;
    font-weight: 500;
}

/* Responsive adjustments for search */
@media (max-width: 768px) {
    .search-input-container {
        flex-direction: column;
    }

    .search-input-container button {
        width: 100%;
        justify-content: center;
    }

    .pmhub-wiki-topic-item {
        flex-direction: column;
    }

    .topic-icon {
        margin-bottom: 1rem;
    }

    .topic-arrow {
        display: none;
    }

    .pmhub-wiki-category-item {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
    }

    .pmhub-wiki-category-item .badge {
        align-self: flex-start;
    }

    .search-input-container {
        flex-direction: column;
        gap: 1rem;
    }

    .search-input-container button {
        width: 100%;
    }

    .search-result-stats {
        margin-top: 1rem;
        text-align: center;
    }
}

/* Popular Topics Carousel */
.popular-topics-carousel {
    padding: 2rem 0;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.popular-topics-carousel h3 {
    color: #0056b3;
    font-weight: 600;
}

.custom-carousel {
    padding: 0 3rem;
}

.custom-carousel .carousel-control-prev,
.custom-carousel .carousel-control-next {
    width: 3rem;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    height: 3rem;
    top: 50%;
    transform: translateY(-50%);
    opacity: 1;
}

.custom-carousel .carousel-control-prev:hover,
.custom-carousel .carousel-control-next:hover {
    background: rgba(0, 0, 0, 0.2);
}

.custom-carousel .carousel-indicators {
    bottom: -2.5rem;
}

.custom-carousel .carousel-indicators button {
    background-color: #0056b3;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 0 5px;
}

.popular-topic-card {
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
    border: 1px solid #dee2e6;
    margin: 0.5rem;
    border-radius: 8px;
}

.popular-topic-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.topic-preview {
    color: #6c757d;
    font-size: 0.9rem;
    margin-bottom: 0;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

/* Remove the old popular topics styles */
.popular-topics {
    display: none;
}

/* Topic list */
.pmhub-wiki-topic-list {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.pmhub-wiki-topic-item {
    display: flex;
    align-items: flex-start;
    padding: 1.25rem;
    gap: 1rem;
}

.topic-icon {
    font-size: 1.5rem;
    color: #0056b3;
    background-color: rgba(0, 86, 179, 0.1);
    width: 40px;
    height: 40px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.topic-content {
    flex: 1;
    min-width: 0; /* Prevents flex item from overflowing */
}

.topic-content h5 {
    margin-bottom: 0.5rem;
    color: #0056b3;
}

.topic-content p {
    color: #6c757d;
    margin-bottom: 5px;
    font-size: 0.9rem;
}

.topic-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-top: 0.5rem;
}

.topic-arrow {
    color: #6c757d;
    display: flex;
    align-items: center;
    padding-left: 1rem;
}

.article-metadata {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #6c757d;
    font-size: 0.9rem;
    border-bottom: 1px solid #dee2e6;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.table-of-contents {
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
}

.toc-item {
    padding: 5px 10px;
    border-left: 3px solid transparent;
}

.toc-item:hover {
    background-color: #e9ecef;
    border-left-color: #0056b3;
}

/* Pagination */
.pagination-container {
    margin-top: 1.5rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .pmhub-wiki-sidebar {
        position: relative;
        top: 0;
        margin-bottom: 2rem;
    }
    
    .pmhub-wiki-content-card {
        margin-bottom: 2rem;
    }
}

/* Wiki Feature Cards */
.wiki-feature-card {
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
    height: 100%;
    border: 1px solid #dee2e6;
    border-radius: 8px;
}

.wiki-feature-card:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.feature-icon {
    font-size: 1.5rem;
    color: #0056b3;
    background-color: rgba(0, 86, 179, 0.1);
    width: 40px;
    height: 40px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Wiki Category Cards */
.wiki-category-card {
    cursor: pointer;
    transition: background-color 0.2s;
    border-left: 4px solid #0056b3;
    height: 100%;
}

.wiki-category-card:hover {
    background-color: #f0f7ff;
}

.wiki-category-card .card-body {
    padding: 1.25rem;
}

.category-icon {
    font-size: 1.5rem;
    color: #0056b3;
    margin-right: 0.5rem;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 86, 179, 0.1);
    border-radius: 8px;
}

/* Individual Wiki Page Styles */
.article-header-nav {
    background-color: white;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.back-button {
    display: inline-flex;
    align-items: center;
}

.wiki-sidebar-container {
    position: sticky;
    top: 20px;
}

.related-articles-card .related-article-item {
    padding: 12px 15px;
}

.related-icon {
    font-size: 1.2rem;
    color: #0056b3;
    background-color: rgba(0, 86, 179, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.category-browse-card .category-browse-item {
    padding: 10px 15px;
    display: flex;
    align-items: center;
}

.feedback-card {
    background-color: #f8f9fa;
}

/* Print styles */
@media print {
    .navbar, .article-header-nav, .wiki-sidebar-container, .footer, 
    .back-button, button {
        display: none !important;
    }
    
    .container-fluid {
        width: 100% !important;
        max-width: 100% !important;
        padding: 0 !important;
        margin: 0 !important;
    }
    
    .col-md-8 {
        width: 100% !important;
        max-width: 100% !important;
        flex: 0 0 100% !important;
    }
    
    .pmhub-wiki-content-card {
        border: none !important;
        box-shadow: none !important;
    }
    
    .pmhub-wiki-header {
        background: none !important;
        color: black !important;
        padding: 0 !important;
        margin-bottom: 1cm !important;
    }
    
    .article-metadata {
        color: #666 !important;
        margin-bottom: 1cm !important;
    }
    
    .pmhub-wiki-section {
        break-inside: avoid;
        page-break-inside: avoid;
        margin-bottom: 1cm !important;
    }
}

/* Grid layout adjustments */
.wiki-overview-content {
    margin-top: 0;
}

.wiki-articles-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.5rem;
    padding: 1rem 0;
}

.wiki-article-card {
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
    border: none;
    background: #fff;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.wiki-article-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 15px rgba(0,0,0,0.1) !important;
}

.wiki-article-card .card-body {
    padding: 1.25rem;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.article-title {
    font-size: 1.25rem;
    font-weight: 600;
    color: #2c3e50;
    margin-bottom: 0.75rem;
    line-height: 1.3;
}

.category-badge {
    font-size: 0.8rem;
    padding: 0.5rem 0.75rem;
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
}

.popular-badge {
    font-size: 0.8rem;
    padding: 0.5rem 0.75rem;
    display: inline-flex;
    align-items: center;
    gap: 0.3rem;
}

.article-excerpt {
    font-size: 0.9rem;
    line-height: 1.5;
    color: #666;
    margin-bottom: 1rem;
    flex-grow: 1;
}

.article-metadata {
    display: flex;
    gap: 1rem;
    padding-top: 0.75rem;
    margin-top: auto;
    border-top: 1px solid #eee;
}

.metadata-item {
    display: flex;
    align-items: center;
    gap: 0.3rem;
    color: #666;
    font-size: 0.85rem;
}

.article-tags {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.3rem;
    margin-top: 0.75rem;
    padding-top: 0.75rem;
    border-top: 1px solid #eee;
}

.tag-badge {
    font-size: 0.75rem;
    font-weight: normal;
    padding: 0.3rem 0.5rem;
}

.tags-icon {
    color: #666;
    font-size: 0.9rem;
}

.card-hover-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    color: white;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.wiki-article-card:hover .card-hover-overlay {
    opacity: 1;
}

.hover-effect {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.hover-effect:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 15px rgba(0,0,0,0.1);
}

/* Image styles for wiki content */
.wiki-content img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    margin: 1rem 0;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.content-toolbar {
    display: flex;
    gap: 0.5rem;
    padding: 0.5rem;
    background: #f8f9fa;
    border: 1px solid #dee2e6;
    border-bottom: none;
    border-radius: 4px 4px 0 0;
}

.editor-toolbar {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    padding: 0.75rem;
    background: #f8f9fa;
    border: 1px solid #dee2e6;
    border-bottom: none;
    border-radius: 4px 4px 0 0;
}

.editor-toolbar button {
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.editor-toolbar .separator {
    width: 1px;
    background: #dee2e6;
    margin: 0 0.5rem;
    align-self: stretch;
}

.section-template {
    padding: 1rem;
    border: 1px dashed #dee2e6;
    border-radius: 4px;
    margin-bottom: 1rem;
    cursor: pointer;
    transition: all 0.2s ease;
}

.section-template:hover {
    border-color: #0056b3;
    background: #f8f9fa;
}

.autosave-indicator {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    padding: 0.5rem 1rem;
    background: rgba(0, 0, 0, 0.8);
    color: white;
    border-radius: 20px;
    font-size: 0.875rem;
    z-index: 1000;
}

.image-editor-container {
    max-width: 100%;
    margin: 0 auto;
}

.image-editor-container img {
    max-width: 100%;
    height: auto;
}

.ReactCrop__crop-selection {
    border: 2px solid #0056b3;
}

.image-preview {
    margin: 1rem 0;
    padding: 1rem;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    text-align: center;
}
