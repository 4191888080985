.calendar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.calendar-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 2px;
}

.calendar-day-header {
    text-align: center;
    font-weight: bold;
    padding: 0.5rem;
}

.calendar-day {
    border: 1px solid #ddd;
    min-height: 80px;
    padding: 0.5rem;
    position: relative;
    display: flex;
    flex-direction: column;
    padding-top: 25px; /* Make room for the date number */
}

.calendar-day.different-month {
    background-color: #f5f5f5;
    color: #999;
}

.calendar-day.today {
    background-color: #e6f3ff;
}

.calendar-event {
    background-color: #007bff;
    color: white;
    padding: 2px 4px;
    border-radius: 3px;
    margin: 1px 0;
    font-size: 0.8rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
    z-index: 2;
}

.calendar-date {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 0.8rem;
    background: transparent;
    z-index: 1;
}

.calendar-day-events {
    flex-grow: 1;
    overflow-y: auto;
    max-height: calc(100% - 25px); /* Subtract the space reserved for date */
    scrollbar-width: thin;
    position: relative;
}

.calendar-day-events::-webkit-scrollbar {
    width: 4px;
}

.calendar-day-events::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.calendar-day-events::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 2px;
}

.calendar-nav-button {
    background: none;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
    padding: 0.5rem;
}

.calendar-nav-button:hover {
    background-color: #f0f0f0;
    border-radius: 50%;
}

.calendar-wrapper .dashboard-card {
    transition: none;
    transform: none;
}

.calendar-wrapper .dashboard-card:hover {
    transform: none;
    box-shadow: none;
}

/* Add dashboard-specific styling for the calendar when used in dashboard */
.dashboard-calendar {
  font-size: 0.9rem;
}

.dashboard-calendar .react-calendar {
  width: 100%;
  border: none;
  background: transparent;
}

.dashboard-calendar .react-calendar__tile {
  padding: 0.5em 0.5em;
  height: 2.5em;
}

.dashboard-calendar .react-calendar__navigation {
  margin-bottom: 0.5em;
}

.dashboard-calendar .react-calendar__navigation button {
  padding: 0.25em;
}
