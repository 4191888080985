/* Sprint Board Layout */
.current-sprint-container {
    padding: 1rem;
    max-width: 100%;
    overflow-x: hidden; /* Prevent horizontal scrolling */
    position: relative;
}

.sprint-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
}

.sprint-info {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.sprint-status {
    font-size: 0.9rem;
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    display: flex;
    align-items: center;
}

.sprint-status.active {
    background-color: #e7f5ec;
    color: #198754;
}

.sprint-status.inactive {
    background-color: #f8f9fa;
    color: #6c757d;
}

/* Sprint Metrics */
.sprint-metrics {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
    margin-bottom: 2rem;
}

.metric-card {
    background: white;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
    text-align: center;
}

.metric-title {
    color: #6c757d;
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
}

.metric-value {
    font-size: 1.5rem;
    font-weight: 600;
    color: #0d6efd;
}

/* Sprint Board */
.sprint-board {
    display: grid;
    grid-template-columns: repeat(4, minmax(280px, 1fr));
    gap: 1rem;
    align-items: start;
    padding: 0.5rem;
    margin: -0.5rem;
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
}

.sprint-board::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.sprint-board::-webkit-scrollbar-track {
    background: transparent;
}

.sprint-board::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
}

.sprint-column {
    background: #f8f9fa;
    border-radius: 8px;
    padding: 1rem;
    min-width: 280px; /* Ensure minimum column width */
    max-width: 100%; /* Prevent columns from growing too wide */
    height: calc(100vh - 400px); /* Adjust based on your header/footer heights */
    display: flex;
    flex-direction: column;
    max-height: 800px;
}

.column-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    font-size: 1rem;
    color: #495057;
    background-color: #f8f9fa;
    border-radius: 8px 8px 0 0;
    padding: 1rem;
    border-bottom: 2px solid #e9ecef;
}

.story-count {
    background: #e9ecef;
    color: #6c757d;
    padding: 0.2rem 0.6rem;
    border-radius: 1rem;
    font-size: 0.8rem;
}

.column-content {
    min-height: 200px;
    padding: 0.5rem;
    overflow-y: auto; /* Allow vertical scrolling within columns */
    flex: 1;
    margin: 0 -0.5rem;
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
}

.column-content::-webkit-scrollbar {
    width: 6px;
}

.column-content::-webkit-scrollbar-track {
    background: transparent;
}

.column-content::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
}

.column-content.dragging-over {
    background: rgba(13, 110, 253, 0.05);
}

/* Story Cards */
.story-card {
    background: white;
    border-radius: 6px;
    padding: 0.75rem;
    margin-bottom: 0.75rem;
    box-shadow: 0 1px 3px rgba(0,0,0,0.05);
    border: 1px solid #dee2e6;
    cursor: grab;
    border-left-width: 4px;
    transition: all 0.2s ease;
}

.story-card.dragging {
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    border-color: #0d6efd;
}

.story-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0,0,0,0.1);
}

.story-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
}

.story-id {
    font-family: monospace;
    font-size: 0.8rem;
    color: #6c757d;
}

.story-title {
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
    color: #212529;
}

.story-points {
    font-size: 0.8rem;
    color: #0d6efd;
    font-weight: 500;
}

.story-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.75rem;
    padding-top: 0.75rem;
    border-top: 1px solid #e9ecef;
}

.story-metrics {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.acceptance-progress {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    min-width: 100px;
}

.acceptance-progress .progress {
    flex: 1;
    height: 4px;
    background-color: #e9ecef;
    border-radius: 2px;
    overflow: hidden;
}

.acceptance-progress .progress-bar {
    background: linear-gradient(45deg, #0d6efd, #0dcaf0);
    border-radius: 2px;
}

.criteria-count {
    font-size: 0.75rem;
    color: #6c757d;
    min-width: 32px;
    text-align: right;
}

.assignee-avatar {
    width: 28px;
    height: 28px;
    background: linear-gradient(45deg, #0d6efd, #0dcaf0);
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75rem;
    font-weight: 600;
}

.sprint-goal-banner {
    background-color: #e7f1ff;
    border-left: 4px solid #0d6efd;
    padding: 0.75rem 1rem;
    border-radius: 4px;
    color: #0d6efd;
    margin-bottom: 1.5rem;
}

/* Sprint Progress Summary */
.sprint-progress-summary {
    background: white;
    border-radius: 12px;
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    box-shadow: 0 2px 8px rgba(0,0,0,0.05);
}

.progress-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.progress-header h4 {
    margin: 0;
    font-weight: 600;
    color: #2c3e50;
}

.risk-indicator {
    padding: 0.35rem 0.75rem;
    border-radius: 6px;
    font-size: 0.75rem;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.risk-indicator.low {
    background-color: #e7f5ec;
    color: #198754;
}

.risk-indicator.medium {
    background-color: #fff3cd;
    color: #997404;
}

.risk-indicator.high {
    background-color: #f8d7da;
    color: #dc3545;
}

.progress-metrics {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
}

.metric {
    background: #f8f9fa;
    padding: 1rem;
    border-radius: 8px;
}

.metric-title {
    color: #6c757d;
    font-size: 0.85rem;
    margin-bottom: 0.5rem;
}

.metric-value {
    font-size: 1.25rem;
    font-weight: 600;
    color: #2c3e50;
}

/* Empty Column State */
.empty-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 120px;
    color: #6c757d;
    background: #f8f9fa;
    border: 2px dashed #dee2e6;
    border-radius: 8px;
    margin: 0.5rem;
}

.empty-column i {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    opacity: 0.5;
}

.empty-column p {
    margin: 0;
    font-size: 0.85rem;
}

/* Responsive Adjustments */
@media (max-width: 1400px) {
    .sprint-board {
        grid-template-columns: repeat(2, minmax(280px, 1fr));
    }
}

@media (max-width: 1200px) {
    .sprint-metrics {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .sprint-header {
        flex-direction: column;
        gap: 1rem;
        align-items: flex-start;
    }

    .sprint-metrics {
        grid-template-columns: 1fr;
    }

    .sprint-board {
        grid-template-columns: repeat(1, 1fr);
    }
}
