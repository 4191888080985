.agilehub-container {
    padding: 20px;
    height: 100%;
}

.agilehub-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.agilehub-content {
    display: grid;
    gap: 20px;
}

.features-section {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.feature-card {
    background: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 6px;
    padding: 15px;
    margin-bottom: 10px;
}

.feature-card h4 {
    margin: 0 0 10px 0;
    color: #333;
}

.feature-card p {
    margin: 0;
    color: #666;
}

.agilehub-tabs {
    margin-bottom: 20px;
}

.nav-tabs .nav-link {
    color: #495057;
    border: none;
    padding: 10px 20px;
}

.nav-tabs .nav-link.active {
    color: #007bff;
    border-bottom: 2px solid #007bff;
    background: none;
}

.tab-content {
    padding: 20px 0;
}

.stat-card {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin-bottom: 15px;
}

.overview-stats {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
}

/* ...keep remaining code... */
