.new-project-page {
    background: linear-gradient(135deg, #0056b3, #4C89CA);
    min-height: 100vh;
}

.new-project-page .new-project-app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    text-align: center;
}

.new-project-page .new-project-container {
    flex: 1;
}

.new-project-page .new-project-form-group {
    max-width: 800px; /* increased from 600px */
    margin: 0 auto;
    padding: 20px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.new-project-page .new-project-form-label {
    font-weight: bold;
}

.new-project-page .new-project-btn-primary {
    background-color: #0056b3 !important;
    color: white !important;
    border-color: #0056b3;
}

.new-project-page .new-project-btn-primary:hover {
    background-color: #004494;
    border-color: #004494;
}

.column-toggle {
    border: 1px solid #0056b3;
    background-color: #fff;
    color: #0056b3;
    padding: 8px 12px;
    border-radius: 5px;
    transition: background-color 0.2s, color 0.2s;
}

.column-toggle.active {
    background-color: #0056b3 !important;
    color: #fff !important;
}

.feature-toggle {
    border: 2px solid #0056b3;
    background: #fff;
    color: #0056b3;
    padding: 12px 20px;
    border-radius: 8px;
    transition: background 0.2s, color 0.2s;
    margin: 5px;
}

.feature-toggle.active {
    background: #0056b3;
    color: #fff;
}