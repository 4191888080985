.card {
    margin-top: 25px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 50px;
}

.card-title {
    margin-bottom: 20px;
}

.resetpassword-btn-primary {
    background-color: #007bff;
    border-color: #007bff;
    margin-top: 10px;
}

.resetpassword-btn-primary:hover {
    background-color: #0056b3;
    border-color: #0056b3;
}