@import url('https://fonts.googleapis.com/css2?family=Moderustic:wght@300..800&display=swap');

body {
    font-family: 'Moderustic', sans-serif;
    margin: 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #f8f9fa;
}

.container-fluid {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0;
}

.sidebar {
    bottom: 0;
    left: 0;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
    width: 100%;
    max-width: 250px;
    text-align: left;
}

@media (max-width: 767.98px) {
    .sidebar {
        padding: 0;
    }
}

.main-content {
    flex: 1;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
    border-radius: 15px;
    margin: 20px;
    height: calc(100vh - 140px);
    overflow: hidden;
}

@media (max-width: 767.98px) {
    .main-content {
        margin-left: 0;
        width: 100%;
    }
}

.row {
    margin: 0; /* Remove default margin */
}

.row.min-vh-100 {
    flex: 1;
    overflow: hidden;
    margin: 0;
    height: 100%;
}

.col-md-6 {
    padding: 0; /* Remove default padding */
}

.col-md-12 {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.editable-div,
.editable-input {
    padding: 10px;
    border-radius: 4px;
    min-height: 150px;
    background-color: white;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #ced4da;
    margin-bottom: 20px;
}

.meeting-minutes,
.additional-notes {
    background-color: white;
    border: none;
}

.full-height {
    height: 100vh;
}

.col-md-6 {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.editable-input {
    border: 1px solid #ced4da;
    outline: none;
    resize: none;
    height: 100vh;
}

#editor-minutes {
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    border: none;
    border-radius: 12px;
    background-color: white;
    margin-bottom: 2rem;
}

#editor-minutes:focus-within {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.align-left {
    text-align: left;
}

#editor-minutes {
    position: relative;
    z-index: 1;
}

.ql-toolbar {
    position: relative;
    z-index: 2;
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
    background-color: #f8f9fa !important;
    border: none !important;
    border-bottom: 1px solid #e9ecef;
    padding: 1rem !important;
}

.ql-container {
    flex: 1;
    overflow: auto;
    border: none !important;
    font-size: 16px !important;
}

.ql-container .ql-editor[data-columns="3"] {
    column-count: 3;
}

.ql-editor {
    min-height: 100%;
    padding: 2rem !important;
}

.meeting-details {
    display: flex;
    align-items: center;
    gap: 2rem;
    margin-top: 10px;
    padding: 1rem 0;
    flex-wrap: wrap;
}

.meeting-details span {
    font-size: 1.2em;
    font-weight: 500;
    color: #333;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    background-color: #f8f9fa;
    border-radius: 6px;
    transition: all 0.3s ease;
}

.meeting-details span:hover {
    background-color: #e9ecef;
    transform: translateY(-2px);
}

.meeting-details input {
    font-size: 1.2em;
    font-weight: 500;
    color: #333;
    border: 1px solid #ced4da;
    border-radius: 4px;
    padding: 5px;
    outline: none;
    width: auto;
}

.meeting-title {
    font-size: 2rem;
    font-weight: normal;
    margin: 0;
    padding: 0;
    color: #333;
    cursor: pointer;
}

.meeting-title:hover {
    color: #2563eb;
    border-bottom: 3px solid #2563eb;
}

.meeting-title::before {
    content: '';
    position: absolute;
    left: -1rem;
    top: 50%;
    transform: translateY(-50%);
    width: 4px;
    height: 70%;
    background: #2563eb;
    border-radius: 2px;
}

.meeting-title-input {
    font-size: 2rem;
    height: auto;
    padding: 0.25rem 0.5rem;
    font-weight: 800;
    color: #1a365d;
    border: none;
    border-bottom: 3px solid #2563eb;
    border-radius: 0;
    margin: 0;
    background: transparent;
    width: 100%;
    letter-spacing: -0.5px;
    transition: all 0.3s ease;
}

.meeting-title-input:focus {
    outline: none;
    box-shadow: none;
    border-bottom: 3px solid #2563eb;
    color: #2563eb;
}

.delete-meeting {
    margin-left: auto;
    padding: 0.75rem 1.5rem;
    font-weight: 600;
    letter-spacing: 0.5px;
    border-radius: 8px;
    transition: all 0.3s ease;
    background-color: #dc3545;
    border: none;
    color: white;
}

.delete-meeting:hover {
    background-color: #c82333;
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(220, 53, 69, 0.2);
}

h2 {
    font-size: 1.5em;
    font-weight: 600;
    color: #0056b3;
    margin-bottom: 20px;
}

/* Enhanced Meeting Header */
.meeting-header {
    background: linear-gradient(135deg, #ffffff 0%, #f8f9fa 100%);
    padding: 2.5rem 3rem;
    border-radius: 15px;
    margin-bottom: 2rem;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
    border: 1px solid #e9ecef;
}

.meeting-header-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1.5rem;
    position: relative;
}

.meeting-metadata {
    display: flex;
    align-items: center;
    gap: 2rem;
    padding: 1.25rem;
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(37, 99, 235, 0.1);
    margin-top: 1.5rem;
    border: 1px solid rgba(37, 99, 235, 0.15);
}

.metadata-item {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    background: rgba(37, 99, 235, 0.03);
    border-radius: 8px;
    transition: all 0.3s ease;
}

.metadata-item i {
    font-size: 1.1rem;
    color: #2563eb;
    margin-right: 0.75rem;
}

.metadata-item span {
    font-size: 1rem;
    color: #1a365d;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
}

.metadata-item:hover {
    background: rgba(37, 99, 235, 0.08);
    transform: translateY(-1px);
}

.metadata-item span:hover {
    color: #2563eb;
}

.metadata-divider {
    width: 1px;
    height: 2rem;
    background: linear-gradient(180deg, 
        transparent 0%, 
        rgba(37, 99, 235, 0.2) 50%,
        transparent 100%
    );
}

.delete-meeting {
    padding: 0.6rem 1.2rem;
    font-size: 0.9rem;
    font-weight: 600;
    border-radius: 6px;
    background-color: #fff;
    color: #dc3545;
    border: 1px solid #dc3545;
    transition: all 0.3s ease;
}

.delete-meeting:hover {
    background-color: #dc3545;
    color: #fff;
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(220, 53, 69, 0.2);
}

.meeting-date,
.meeting-time {
    background-color: #ffffff;
    border: 2px solid #2563eb;
    border-radius: 6px;
    padding: 0.5rem 0.75rem;
    font-size: 1rem;
    color: #1a365d;
    transition: all 0.2s ease;
    width: auto;
    min-width: 120px;
}

.meeting-date:focus,
.meeting-time:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.2);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .meeting-header {
        padding: 1.5rem;
    }

    .meeting-header-top {
        flex-direction: column;
        gap: 1rem;
        align-items: flex-start;
    }

    .meeting-metadata {
        flex-direction: column;
        gap: 1rem;
        padding: 1rem;
    }

    .metadata-item {
        width: 100%;
        justify-content: center;
    }

    .metadata-divider {
        display: none;
    }

    .delete-meeting {
        width: 100%;
    }

    .meeting-title,
    .meeting-title-input {
        font-size: 2em;
    }

    .meeting-title::before {
        left: -0.5rem;
        height: 60%;
    }

    .save-prompt {
        margin-top: 0.5rem;
        font-size: 0.8rem;
    }
}

/* Loading States */
.loading {
    opacity: 0.7;
    pointer-events: none;
}

/* Autosave Indicator */
.autosave-indicator {
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 0.5rem 1rem;
    background-color: #28a745;
    color: white;
    border-radius: 20px;
    font-size: 0.9em;
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.3s ease;
}

.autosave-indicator.visible {
    opacity: 1;
    transform: translateY(0);
}

/* Animation Keyframes */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.save-indicator {
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 12px 24px;
    background-color: #28a745;
    color: white;
    border-radius: 8px;
    font-size: 0.9rem;
    font-weight: 500;
    animation: fadeInOut 2s ease-in-out;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 1000;
}

.save-indicator.error {
    background-color: #dc3545;
}

@keyframes fadeInOut {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    20% {
        opacity: 1;
        transform: translateY(0);
    }
    80% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(-20px);
    }
}

.unsaved-indicator {
    animation: fadeInOut 2s infinite;
}

@keyframes fadeInOut {
    0% { opacity: 0.4; }
    50% { opacity: 0.8; }
    100% { opacity: 0.4; }
}

.save-prompt {
    font-size: 0.9rem;
    color: #6c757d;
    background-color: rgba(108, 117, 125, 0.1);
    padding: 0.5rem 1rem;
    border-radius: 6px;
    animation: pulse 2s infinite;
    display: inline-flex;
    align-items: center;
    height: fit-content;
}

@keyframes pulse {
    0% { opacity: 0.6; }
    50% { opacity: 1; }
    100% { opacity: 0.6; }
}