.register-card {
    margin-top: 25px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 50px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
}

.register-card-title {
    margin-bottom: 20px;
    text-align: center;
}

.register-form-group {
    margin-bottom: 1rem;
}

.register-form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

.register-btn-primary {
    background: var(--gradient);
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 20px auto 0;
    cursor: pointer;
    border-radius: 10px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: block;
    width: 100%;
    max-width: 200px;
}

.register-btn-primary:hover {
    transform: translateY(-2px);
    box-shadow: var(--shadow);
}

.register-btn-primary:focus {
    outline: 2px solid #0056b3;
    outline-offset: 2px;
}

.register-form-check {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 15px;
    gap: 10px;
}

.register-checkbox {
    margin-right: 10px;
    min-width: 20px;
    height: 20px;
}

.register-policy-link {
    color: var(--primary);
    text-decoration: underline;
    font-weight: 600;
}

.register-policy-link:hover {
    color: var(--primary-light);
}

.register-password-rules {
    list-style-type: none;
    padding: 0;
    margin-top: 10px;
}

.register-password-rules li {
    color: red;
}

.register-password-rules li.valid {
    color: green;
}

.register-login-link {
    display: block;
    text-align: center;
    margin-top: 1rem;
    color: var(--primary);
    text-decoration: none;
}

.register-message {
    text-align: center;
    margin-top: 1rem;
    color: var(--primary);
}

.register-container {
    flex: 1;
    padding-bottom: 2rem;
    background: var(--gradient-main);
}

.register-card-body {
    background-color: #ffffff;
}

@media (max-width: 576px) {
    .register-card {
        margin-top: 15px;
        margin-bottom: 30px;
        padding: 15px;
    }
}

.subscription-cards {
    display: flex;
    gap: 25px;
    justify-content: center;
    margin: 1.5rem 0;
}

@media (max-width: 767px) {
    .subscription-cards {
        flex-direction: column;
        align-items: center;
    }
    
    .subscription-card {
        width: 100%;
        max-width: 350px;
    }
}

.subscription-card {
    flex: 1;
    max-width: 350px;
    padding: 20px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 2px 10px rgba(0,0,0,0.05);
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    border: 2px solid #f0f0f0;
    display: flex;
    flex-direction: column;
}

.subscription-card:hover {
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(0,0,0,0.08);
}

.subscription-card.selected {
    border-color: var(--primary);
}

.subscription-card-header {
    text-align: center;
    padding-bottom: 15px;
    border-bottom: 1px solid #f0f0f0;
    margin-bottom: 15px;
}

.subscription-card h3 {
    color: #333;
    font-size: 1.3rem;
    margin-bottom: 10px;
    font-weight: 600;
}

.subscription-card .price {
    font-size: 1.8rem;
    font-weight: bold;
    color: #2c3e50;
    margin: 5px 0;
}

.feature-category {
    margin-bottom: 15px;
}

.feature-category h4 {
    font-size: 0.9rem;
    color: #666;
    margin-bottom: 8px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.subscription-card ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.subscription-card ul li {
    margin: 8px 0;
    color: #555;
    font-size: 0.9rem;
    display: flex;
    align-items: flex-start;
    line-height: 1.4;
}

.subscription-card ul li::before {
    content: "✓";
    color: #4CAF50;
    font-weight: bold;
    margin-right: 8px;
    flex-shrink: 0;
}

.savings-badge {
    background: #4CAF50;
    color: white;
    padding: 2px 6px;
    border-radius: 4px;
    font-size: 0.7rem;
    margin-left: 5px;
    font-weight: 500;
}

.billing-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f7f8fa;
    padding: 8px;
    border-radius: 24px;
    margin-bottom: 15px;
}

.billing-toggle span {
    padding: 5px 10px;
    font-size: 0.9rem;
    border-radius: 16px;
    transition: all 0.2s ease;
}

.billing-toggle span.active {
    background: white;
    color: var(--primary);
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    font-weight: 500;
}

/* Toggle Switch Styling */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
}

.switch input:checked + .slider {
    background-color: var(--primary);
}

.switch input:checked + .slider:before {
    transform: translateX(26px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.subscription-section {
    margin: 2rem 0;
    padding: 1.5rem 0;
    border-top: 1px solid #eee;
}

.subscription-title {
    text-align: center;
    font-size: 1.4rem;
    margin-bottom: 1.5rem;
    color: #333;
    font-weight: 500;
}

.subscription-subtitle {
    text-align: center;
    color: #666;
    margin-bottom: 2rem;
}

/* Plan comparison styles */
.plan-comparison {
    margin-top: 20px;
    border-top: 1px solid #f0f0f0;
    padding-top: 15px;
}

.plan-comparison-toggle {
    text-align: center;
    color: var(--primary);
    cursor: pointer;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
}

.toggle-icon {
    margin-left: 5px;
    font-weight: bold;
}

.plan-comparison-details {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
}

.plan-comparison-details.expanded {
    max-height: 500px;
}

.comparison-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 15px;
    font-size: 0.85rem;
}

.comparison-table th, 
.comparison-table td {
    padding: 8px;
    text-align: center;
    border-bottom: 1px solid #f0f0f0;
}

.comparison-table th {
    background-color: #f7f8fa;
    font-weight: 600;
    color: #333;
}

.comparison-table td:first-child {
    text-align: left;
    font-weight: 500;
}

/* ...existing styles... */

.register-btn-secondary {
    background: #fff;
    color: var(--primary);
    border: 2px solid var(--primary);
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 10px;
    transition: all 0.3s ease;
    min-width: 120px;
    font-weight: 600;
    text-align: center;
    height: 44px; /* Match the height */
    padding: 8px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.register-btn-secondary:hover {
    background: var(--primary-light);
    color: white;
    border-color: var(--primary-light);
    transform: translateY(-2px);
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.register-btn-secondary:disabled {
    opacity: 0.6;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
}

.stripe-card-element {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding: 0.75rem;
    background: white;
}

.register-error-message {
    color: #dc3545;
    font-size: 0.875rem;
    margin-top: 0.5rem;
    text-align: center;
}

/* Add visual indicator for required fields */
.register-form-group label.required::after {
    content: "*";
    color: #dc3545;
    margin-left: 3px;
}

/* Enhance visibility of validation errors */
.register-error-message {
    color: #dc3545;
    font-size: 0.875rem;
    margin-top: 0.5rem;
    text-align: center;
    background-color: rgba(220, 53, 69, 0.1);
    padding: 10px;
    border-radius: 5px;
    border-left: 3px solid #dc3545;
}

.secure-payment-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    padding: 12px;
    background-color: #f8f9fa;
    border-radius: 8px;
    border: 1px solid #e9ecef;
}

.lock-icon {
    margin-right: 8px;
    font-size: 1.1rem;
}

.secure-text {
    color: #6c757d;
    font-size: 0.9rem;
    font-weight: 500;
}

.stripe-card-element.StripeElement--focus {
    border-color: var(--primary);
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.stripe-card-element.StripeElement--invalid {
    border-color: #dc3545;
}

/* Add styles for button container */
.d-flex.justify-content-between.mt-4 {
    gap: 15px;
    align-items: center; /* Add this to vertically align buttons */
}

/* Update button styles to ensure consistent height */
.d-flex.justify-content-between.mt-4 button {
    flex: 1;
    max-width: 200px;
    height: 44px; /* Set fixed height for both buttons */
    line-height: 24px; /* Adjust line height for vertical centering */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 20px;
}

/* Ensure both buttons have consistent width */
.d-flex.justify-content-between.mt-4 button {
    flex: 1;
    max-width: 200px;
}

/* Update button container styles */
.d-flex.justify-content-between.mt-4 {
    display: flex;
    gap: 15px;
    align-items: stretch; /* Change to stretch */
    margin: 1.5rem 0; /* Add consistent margin */
    max-width: 500px; /* Limit container width */
    margin-left: auto;
    margin-right: auto;
}

/* Update button styles to be truly consistent */
.register-btn-primary,
.register-btn-secondary {
    height: 44px;
    min-height: 44px; /* Ensure minimum height */
    padding: 0 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    margin: 0; /* Remove default margins */
    max-width: none; /* Remove individual max-width */
    font-size: 1rem;
    line-height: 1;
}
