.project-settings-card-body ul {
    list-style-type: none;
    padding: 0;
}

.project-settings-card-body li {
    padding: 10px;
    margin: 5px 0;
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 5px;
    display: flex;
    align-items: center;
}

.project-settings-card-body li input[type="checkbox"] {
    margin-right: 10px;
}

.project-settings-card-body h2, .project-settings-card-body h3 {
    margin-bottom: 20px;
}

.project-settings-card-body {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

.project-settings-card-body .add-column {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: 5px 0;
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 5px;
}

.project-settings-card-body .add-column .btn-link {
    font-size: 24px;
    color: #007bff;
    text-decoration: none;
}

.project-settings-card-body .add-column .btn-link:hover {
    color: #0056b3;
    text-decoration: none;
}

.project-settings-card-body .btn-link.text-danger {
    margin-left: 10px;
    font-size: 18px;
    color: #dc3545;
}

.project-settings-card-body .btn-link.text-danger:hover {
    color: #c82333;
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-inner {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 300px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.popup-inner h3 {
    margin-bottom: 20px;
}

.popup-inner input {
    margin-bottom: 20px;
    width: 100%;
}

.popup-inner button {
    width: 100%;
    margin-bottom: 10px;
}

.popup-inner button:last-child {
    margin-bottom: 0;
}

.container-fluid {
    /* Removed flex layout to allow page to extend naturally */
    /* display: flex;
    flex-direction: column;
    min-height: 100vh; */
    height: auto; /* Allow container to extend with content */
}

.main-content {
    /* Removed flex property that forces content to shrink */
    /* flex: 1; */
    height: auto; /* Allow main content to grow based on its content */
}

@media (max-width: 768px) {
    .project-settings-card-body {
        padding: 10px;
    }

    .project-settings-card-body h2, .project-settings-card-body h3 {
        margin-bottom: 10px;
    }

    .project-settings-card-body li {
        padding: 5px;
        margin: 3px 0;
    }

    .popup-inner {
        width: 90%;
    }
}
