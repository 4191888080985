@import url('https://fonts.googleapis.com/css2?family=Moderustic:wght@300..800&display=swap');

/* Base Styles */
:root {
    --primary: #0056b3;
    --primary-light: #4C89CA;
    --text-dark: #333;
    --text-light: #666;
    --white: #fff;
    --gradient: linear-gradient(135deg, var(--primary), var(--primary-light));
    --shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    --shadow-hover: 0 15px 35px rgba(0, 0, 0, 0.2);
    --container-width: 1200px;
    --gradient-fade: linear-gradient(180deg, var(--primary) 0%, var(--primary-light) 50%, #f8f9fa 100%);
    --gradient-reverse: linear-gradient(180deg, #f8f9fa 0%, var(--primary-light) 50%, var(--primary) 100%);
    --gradient-main: linear-gradient(180deg, var(--primary) 0%, var(--primary-light) 60%, #f8f9fa 100%);
}

body, html {
    margin: 0;
    padding: 0;
    font-family: 'Moderustic', sans-serif;
    color: var(--text-dark);
    line-height: 1.6;
}

.container {
    max-width: var(--container-width);
    margin: 0 auto;
    padding: 0;
}

.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    text-align: center;
}

main {
    flex: 1;
}

.gradient-bg {
    background: var(--gradient);
    color: white;
}

.siteHeader {
    background: var(--gradient);
    color: white;
    margin: 0;
    padding: 0;
}

/* Remove or comment out the old .homeIntro class */
/* .homeIntro {
    min-height: 100vh;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    background: linear-gradient(135deg, rgba(0,86,179,0.95), rgba(76,137,202,0.95));
} */

.welcomeCard {
    background: transparent;
    position: relative;
    z-index: 2;
    margin: 0;
    padding: 0;
    min-height: 70vh; /* Adjust this value as needed */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    text-align: center;
}

.welcomeCard-contentLeft {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 250px;
    width: 700px;
    border-radius: 10px;
    box-shadow: 10px 10px 15px black;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
}

.welcomeCard-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
}

.productFeatures {
    background-color: #E6EEF7;
    margin: 0;
    padding: 100px 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 30px;
    position: relative;
    overflow: hidden;
}

.productFeatures-header {
    width: 100%;
    text-align: center;
    margin-bottom: 40px;
}

.feature-card {
    background: var(--gradient);
    color: white;
    border-radius: 15px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    padding: 30px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    height: 100%;
    min-height: 450px; /* Increased minimum height to ensure consistency */
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
}

.feature-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.3);
}

.feature-card h3 {
    margin: 15px 0;
    font-size: 22px;
    font-weight: 600;
    position: relative;
    z-index: 2;
    color: white;
}

.feature-card p {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 15px;
    position: relative;
    z-index: 2;
    flex-grow: 1; /* Allow paragraph to expand */
    color: white;
}

.feature-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.05);
    opacity: 0;
    transition: opacity 0.3s ease;
}

.feature-card:hover::before {
    opacity: 1;
}

.feature-icon {
    margin: 0 auto 20px;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #e6f2ff; /* Light blue background */
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease;
}

.feature-icon svg {
    color: #0056b3; /* Blue color for the icons */
    filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1));
}

.feature-card:hover .feature-icon {
    transform: scale(1.1);
    background-color: #d1e8ff; /* Slightly different color on hover */
}

@media (max-width: 992px) {
    .feature-card {
        flex: 0 1 calc(50% - 15px);
    }
}

@media (max-width: 576px) {
    .feature-card {
        flex: 0 1 100%;
    }
}

.shiny-text {
    position: relative;
    display: inline-block;
    font-size: 36px; /* Adjust the font size as needed */
    color: #0056b3; /* Base color */
    background: var(--gradient);
    background-size: 200%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text; /* Standard property for compatibility */
    animation: shiny 5s linear infinite;
}

@keyframes shiny {
    0% {
        background-position: -200%;
    }
    100% {
        background-position: 200%;
    }
}

.aboutUs {
    padding-top: 100px;
    position: relative;
    z-index: 5;
    border-radius: 0;
    padding: 50px 20px; /* Add padding for spacing */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap; /* Ensure responsiveness */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.aboutUs h1 {
    font-size: 36px; /* Larger font size for the heading */
    margin-bottom: 20px; /* Space below the heading */
    color: #0056b3; /* Use a color that matches your theme */
    text-align: center; /* Center align the heading */
    width: 100%; /* Ensure full width */
}

.aboutUs p {
    font-size: 16px; /* Standard font size for paragraphs */
    line-height: 1.6; /* Improve readability */
    color: #333; /* Darker color for text */
    margin-bottom: 15px; /* Space between paragraphs */
    text-align: justify; /* Justify text for a cleaner look */
}

.aboutUs .col-lg-4 {
    flex: 1 1 100%; /* Ensure the heading takes full width on smaller screens */
    text-align: center; /* Center align the heading */
}

.aboutUs .col-lg-8 {
    flex: 1 1 100%; /* Ensure the text takes full width on smaller screens */
    text-align: left; /* Left align the text */
    max-width: 800px; /* Limit the width for better readability */
    margin: 0 auto; /* Center the text block */
}

@media (min-width: 768px) {
    .aboutUs .col-lg-4 {
        flex: 1 1 30%; /* Adjust width for larger screens */
        text-align: left; /* Left align the heading */
    }

    .aboutUs .col-lg-8 {
        flex: 1 1 70%; /* Adjust width for larger screens */
    }
}

.getStarted {
    background: var(--gradient); /* Gradient background */
    color: white;
    border: none;
    padding: 10px 20px;
    margin-top: 20px;
    cursor: pointer;
    border-radius: 10px;
}

.getStarted:hover {
    background: linear-gradient(135deg, #004494, #3B7BBF); /* Darker gradient on hover */
}

.getStarted:active,
.getStarted:focus {
    background: linear-gradient(135deg, #003366, #2A6BAA); /* Even darker gradient on active/focus */
    outline: none; /* Remove default outline */
}

.getStarted:focus {
    outline: 2px solid #80AAD9; /* Light blue outline */
}

.feature1 {
    padding-top: 10px;
    background-color: #0056b3;
    color: white;
}
.feature2 {
    padding-top: 10px;
    background-color: #1A67BB;
    color: white;
}
.feature3 {
    padding-top: 10px;
    background-color: #3378C2;
    color: white;
}
.feature4 {
    padding-top: 10px;
    background-color: #4C89CA;
    color: white;
}

.dashboardImage {
    width: 100%;
    height: auto;
    padding: 10px;
    box-shadow: 10px 10px 10px black inset;
}

/* Document Container Styles */
.documentContainer {
    background: linear-gradient(180deg, #f8f9fa 0%, var(--primary-light) 30%, var(--primary) 100%);
    margin-top: -50px;
    padding-top: 100px;
    position: relative;
    z-index: 2;
    min-height: 300px;
    overflow: hidden;
}

.document-icon {
    opacity: 0;
    transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

.document-icon.visible {
    opacity: .25;
}

.document-icon.slide-to-center {
    transform: translate(0, 0) scale(0.1);
    opacity: 0;
}

.central-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    animation: fadeIn 2s forwards;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10;
}

.central-icon h2 {
    opacity: 0;
    animation: fadeIn 2s forwards 1s;
    margin-top: 20px;
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}

.testimonials {
    background: var(--gradient);
    padding: 50px 0;
    color: white;
}

.testimonials h2 {
    margin-bottom: 30px;
}

.testimonials p {
    font-style: italic;
}

.pricingTable .row {
    background-color: #f8f9fa;
    padding: 50px 0;
}

.pricingTable h2 {
    margin-bottom: 30px;
}

.pricingPlanLabel {
    padding-left: 10px;
}

.form-check.form-switch {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.pricing-card {
    background: rgba(255, 255, 255, 0.95);
    border-radius: 15px;
    padding: 2rem;
    height: 100%;
    box-shadow: var(--shadow);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.pricing-card:hover {
    transform: translateY(-5px);
    box-shadow: var(--shadow-hover);
}

.pricing-card h3 {
    margin-bottom: 15px;
    font-size: 24px;
}

.pricing-card .price {
    font-size: 20px;
    margin-bottom: 15px;
    color: #0056b3;
}

.pricing-card ul {
    list-style: none;
    padding: 0;
    margin: 0 0 20px 0;
    flex-grow: 1; /* Allow the list to grow and take available space */
}

.pricing-card ul li {
    margin-bottom: 10px;
    font-size: 20px;
}

.pricing-card button {
    background: var(--gradient);
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 10px;
    align-self: flex-end; /* Align the button to the bottom */
    width: 100%;
}

.pricing-card button:hover {
    background: linear-gradient(135deg, #004494, #3B7BBF);
}

/* Media Queries for Responsiveness */
@media (max-width: 1200px) {
    .welcomeCard-contentLeft {
        width: 600px;
    }
}

@media (max-width: 992px) {
    .welcomeCard-contentLeft {
        width: 500px;
    }
    .feature {
        transform: translateX(0); /* Ensure features are visible */
    }
    .productFeatures {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .welcomeCard-contentLeft {
        width: 400px;
    }
    .feature {
        padding: 5px; /* Reduce padding for smaller screens */
    }
    .document-icon {
        width: 50px;
        height: 50px;
    }
}

@media (max-width: 576px) {
    .welcomeCard-contentLeft {
        width: 300px;
    }
    .getStarted {
        padding: 5px 10px; /* Adjust button padding */
    }
    .feature {
        padding: 2px; /* Further reduce padding */
    }
    .document-icon {
        width: 40px;
        height: 40px;
    }
    .productFeatures {
        grid-template-columns: 1fr;
    }
}

.benefits {
    padding: 100px 0 50px;
    position: relative;
    z-index: 2;
    color: var(--white);
    background: transparent;
}

.benefit-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 30px;
    padding: 20px;
    grid-auto-rows: 1fr; /* Force all rows to have the same height */
}

.benefit-card {
    background: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 15px;
    padding: 2rem;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    min-height: 280px; /* Increased from 250px to accommodate taller content */
    height: 100%; /* Added to ensure all cards take full height of their container */
    display: flex;
    flex-direction: column;
}

.benefit-card:hover {
    transform: translateY(-5px);
    box-shadow: var(--shadow-hover);
}

.benefit-card h3 {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 22px;
    font-weight: 600;
    color: white;
}

.benefit-card p {
    margin: 0;
    font-size: 16px;
    line-height: 1.6;
    color: rgba(255, 255, 255, 0.9);
    flex-grow: 1; /* Allow paragraph to expand to fill space */
}

/* Parallax Background */
.parallax-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    transform: translateZ(-1px) scale(2);
    z-index: -1;
}

.hero {
    min-height: 100vh;
    padding: 120px 0;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;
    background: transparent;
    margin-bottom: 0;
    overflow: hidden; /* Add this to contain the clouds */
}

.hero-title {
    font-size: clamp(2rem, 5vw, 3.5rem);
    font-weight: 700;
    color: var(--white);
    margin-bottom: 1.5rem;
    line-height: 1.2;
}

.hero-subtitle {
    font-size: clamp(1rem, 2vw, 1.25rem);
    color: rgba(255, 255, 255, 0.9);
    margin-bottom: 2rem;
}

.hero-image {
    width: 100%;
    height: auto;
    border-radius: 12px;
    box-shadow: var(--shadow);
    transition: transform 0.3s ease;
}

.section-header {
    text-align: center;
    margin-bottom: 4rem;
}

.section-header h2 {
    font-size: clamp(2rem, 4vw, 2.5rem);
    color: white; /* Changed from var(--primary) to white */
    margin-bottom: 1rem;
}

.section-header .lead {
    font-size: clamp(1rem, 2vw, 1.25rem);
    color: var(--white);
}

.features {
    padding: 100px 0;
    position: relative;
    z-index: 2;
    background: transparent;
}

/* Update container widths for better readability */
.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0;
}

/* Update responsive styles */
@media (max-width: 992px) {
    .hero-title {
        font-size: 2.5rem;
    }
    
    .hero {
        padding: 80px 0;
    }
    
    .section-header h2 {
        font-size: 2rem;
    }
}

@media (max-width: 768px) {
    .hero-title {
        font-size: 2rem;
    }
    
    .hero-subtitle {
        font-size: 1.1rem;
    }
}

/* Buttons */
.home-btn-primary {
    background: var(--gradient);
    color: white;
    border: none;
    padding: 12px 30px;
    border-radius: 8px;
    font-weight: 600;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.home-btn-primary:hover {
    transform: translateY(-2px);
    box-shadow: var(--shadow);
}

/* Animation Classes */
.shiny-text {
    background: var(--gradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    background-size: 200% auto;
    animation: shine 5s linear infinite;
}

@keyframes shine {
    to {
        background-position: 200% center;
    }
}

.main-content-wrapper {
    background: var(--gradient-main);
    position: relative;
    padding-bottom: 100px;
}

.benefits, .features, .pricing, .aboutUs {
    margin-top: 0;
}

.pricing {
    background: #f8f9fa;
    position: relative;
    z-index: 3;
    padding: 100px 0;
}

/* Cloud Animation Styles */
.clouds-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 1;
    pointer-events: none;
}

.cloud {
    position: absolute;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    opacity: 0.8;
    filter: blur(20px);
    pointer-events: none;
}

.cloud-1 {
    width: 300px;
    height: 100px;
    left: -150px;
    top: 20%;
    animation: floatCloud 60s linear infinite;
}

.cloud-2 {
    width: 200px;
    height: 80px;
    left: 10%;
    top: 60%;
    animation: floatCloud 45s linear infinite;
    animation-delay: 5s;
}

.cloud-3 {
    width: 400px;
    height: 120px;
    left: -200px;
    top: 40%;
    animation: floatCloud 70s linear infinite;
    animation-delay: 10s;
}

.cloud-4 {
    width: 250px;
    height: 90px;
    left: 30%;
    top: 15%;
    animation: floatCloud 55s linear infinite;
    animation-delay: 15s;
}

.cloud-5 {
    width: 350px;
    height: 110px;
    left: 50%;
    top: 70%;
    animation: floatCloud 65s linear infinite;
    animation-delay: 20s;
}

@keyframes floatCloud {
    0% {
        transform: translateX(0);
        opacity: 0;
    }
    10% {
        opacity: 0.7;
    }
    90% {
        opacity: 0.7;
    }
    100% {
        transform: translateX(calc(100vw + 400px));
        opacity: 0;
    }
}