/* Backlog Container */
.backlog-container {
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 0.5rem;
}

.backlog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.backlog-header h3 {
  margin: 0;
  font-weight: 600;
  color: #2c3e50;
}

.backlog-filters {
  background-color: white;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.backlog-group-header {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #e9ecef;
}

.backlog-group-header h4 {
  margin: 0;
  font-size: 1.15rem;
  font-weight: 600;
  color: #343a40;
}

.backlog-group-header .badge {
  margin-left: 0.5rem;
  padding: 0.35rem 0.65rem;
  font-size: 0.75rem;
  font-weight: 500;
}

/* Card View */
.backlog-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
}

.backlog-card {
  position: relative;
  background-color: white;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);
  transition: transform 0.2s, box-shadow 0.2s;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border: 1px solid #e9ecef;
}

.backlog-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
  border-color: #dee2e6;
}

.card-priority-indicator {
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
}

.card-priority-indicator.priority-high {
  background: linear-gradient(to bottom, #dc3545, #fd7e14);
}

.card-priority-indicator.priority-medium {
  background: linear-gradient(to bottom, #ffc107, #fd7e14);
}

.card-priority-indicator.priority-low {
  background: linear-gradient(to bottom, #0dcaf0, #0d6efd);
}

.backlog-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.75rem;
}

.story-id {
  font-family: 'Roboto Mono', monospace;
  font-size: 0.8rem;
  font-weight: 600;
  color: #6c757d;
  background-color: #f8f9fa;
  padding: 0.15rem 0.5rem;
  border-radius: 4px;
  border: 1px solid #e9ecef;
}

.backlog-card-title {
  font-size: 1rem;
  font-weight: 600;
  color: #343a40;
  margin-bottom: 0.5rem;
  line-height: 1.4;
}

.backlog-card-description {
  font-size: 0.85rem;
  color: #6c757d;
  margin-bottom: 1rem;
  flex-grow: 1;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.backlog-card-meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.75rem;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.story-meta-item {
  display: flex;
  align-items: center;
  gap: 0.35rem;
  font-size: 0.85rem;
  min-height: 24px;
}

.status-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  font-size: 1rem;
  flex-shrink: 0;
}

.status-text {
  color: #495057;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1;
  white-space: nowrap;
  margin-top: 1px;
}

.epic-badge {
  background-color: #e9ecef;
  color: #495057;
  padding: 0.15rem 0.5rem;
  border-radius: 4px;
  font-size: 0.75rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 120px;
}

.story-points {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e9ecef;
  color: #495057;
  border-radius: 50%;
  font-weight: 600;
  font-size: 0.75rem;
}

.backlog-card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  padding-top: 0.75rem;
  border-top: 1px solid #e9ecef;
}

.criteria-progress {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex: 1;
}

.criteria-progress .progress {
  flex: 1;
  background-color: #e9ecef;
  height: 6px;
  border-radius: 3px;
}

.criteria-progress .progress-bar {
  background: linear-gradient(45deg, #007bff, #6610f2);
  border-radius: 3px;
}

.criteria-count {
  font-size: 0.75rem;
  color: #6c757d;
  min-width: 32px;
  text-align: right;
}

.card-actions .btn-icon {
  width: 28px;
  height: 28px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6c757d;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  transition: all 0.2s;
}

.card-actions .btn-icon:hover {
  background-color: #e9ecef;
  color: #495057;
}

/* List View */
.backlog-list {
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 0;
}

.backlog-list .table {
  margin-bottom: 0;
}

.backlog-list .table thead th {
  background-color: #f8f9fa;
  color: #495057;
  font-weight: 600;
  padding: 0.75rem 1rem;
  border-bottom: 2px solid #dee2e6;
}

.backlog-list .table tbody td {
  padding: 0.75rem 1rem;
  vertical-align: middle;
  white-space: nowrap;
}

.story-title {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  max-width: 400px;
}

/* Empty State */
.backlog-empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 3rem 1rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.empty-icon {
  margin-bottom: 1rem;
  color: #6c757d;
}

.backlog-empty-state h4 {
  margin-bottom: 0.5rem;
  color: #343a40;
}

.backlog-empty-state p {
  color: #6c757d;
  margin-bottom: 1.5rem;
}

/* Loading State */
.backlog-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem 0;
  color: #6c757d;
}

.backlog-loading .spinner-border {
  margin-bottom: 1rem;
}

/* Responsive Adjustments */
@media (max-width: 992px) {
  .backlog-cards {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}

@media (max-width: 768px) {
  .backlog-cards {
    grid-template-columns: 1fr;
  }
  
  .story-title {
    max-width: 200px;
  }
}

/* Analytics Dashboard */
.backlog-analytics {
    background: white;
    border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 0 2px 8px rgba(0,0,0,0.05);
}

.analytics-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 1.5rem;
}

.analytic-card {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    padding: 1.25rem;
    background: #f8f9fa;
    border-radius: 8px;
    transition: transform 0.2s ease;
}

.analytic-card:hover {
    transform: translateY(-2px);
}

.analytic-icon {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    border-radius: 8px;
    font-size: 1.25rem;
}

.analytic-content {
    flex: 1;
}

.analytic-content h4 {
    font-size: 0.875rem;
    color: #6c757d;
    margin: 0 0 0.5rem 0;
}

.analytic-value {
    font-size: 1.5rem;
    font-weight: 600;
    color: #2c3e50;
}

/* Enhanced Filter Badges */
.filter-badges {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-top: 1rem;
}

.filter-badge {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.25rem 0.75rem;
    background: #e7f1ff;
    color: #0d6efd;
    border-radius: 1rem;
    font-size: 0.875rem;
}

.filter-badge button {
    background: none;
    border: none;
    color: #0d6efd;
    font-size: 1.25rem;
    line-height: 1;
    padding: 0;
    cursor: pointer;
    opacity: 0.7;
    transition: opacity 0.2s;
}

.filter-badge button:hover {
    opacity: 1;
}

.clear-filters-btn {
    background: none;
    border: none;
    color: #6c757d;
    font-size: 0.875rem;
    padding: 0.25rem 0.5rem;
    cursor: pointer;
    transition: color 0.2s;
}

.clear-filters-btn:hover {
    color: #dc3545;
}
