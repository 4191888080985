.footer-main {
    background: linear-gradient(to right, #004494, #0056b3, #004494);
    color: white;
    align-items: center;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    text-align: center;
    padding: 2.5rem 0 2rem;
    box-shadow: 0 -5px 15px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 1; /* Lower z-index */
}

.footer-main a {
    color: white;
    text-decoration: none;
    font-weight: 500;
    transition: all 0.3s ease;
    padding: 0.5rem 0.8rem;
    border-radius: 4px;
}

.footer-main a:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.15);
    transform: translateY(-2px);
}

.footer-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.footer-links {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1.5rem;
    margin-bottom: 0.5rem;
    position: relative;
}

.footer-links:after {
    content: '';
    position: absolute;
    bottom: -15px;
    left: 25%;
    right: 25%;
    height: 1px;
    background: rgba(255, 255, 255, 0.3);
}

.footer-copyright {
    margin: 0;
    font-size: 0.9rem;
    opacity: 0.9;
    letter-spacing: 0.5px;
}

.footer {
    position: relative;
    z-index: 1; /* Lower z-index */
}