/* Core container styles */
.dashboard-container {
  padding: 25px;
  background-color: #f9fafc;
  min-height: calc(100vh - 60px);
  background-image: linear-gradient(to bottom right, rgba(240, 245, 255, 0.5), rgba(255, 255, 255, 0.8));
}

/* Header section */
.dashboard-header {
  margin-bottom: 30px;
}

.dashboard-header h1 {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 15px;
  color: #2c3e50;
}

.dashboard-actions {
  display: flex;
  gap: 10px;
  margin-bottom: 25px;
}

.dashboard-actions .btn {
  padding: 8px 16px;
  transition: all 0.2s;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.dashboard-actions .btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.15);
}

/* Button colors - all blue */
.dashboard-actions .btn-primary {
  background-color: #0056b3;
}

/* Updated grid layout */
.dashboard-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  grid-template-areas:
    "projects daily-summary daily-summary"
    "raidlog productivity tasks"
    "calendar overdue upcoming"
    "overdue-detailed upcoming-detailed upcoming-detailed";
  overflow: visible !important;
  position: relative;
  z-index: auto;
}

/* Make certain cards span full width */
.full-width {
  grid-column: span 3;
}

/* Half-width cards */
.half-width {
  grid-column: span 1;
}

/* Card styling with subtle blue accents */
.dashboard-card {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  overflow: visible !important;
  height: 100%;
  position: relative;
  transition: box-shadow 0.2s;
}

.dashboard-card:hover {
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
}

.dashboard-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #eee;
}

.dashboard-card-header h2 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  color: #2c3e50;
}

.dashboard-card-body {
  padding: 20px;
}

/* Project list styling */
.project-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

/* Project Item Styling - updated to fix stacking context */
.project-item {
  padding: 12px 20px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: static;
  z-index: auto !important;
}

.project-item:hover {
  background-color: #f8f9fa;
  transform: translateX(4px);
  border-left-width: 8px;
  /* Don't increase z-index on hover to avoid breaking the dropdown */
}

.project-name {
  font-size: 14px;
  font-weight: 500;
  color: #2c3e50;
  margin-right: 12px;
}

/* Status Badge Styling - more explicit stacking */
.project-status-badge {
  font-size: 12px;
  font-weight: 500;
  padding: 4px 12px;
  border-radius: 12px;
  color: white;
  border: none;
  margin-left: 12px;
  position: static;
  z-index: auto !important;
}

/* Project status colors */
.project-status-badge.green {
  background-color: #28a745;
}

.project-status-badge.yellow {
  background-color: #ffc107;
  color: #000; /* Dark text for better contrast on yellow */
}

.project-status-badge.red {
  background-color: #dc3545;
}

.project-status-badge.active {
  background-color: #0056b3;
}

.project-status-badge.onhold {
  background-color: #6c757d;
}

.project-status-badge.completed {
  background-color: #28a745;
}

.project-status-badge.cancelled {
  background-color: #dc3545;
}

.project-status-badge.planning {
  background-color: #17a2b8;
}

/* Project menu styling */
.project-menu {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 10px 0 0 20px;
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.project-menu a {
  color: #0056b3;
  padding: 8px;
  border-radius: 8px;
  transition: all 0.2s;
  background-color: rgba(0, 86, 179, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.project-menu a:hover {
  background-color: #0056b3;
  color: white;
  transform: scale(1.1);
}

/* Table styling */
.dashboard-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.dashboard-table th {
  background-color: #f1f5fb;
  padding: 12px 15px;
  text-align: left;
  border-bottom: 2px solid #e3e6f0;
  color: #0056b3;
  font-weight: 600;
}

.dashboard-table td {
  padding: 12px 15px;
  border-bottom: 1px solid #e3e6f0;
  transition: background-color 0.2s;
  text-align: left;
}

.dashboard-table tr:hover td {
  background-color: #f8f9fc;
}

.dashboard-table tr:last-child td {
  border-bottom: none;
}

.dashboard-table a {
  color: #0056b3;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.2s;
  display: inline-block;
  max-width: 350px;
  word-break: break-word;
  text-align: left;
  line-height: 1.4;
}

.dashboard-table a:hover {
  color: #003d7a;
  text-decoration: underline;
}

/* Metrics display */
.metrics-container {
  display: flex;
  justify-content: space-around;
  margin: 15px 0;
  text-align: center;
}

.metric {
  padding: 15px;
  background-color: #f8f9fc;
  border-radius: 10px;
  min-width: 100px;
  transition: transform 0.2s;
  border: 1px solid #e3e6f0;
}

.metric:hover {
  transform: translateY(-5px);
}

.metric-value {
  font-size: 36px;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 8px;
  color: #0056b3 !important;
}

.metric-label {
  color: #6c757d;
  font-size: 14px;
  font-weight: 500;
}

.big-number {
  font-size: 48px;
  font-weight: bold;
  text-align: center;
  color: #dc3545; /* Red for overdue tasks count */
  margin: 20px 0;
}

/* Chart container */
.chart-container, .productivity-chart-container {
  height: 200px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
}

.chart-container canvas, .productivity-chart-container canvas {
  width: 100% !important;
  height: 100% !important;
  max-height: 200px;
  display: block;
  margin: 0 auto;
}

/* Overdue Tasks Styling */
.overdue-card {
  background-color: #fff8f8;
  border-left: 4px solid #dc3545;
}

.overdue-card .dashboard-card-header {
  background-color: #fff0f0;
  border-bottom: 1px solid rgba(220, 53, 69, 0.2);
}

.overdue-task-row {
  background-color: rgba(220, 53, 69, 0.05);
}

.overdue-task-row:hover {
  background-color: rgba(220, 53, 69, 0.1) !important;
}

.overdue-task-link {
  color: #dc3545;
  font-weight: 500;
  text-decoration: none;
  display: inline-block;
  max-width: 350px;
  word-break: break-word;
  text-align: left;
  line-height: 1.4;
}

.overdue-task-link:hover {
  text-decoration: underline;
  color: #c82333;
}

.days-overdue {
  color: #dc3545;
  font-weight: 600;
}

/* Card placement */
.projects-card { 
  grid-area: projects; 
  overflow: visible !important;
  position: relative;
  z-index: auto;
}
.daily-summary-card { grid-area: daily-summary; }
.raid-log-card { grid-area: raidlog; }
.productivity-card { grid-area: productivity; }
.tasks-card { grid-area: tasks; }
.calendar-card { grid-area: calendar; }
.overdue-card { grid-area: overdue; }
.upcoming-card { grid-area: upcoming; }
.overdue-detailed-card { grid-area: overdue-detailed; }
.upcoming-detailed-card { grid-area: upcoming-detailed; }

/* Add a container for the side-by-side cards */
.cards-row {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  width: 100%;
  grid-column: 1 / -1;
}

.cards-row > div {
  flex: 1;
  min-width: 0; /* Allow cards to shrink if needed */
}

.cards-row .dashboard-card {
  height: 100%;
}

/* Today's Tasks Card styling */
.tasks-card .dashboard-card-header {
  background-color: #f8f9fa;
  border-bottom: 2px solid #e3e6f0;
}

.tasks-card .metrics-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  padding: 20px;
}

.tasks-card .metric {
  background: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  transition: all 0.2s ease;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #e3e6f0;
}

.tasks-card .metric-value {
  font-size: 32px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 8px;
}

.tasks-card .metric-label {
  font-size: 13px;
  color: #6c757d;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

/* Custom colors for each metric */
.tasks-card .metric:nth-child(1) .metric-value,
.tasks-card .metric:nth-child(2) .metric-value {
  color: #0056b3;
}

.tasks-card .metric:nth-child(3) .metric-value {
  color: #ffc107;
}

.tasks-card .metric:nth-child(4) .metric-value {
  color: #28a745;
}

/* Today's Tasks Card Header specific styling */
.tasks-card .tasks-header-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tasks-card .tasks-title {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0;
}

.tasks-card .tasks-view-all {
  padding: 4px 12px;
  font-size: 0.875rem;
  margin-left: auto;
  white-space: nowrap;
}

/* Daily Summary Card styling */
.daily-summary-card {
  grid-area: daily-summary;
  display: flex;
  height: auto !important; /* Override any fixed height */
  overflow: visible !important; /* Allow content to expand beyond */
  max-height: none !important; /* Remove any max-height limitation */
  display: flex;
  flex-direction: column;
}

.daily-summary-card .dashboard-card-body {
  padding: 0;
  overflow: visible;
  max-height: none;
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* AI Insights styling - completely revised and deduplicated */
.ai-insight-section {
  background-color: transparent;
  padding: 0;
  box-shadow: none;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: visible !important;
  max-height: none !important;
  height: auto !important;
}

.ai-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  margin-bottom: 0;
  background-color: #f8f9fa;
  border-bottom: 1px solid rgba(0,0,0,0.05);
}

.ai-header h4 {
  font-size: 0.9rem;
  margin: 0;
  display: flex;
  align-items: center;
}

.insight-highlight-icon {
  color: #0056b3;
  margin-right: 8px;
  font-size: 0.9rem;
}

/* Compact view for insights */
.ai-insights-compact {
  display: flex;
  overflow-x: auto;
  padding: 10px 15px;
  gap: 10px;
  scrollbar-width: thin;
  background-color: #fff;
  border-bottom: 1px solid rgba(0,0,0,0.05);
}

.ai-insights-compact::-webkit-scrollbar {
  height: 4px;
}

.ai-insights-compact::-webkit-scrollbar-thumb {
  background-color: rgba(0,0,0,0.2);
  border-radius: 4px;
}

.ai-insights-compact::-webkit-scrollbar-track {
  background-color: rgba(0,0,0,0.05);
}

.ai-chip {
  padding: 5px 12px;
  border-radius: 16px;
  white-space: nowrap;
  font-size: 0.75rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid rgba(0,0,0,0.1);
  box-shadow: 0 1px 3px rgba(0,0,0,0.05);
  transition: all 0.2s;
  min-width: max-content;
  position: relative;
  flex-shrink: 0;
}

.ai-chip:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.ai-chip.active {
  transform: translateY(-2px);
  box-shadow: 0 3px 6px rgba(0,0,0,0.15);
  font-weight: 600;
  border-width: 2px;
  position: relative;
  transform: scale(1.05);
}

.ai-chip.active:after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  width: 8px;
  height: 8px;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0.8;
}

.ai-chip-icon {
  margin-right: 5px;
  font-size: 0.7rem;
}

/* Chip color variants */
.ai-chip.urgent {
  background-color: rgba(220, 53, 69, 0.1);
  color: #dc3545;
  border-color: rgba(220, 53, 69, 0.2);
}

.ai-chip.followup {
  background-color: rgba(253, 126, 20, 0.1);
  color: #fd7e14;
  border-color: rgba(253, 126, 20, 0.2);
}

.ai-chip.tasks {
  background-color: rgba(0, 86, 179, 0.1);
  color: #0056b3;
  border-color: rgba(0, 86, 179, 0.2);
}

.ai-chip.watch {
  background-color: rgba(255, 193, 7, 0.1);
  color: #ffc107;
  border-color: rgba(255, 193, 7, 0.2);
}

.ai-chip.quick {
  background-color: rgba(40, 167, 69, 0.1);
  color: #28a745;
  border-color: rgba(40, 167, 69, 0.2);
}

.ai-chip-count {
  margin-left: 5px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 1px 6px;
  font-size: 0.7rem;
  font-weight: 600;
}

/* Detailed insights (expanded view) */
.ai-insights-detailed {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  background-color: #fff;
}

.ai-insights-detailed.expanded {
  max-height: 500px;
  overflow-y: auto;
  border-top: 1px solid rgba(0,0,0,0.05);
}

.ai-insights-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  padding: 10px 15px;
}

/* Category details */
.ai-category {
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.05);
  border-left-width: 4px;
  border-left-style: solid;
  background: #ffffff;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  height: 100%;
  margin-bottom: 0;
}

.ai-category:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0,0,0,0.1);
}

/* Make urgent category full width */
.ai-category.urgent {
  grid-column: 1 / -1;
}

/* Category variations */
.ai-category.urgent {
  border-left-color: #dc3545;
  background-color: rgba(220, 53, 69, 0.05);
}

.ai-category.followup {
  border-left-color: #fd7e14;
  background-color: rgba(253, 126, 20, 0.05);
}

.ai-category.tasks {
  border-left-color: #0056b3;
  background-color: rgba(0, 86, 179, 0.05);
}

.ai-category.watch {
  border-left-color: #ffc107;
  background-color: rgba(255, 193, 7, 0.05);
}

.ai-category.quick {
  border-left-color: #28a745;
  background-color: rgba(40, 167, 69, 0.05);
}

.ai-category-header {
  font-weight: 600;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  padding-bottom: 8px;
  border-bottom: 1px solid rgba(0,0,0,0.05);
  color: #2c3e50;
  font-size: 0.9rem;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.ai-category-icon {
  margin-right: 10px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  flex-shrink: 0;
}

/* Category-specific icon styles */
.ai-category.urgent .ai-category-icon {
  color: #dc3545;
}

.ai-category.followup .ai-category-icon {
  color: #fd7e14;
}

.ai-category.tasks .ai-category-icon {
  color: #0056b3;
}

.ai-category.watch .ai-category-icon {
  color: #ffc107;
}

.ai-category.quick .ai-category-icon {
  color: #28a745;
}

/* Bullet list styles - consolidated from repeated code */
.ai-bullet-list, .ai-bullet-list-large {
  list-style-type: none !important;
  padding: 0 0 0 5px;
  margin: 0;
  text-align: left;
}

.ai-bullet-item {
  position: relative;
  padding: 6px 0 6px 28px;
  font-size: 0.85rem;
  margin-bottom: 6px;
  line-height: 1.5;
  color: #4a5568;
  border-bottom: 1px solid rgba(0,0,0,0.03);
  padding-bottom: 6px;
  text-align: left;
  list-style-type: none !important;
  display: block;
}

.ai-bullet-item:before {
  content: "";
  position: absolute;
  left: 12px;
  top: 14px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: currentColor;
  display: block;
}

/* Category detail view */
.ai-category-detail {
  padding: 15px;
  background-color: #fff;
  min-height: 100px;
  max-height: 350px;
  display: flex;
  flex-direction: column;
  animation: fadeIn 0.3s ease;
  overflow-y: auto;
}

.ai-category-content {
  flex: 1;
  border-radius: 10px;
  padding: 12px 15px;
  background-color: rgba(0,0,0,0.02);
  border-left-width: 4px;
  border-left-style: solid;
}

/* Category content variations by type */
.ai-category-content.urgent {
  border-left-color: #dc3545;
  background-color: rgba(220, 53, 69, 0.05);
}

.ai-category-content.followup {
  border-left-color: #fd7e14;
  background-color: rgba(253, 126, 20, 0.05);
}

.ai-category-content.tasks {
  border-left-color: #0056b3;
  background-color: rgba(0, 86, 179, 0.05);
}

.ai-category-content.watch {
  border-left-color: #ffc107;
  background-color: rgba(255, 193, 7, 0.05);
}

.ai-category-content.quick {
  border-left-color: #28a745;
  background-color: rgba(40, 167, 69, 0.05);
}

.ai-category-title {
  font-weight: 600;
  font-size: 0.9rem;
  margin-bottom: 10px;
  padding-bottom: 8px;
  border-bottom: 1px solid rgba(0,0,0,0.1);
  color: #2c3e50;
}

/* Special styling for 'more items' indicator */
.more-items {
  padding-top: 4px !important;
  padding-bottom: 0 !important;
  font-style: italic;
}

.more-items:before {
  display: none;
}

.more-indicator {
  color: #0056b3;
  font-size: 0.8rem;
  cursor: pointer;
  display: inline-block;
  padding: 4px 8px;
  background-color: rgba(0, 86, 179, 0.05);
  border-radius: 12px;
  transition: all 0.2s ease;
}

.more-indicator:hover {
  background-color: rgba(0, 86, 179, 0.1);
  text-decoration: underline;
  transform: translateY(-1px);
}

/* Toggle button */
.expand-toggle {
  background: none;
  border: none;
  color: #6c757d;
  font-size: 0.75rem;
  padding: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.expand-toggle:hover {
  color: #0056b3;
}

.expand-toggle svg {
  margin-left: 4px;
  transition: transform 0.2s;
}

.expand-toggle.expanded svg {
  transform: rotate(180deg);
}

/* Loading state */
.ai-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  background-color: #fff;
  min-height: 52px;
}

/* Placeholder state */
.ai-placeholder {
  padding: 12px 15px;
  background-color: #fff;
  font-size: 0.8rem;
  text-align: center;
  min-height: 52px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ai-placeholder button {
  margin-top: 8px;
  padding: 3px 10px;
  font-size: 0.75rem;
}

/* Category title count badge */
.count-badge {
  background-color: rgba(0,0,0,0.1);
  color: #333;
  border-radius: 12px;
  padding: 2px 8px;
  font-size: 0.7rem;
  margin-left: 8px;
  font-weight: 500;
}

/* Refresh button */
.refresh-insights-btn {
  background: none;
  border: none;
  color: #0056b3;
  cursor: pointer;
  padding: 6px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  position: relative;
  width: 32px;
  height: 32px;
}

.refresh-insights-btn:hover {
  background-color: rgba(0, 86, 179, 0.1);
}

.refresh-insights-btn:disabled {
  color: #a0a0a0;
  cursor: not-allowed;
}

/* Style for feature dots in upgrade message */
.feature-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  flex-shrink: 0;
}

/* Upgrade section styling */
.upgrade-container {
  display: flex;
  flex-direction: column;
  background-color: #f8f9fa;
  border-radius: 12px;
  overflow: hidden;
}

.upgrade-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upgrade-icon {
  filter: drop-shadow(0 2px 5px rgba(0, 86, 179, 0.3));
}

.upgrade-btn {
  padding: 10px 24px;
  transition: all 0.3s ease;
  border-width: 2px;
}

.upgrade-btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.features-container {
  padding: 0 15px 20px;
}

.feature-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

.feature-item {
  display: flex;
  align-items: center;
  background-color: white;
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s;
}

.feature-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
}

.feature-icon {
  color: #0056b3;
  margin-right: 10px;
  font-size: 1rem;
  width: 20px;
  text-align: center;
}

/* Remove the old feature dots styling as we're using FontAwesome icons instead */
.feature-dot {
  display: none;
}

/* Responsive layout for features */
@media (max-width: 576px) {
  .feature-grid {
    grid-template-columns: 1fr;
  }
}

/* Responsive adjustments */
@media (max-width: 992px) {
  .dashboard-grid {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "projects daily-summary"
      "raidlog productivity"
      "tasks calendar"
      "overdue upcoming"
      "overdue-detailed upcoming-detailed";
  }
  
  .ai-insights-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .dashboard-grid {
    grid-template-columns: 1fr;
    grid-template-areas:
      "projects"
      "daily-summary"
      "raidlog"
      "productivity"
      "tasks"
      "calendar"
      "overdue"
      "upcoming"
      "overdue-detailed"
      "upcoming-detailed";
  }
  
  .cards-row {
    flex-direction: column;
  }
  
  .cards-row > div {
    margin-bottom: 20px;
  }
  
  .cards-row > div:last-child {
    margin-bottom: 0;
  }
  
  .ai-insights-grid {
    grid-template-columns: 1fr;
  }
  
  .ai-category.urgent {
    grid-column: auto;
  }
  
  .tasks-card .metrics-container {
    grid-template-columns: 1fr;
    gap: 10px;
    padding: 15px;
  }
  
  .tasks-card .metric {
    padding: 15px;
  }
  
  .tasks-card .metric-value {
    font-size: 28px;
  }
}

/* Debug view for AI diagnostics */
.raw-content {
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 8px;
  margin-bottom: 15px;
  font-family: monospace;
}

/* Project filter dropdown styling */
#projectFilterDropdown {
  font-size: 0.8rem;
  padding: 3px 8px;
  border-radius: 4px;
  background-color: rgba(0, 86, 179, 0.08);
  border: 1px solid rgba(0, 86, 179, 0.1);
  color: #0056b3;
  transition: all 0.2s ease;
}

#projectFilterDropdown:hover {
  background-color: rgba(0, 86, 179, 0.15);
  border-color: rgba(0, 86, 179, 0.2);
}

.dropdown-menu .dropdown-item.active {
  background-color: rgba(0, 86, 179, 0.1);
  color: #0056b3;
  font-weight: 500;
}

/* Project filter dropdown styling - updated for custom implementation and stacking */
.custom-dropdown {
  position: relative;
  z-index: 10000 !important; /* Significantly higher z-index than any other elements */
}

.custom-dropdown-toggle {
  font-size: 0.8rem;
  padding: 3px 8px;
  border-radius: 4px;
  background-color: rgba(0, 86, 179, 0.08);
  border: 1px solid rgba(0, 86, 179, 0.1);
  color: #0056b3;
  transition: all 0.2s ease;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 10000; /* Match parent z-index */
}

.custom-dropdown-toggle:hover {
  background-color: rgba(0, 86, 179, 0.15);
  border-color: rgba(0, 86, 179, 0.2);
}

.custom-dropdown-menu {
  position: absolute;
  right: 0;
  top: calc(100% + 4px);
  z-index: 10001; /* Even higher z-index than the dropdown container */
  min-width: 10rem;
  padding: 0.5rem 0;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);
}

/* Fix for dropdown items to ensure they maintain correct stacking */
.custom-dropdown-menu .dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  text-align: left;
  cursor: pointer;
  position: relative;
  z-index: 10002; /* Higher than the menu itself */
}

.custom-dropdown-menu .dropdown-item:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}

.custom-dropdown-menu .dropdown-item.active {
  background-color: rgba(0, 86, 179, 0.1);
  color: #0056b3;
  font-weight: 500;
}

/* Create an overlay behind the dropdown menu but above everything else */
.custom-dropdown::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  z-index: 9999;
  pointer-events: none;
  display: none;
}

.custom-dropdown.open::before {
  display: block;
  pointer-events: auto;
}

.dropdown-icon {
  font-size: 0.7rem;
  transition: transform 0.2s;
}

.custom-dropdown.open .dropdown-icon {
  transform: rotate(180deg);
}

/* Custom PMHub Filter Dropdown - with unique prefixes to avoid conflicts */
.pmh-filter-container {
  position: relative;
  margin-right: 0.5rem;
}

.pmh-filter-button {
  font-size: 0.8rem;
  padding: 3px 8px;
  border-radius: 4px;
  background-color: rgba(0, 86, 179, 0.08);
  border: 1px solid rgba(0, 86, 179, 0.1);
  color: #0056b3;
  transition: all 0.2s ease;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.pmh-filter-button:hover {
  background-color: rgba(0, 86, 179, 0.15);
  border-color: rgba(0, 86, 179, 0.2);
}

.pmh-filter-dropdown {
  position: fixed !important; /* Use fixed to ensure it's outside any stacking context */
  min-width: 10rem;
  padding: 0.5rem 0;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);
  z-index: 99999 !important;
}

.pmh-filter-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: left;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  cursor: pointer;
}

.pmh-filter-item:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}

.pmh-filter-active {
  background-color: rgba(0, 86, 179, 0.1);
  color: #0056b3;
  font-weight: 500;
}

.pmh-rotate {
  transform: rotate(180deg);
}