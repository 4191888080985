.features-container {
    padding: 1rem;
    max-width: 100%;
}

/* Features Analytics */
.features-analytics {
    padding: 1rem 0;
}

.analytics-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
}

.analytic-card {
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    padding: 1.25rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.analytic-card:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.08);
}

.analytic-icon {
    width: 50px;
    height: 50px;
    border-radius: 12px;
    background: #f8f9fa;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
}

.analytic-content {
    flex: 1;
}

.analytic-content h4 {
    font-size: 0.85rem;
    color: #6c757d;
    margin-bottom: 0.5rem;
}

.analytic-value {
    font-size: 1.5rem;
    font-weight: 600;
    color: #212529;
}

/* Features Filters */
.features-filters {
    background: white;
    border-radius: 12px;
    padding: 1.25rem;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
}

.view-toggle-btn {
    border: none;
    background: #f8f9fa;
    color: #6c757d;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    transition: all 0.2s ease;
}

.view-toggle-btn.active {
    background: #0d6efd;
    color: white;
}

.view-toggle-btn:hover:not(.active) {
    background: #e9ecef;
}

/* Features Grid View */
.features-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
    gap: 1.5rem;
    margin-top: 1rem;
}

.feature-card {
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.feature-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.feature-card-header {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.feature-card-header.functionality {
    background: linear-gradient(to right, rgba(13, 110, 253, 0.05), transparent);
}

.feature-card-header.ui {
    background: linear-gradient(to right, rgba(25, 135, 84, 0.05), transparent);
}

.feature-card-header.enhancement {
    background: linear-gradient(to right, rgba(13, 202, 240, 0.05), transparent);
}

.feature-card-header.optimization {
    background: linear-gradient(to right, rgba(255, 193, 7, 0.05), transparent);
}

.feature-card-header.integration {
    background: linear-gradient(to right, rgba(108, 117, 125, 0.05), transparent);
}

.feature-card-header.security {
    background: linear-gradient(to right, rgba(220, 53, 69, 0.05), transparent);
}

.feature-number {
    font-family: 'Roboto Mono', monospace;
    font-size: 0.85rem;
    color: #6c757d;
    background: #f8f9fa;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    border: 1px solid #e9ecef;
}

.feature-type-label {
    display: flex;
    align-items: center;
    font-size: 0.85rem;
    color: #6c757d;
}

.feature-card-body {
    padding: 1.25rem;
    flex: 1;
}

.feature-title {
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 0.75rem;
    color: #212529;
}

.feature-description {
    color: #6c757d;
    font-size: 0.9rem;
    margin-bottom: 1rem;
    line-height: 1.5;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.feature-meta {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
}

.feature-badge-planning {
    background-color: #6c757d;
}

.feature-badge-in.progress {
    background-color: #0d6efd;
}

.feature-badge-testing {
    background-color: #ffc107;
    color: #212529;
}

.feature-badge-complete {
    background-color: #198754;
}

.feature-badge-on.hold {
    background-color: #dc3545;
}

.feature-card-footer {
    padding: 1rem 1.25rem;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    background: #f8f9fa;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.feature-progress {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex: 1;
}

.feature-progress .progress {
    flex: 1;
    height: 6px;
    background-color: #e9ecef;
    border-radius: 3px;
}

.feature-progress .progress-bar {
    background: linear-gradient(to right, #0d6efd, #0dcaf0);
    border-radius: 3px;
}

.progress-text {
    font-size: 0.8rem;
    font-weight: 500;
    color: #6c757d;
    min-width: 36px;
    text-align: right;
}

.feature-data {
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.feature-epics {
    font-size: 0.8rem;
    color: #6c757d;
    background: #e9ecef;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
}

/* Feature Detail Grid */
.feature-detail-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    margin-bottom: 1.5rem;
}

.feature-detail-item {
    background: #f8f9fa;
    border-radius: 8px;
    padding: 1rem;
}

.feature-detail-label {
    font-size: 0.8rem;
    color: #6c757d;
    margin-bottom: 0.5rem;
}

.feature-detail-value {
    font-weight: 500;
    color: #495057;
}

/* Table View */
.sortable-header {
    cursor: pointer;
    user-select: none;
}

.sortable-header:hover {
    background-color: rgba(0, 0, 0, 0.03);
}

.feature-type {
    display: inline-flex;
    align-items: center;
    font-size: 0.9rem;
}

.cursor-pointer {
    cursor: pointer;
}

.table th, .table td {
    vertical-align: middle;
}

/* Requirements List */
.requirements-list {
    max-height: 400px;
    overflow-y: auto;
    margin: 1rem 0;
    padding-right: 0.5rem;
}

.requirements-progress {
    margin-top: 1rem;
}

/* Empty State */
.empty-state {
    padding: 3rem 1rem;
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

/* Responsive Adjustments */
@media (max-width: 1200px) {
    .analytics-grid {
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
    }

    .feature-detail-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .analytics-grid {
        grid-template-columns: 1fr;
    }

    .features-grid {
        grid-template-columns: 1fr;
    }

    .feature-detail-grid {
        grid-template-columns: 1fr;
    }

    .feature-controls {
        flex-direction: column;
        align-items: stretch !important;
        gap: 0.5rem;
    }

    .search-field {
        width: 100% !important;
    }

    .view-toggle-container {
        margin-top: 1rem;
        justify-content: flex-end;
    }
}
